import React from 'react';
import { Layout, Typography } from 'antd';
import useTemplateGlobalState from '../../atoms/variables';
import User from './components/UserComponent';

import { useLocation } from 'react-router-dom';

import HeaderEquipmentInfo from '../../../VirtualEnginner/components/HeaderEquipmentInfo';

import './style.css';
import Breadcrumbs from './components/Breadcrumbs';

const { Header } = Layout;
const { Title } = Typography;

const HeaderComponent = () => {
  const { title, buttons, customMenu } = useTemplateGlobalState();
  const location = useLocation();

  return (
    <Header
      id='header'
      style={
        location.pathname.match(/\/assets\/[\dA-z]+/) ||
        location.pathname.match(/\/positions\/[\dA-z]+/) ||
        location.pathname.match(/\/instruments\/[\dA-z]+/)
          ? { borderBottom: 'none' }
          : {}
      }
      className={customMenu ? 'has-menu' : 'no-menu'}
    >
      <div className='header-content'>
        <div className='title'>
          {typeof title === 'string' ? <Title className='productName'>{title}</Title> : title}
          <div className='breadcrumbs'>
            <Breadcrumbs />
          </div>
        </div>
        <div className='header-buttons'>
          {buttons?.map((button, index) => {
            return (
              <>
                {button?.node}
                <div className='header-buttons-divider'></div>
              </>
            );
          })}
          <User />
        </div>
        {/* {location.pathname.includes('/equipments') && <HeaderEquipmentInfo />} */}
      </div>
      {customMenu}
    </Header>
  );
};

export default HeaderComponent;
