import React, { useEffect } from 'react';
import { Modal, Button, Typography, Space } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

const TokenModal = ({ item, onClose }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(item?.token);
  };

  return (
    <div style={{ textAlign: 'left' }}>
      <Title style={{ marginTop: 0 }} level={4}>
        Registro completado com sucesso!
      </Title>
      <Text>Envie o seguinte token ao contratante para este serviço.</Text>
      <div style={{ margin: '20px 0' }}>
        <Title level={2} style={{ display: 'inline', color: '#1890ff' }}>
          {item?.token}
        </Title>
        <Button icon={<CopyOutlined />} onClick={copyToClipboard} style={{ marginLeft: 8 }} />
      </div>
      <Space style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 80 }}>
        <Button type='primary' onClick={onClose}>
          Ok
        </Button>
      </Space>
    </div>
  );
};

export default TokenModal;
