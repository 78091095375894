import { Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import Table from '../../../../../Template/layoutComponents/Table';
import TableTip from '../../../../../Template/layoutComponents/Table/components/tip';
import RenderBool from '../../../../../Template/layoutComponents/Table/components/boolValues';

import { useNavigate, useParams } from 'react-router-dom';

import useMaintenanceState from '../../../../atoms/variables';
import { findObjectById } from '../../../../../helpers/misc';
import { useEffect, useState } from 'react';
import useTemplateState from '../../../../../Template/atoms/variables';
import { getSystemChildrenDetails } from '../../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';

import { getAllCategories } from '../../../../../Settings/helpers/apiCalls';

const ViewSystem = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const { plantId, systemId } = useParams();
  const { chosenSystem, chosenPlant } = useMaintenanceState();
  const { setPendingApiCalls } = useTemplateState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [childrenDetails, setChildrenDetails] = useState(null);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  useEffect(() => {
    console.log(childrenDetails);
  }, [childrenDetails]);

  useEffect(() => {
    console.log(categories);
  }, [categories]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) =>
        current.concat(['getAllCategories', 'getSystemChildrenDetails']),
      );

      const responses = await Promise.all([
        getAllCategories(instance),
        getSystemChildrenDetails(instance, { _id: chosenSystem?.children }),
      ]);

      setCategories(responses[0]);
      setChildrenDetails(responses[1]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) =>
        current.filter(
          (item) => item !== 'getSystemChildrenDetails' && item !== 'getAllCategories',
        ),
      );
    }
  };

  return (
    <div className='instrumentsContent'>
      {contextHolder}
      <div className='instrumentsContentHeader'>
        <div className='pageTitle'>{chosenSystem?.name}</div>
        <div className='pageButtons'>
          <Button
            className='newItemButton'
            size='large'
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            onClick={async () => {
              navigate(`/maintenance/plants/${plantId}/systems/${systemId}`);
            }}
          >
            {t('edit_system')}
          </Button>
        </div>
      </div>
      <Table
        handleSelect={null}
        handleRowClick={(row) => {
          navigate(`/maintenance/plants/${plantId}/assets/${row.id}`);
        }}
        apiCall={(instance, params) =>
          getSystemChildrenDetails(instance, { _id: chosenSystem?.children, ...params })
        }
        items={childrenDetails?.items}
        tableSettings={{
          totalItems: childrenDetails?.totalItems ?? 0,
          columnsConfig: {
            type: {
              label: t('type'),
            },
            name: {
              label: t('name'),
            },
            asset_category: {
              label: t('category'),
              replaceValue: (value) => {
                return categories?.find((cat) => cat._id === value || cat.id === value);
              },
            },
            status: {
              label: t('status'),
              customElement: (item) => <TableTip children={item.status ?? item} />,
            },
          },
          sortable: [],
          listFilter: ['asset_category'],
          searchFilter: ['name'],
          uniqueValues: childrenDetails?.uniqueValues,
        }}
      />
    </div>
  );
};

export default ViewSystem;
