import { useState } from 'react';
import SiderMenu from '../Sider/components/SiderMenu';
import './style.css';

const AppBar = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div id='appBar'>
      <SiderMenu collapsed={collapsed} setCollapsed={setCollapsed} isMobile={true} />
    </div>
  );
};

export default AppBar;
