import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import Forms from '../../../Template/layoutComponents/Forms';
import { useEffect, useState } from 'react';

import useTemplateState from '../../../Template/atoms/variables';
import './style.css';
import { useNavigate } from 'react-router-dom';

import { CloseCircleOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import { Button, notification } from 'antd';
import useSettingsState from '../../../Settings/atoms/variables';

const GeneralTab = ({ detailsLink, updateItem }) => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls } = useTemplateState();
  const { viewDetailsCategory } = useSettingsState();

  const [chosenItem, setChosenItem] = useState(viewDetailsCategory);
  const [editingItem, setEditingItem] = useState(false);

  const [halfMain, setHalfMain] = useState(0);

  useEffect(() => {
    setHalfMain(Math.ceil(chosenItem?.properties.length / 2));
  }, [chosenItem]);

  const disableCreateSave = () => {
    if (editingItem)
      return chosenItem.properties?.some(
        (prop) =>
          !prop.type ||
          prop.label == '' ||
          prop.label == t('new_property') ||
          prop.options?.some((opt) => opt?.label == '') ||
          (prop.type.toLowerCase().includes('select') && prop.options?.length === 0),
      );

    return true;
  };

  return (
    <div className='generalTab'>
      {contextHolder}
      <div className='propsForm mainProps'>
        <div className='columnTitle' style={{ paddingBottom: '2rem' }}>
          {t('main_properties')}
        </div>
        <div className='propsFormContent'>
          <div className='propsColumn firstColumn'>
            <Forms
              inputs={chosenItem?.properties}
              onInputChange={(event, inputIndex) => {
                setEditingItem(true);

                setChosenItem((current) => {
                  if (current) {
                    return {
                      ...current,
                      properties: current.properties?.map((item, index) => {
                        if (index == inputIndex) {
                          return {
                            ...item,
                            value: item.type.toLowerCase().includes('select')
                              ? { id: event.target.value, label: '' }
                              : event.target.value,
                          };
                        }
                        return item;
                      }),
                      label: current.properties[0].value,
                    };
                  }
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className='manageCategoriesButtonsContainer detailPage'>
        {editingItem && (
          <Button
            id='cancel'
            size='large'
            icon={<CloseCircleOutlined />}
            style={{
              borderColor: 'black',
              background: 'white',
              color: 'black',
            }}
            onClick={() => {
              navigate(`${detailsLink}`);
            }}
          >
            {t('cancel_changes')}
          </Button>
        )}
        {chosenItem && (
          <Button
            id='create'
            className={disableCreateSave() ? 'disabled' : ''}
            size='large'
            disabled={disableCreateSave()}
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            icon={<AppstoreAddOutlined />}
            onClick={async () => {
              try {
                setPendingApiCalls((current) => current.concat(['updateItem']));
                await updateItem(instance, chosenItem);
              } catch (err) {
                // API fail notification
                notificationApi.error({
                  message: 'Falha ao atualizar categoria existente',
                  description: err?.response?.data?.message
                    ? `${err?.response?.data?.message} (${err?.response?.status})`
                    : err?.message,
                  duration: 5,
                });
              } finally {
                setPendingApiCalls((current) => current.filter((item) => item !== 'updateItem'));
              }

              setEditingItem(false);
            }}
          >
            {t('save_item_settings')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default GeneralTab;
