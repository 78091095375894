import { Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const LoadingModal = ({ visible }) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      centered
      footer={null}
      closable={false}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
      }}
      width='45%'
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 80 }} spin />} />
      <p
        style={{
          marginTop: '20px',
          fontSize: '1.2rem',
          fontWeight: '600',
          marginTop: '30px',
        }}
      >
        {t('generating_knowledge')}
      </p>
    </Modal>
  );
};

export default LoadingModal;
