import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';

const ArrowSteps = ({
  currentStep,
  stepProgress = {},
  highlightCompletedSteps = true,
  components = [],
  mandatoryStepsCompletion = {},
  onStepChange,
}) => {
  const { t } = useTranslation();
  const steps = [
    { label: 'L1' },
    { label: 'L2' },
    { label: 'L3' },
    { label: 'L4' },
    { label: 'L5' },
    { label: t('operating') },
  ];

  return (
    <div>
      <div className='steps-title'>
        <h3>{`${t('acceptance_criteria')}:`}</h3>
      </div>
      <div className='steps-arrow'>
        {steps.map((step, index) => {
          const isActive = index <= currentStep;
          const isCurrent = index === currentStep;
          const progress = stepProgress?.[step.label] || 0;
          const isMandatoryComplete = mandatoryStepsCompletion[step.label] || false;
          const isClickable =
            index <= currentStep ||
            (index === currentStep + 1 && mandatoryStepsCompletion[steps[currentStep]?.label]);

          let labelColor = '#333';
          if (isCurrent && progress < 40) {
            labelColor = '#007BFF';
          } else if (isActive) {
            labelColor = '#FFF';
          }

          return (
            <div
              key={index}
              className={`step-arrow ${isActive ? 'active' : ''} ${!isClickable ? 'disabled' : ''}`}
              style={{
                background: isCurrent
                  ? '#d9e7ff'
                  : isActive && highlightCompletedSteps
                    ? `linear-gradient(to right, #2196f3 ${progress}%, #1777FF2E ${progress}%)`
                    : '#e0e0e0',
                cursor: isClickable ? 'pointer' : 'not-allowed',
              }}
              onClick={() => isClickable && onStepChange(index)}
            >
              <div
                className='progress-bar'
                style={{
                  width: `${progress}%`,
                  background: isMandatoryComplete && index < currentStep ? `#0D47A1` : `#007BFF`,
                  borderTopRightRadius: progress < 100 ? '16px' : '0',
                  borderBottomRightRadius: progress < 100 ? '16px' : '0',
                }}
              ></div>

              <span style={{ color: labelColor }}>{step.label}</span>
            </div>
          );
        })}
      </div>
      <div className='step-content'>
        {components[currentStep] || <p>Componente não encontrado</p>}
      </div>
    </div>
  );
};

export default ArrowSteps;
