import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, message, Modal } from 'antd';

import CustomForm from '../../../../components/CustomForm';
import useCrudApi from '../../../../hooks/useCrudApi';
import { formatEmail, phoneNumberValidator } from '../../../../helpers/validations';

import './style.css';

export default function Register({ executors, visible, handleClose, handleCancel, handleFinish }) {
  const navigate = useNavigate();
  const element = 'executors';
  const { createItem, creating } = useCrudApi(element);
  const getLocations = useCrudApi('sites').getItems;
  const [listLocations, setListLocations] = useState(null);

  const checkExistingAndValidEmail = async (_, value) => {
    await formatEmail(_, value);
    const existingEmail = executors.find((executor) => executor.email === value);
    if (existingEmail)
      return Promise.reject('Email já cadastrado! Remove ou adicione outro email.');
    return Promise.resolve();
  };

  const fields = [
    { label: 'Nome completo', key: 'name', type: 'input', required: true },
    {
      label: 'Email',
      key: 'email',
      type: 'input',
      required: true,
      placeholder: 'Ex: exemplo@domain.com',
      validateFunction: checkExistingAndValidEmail,
    },
    {
      label: 'Telefone',
      key: 'phone',
      type: 'input-number',
      required: true,
      placeholder: 'Ex: 11988887777',
      maxLength: 11,
      validateFunction: phoneNumberValidator,
    },
    {
      label: 'Função/Cargo',
      key: 'role',
      type: 'input',
      placeholder: 'Ex: Técnico de Redes',
      required: true,
    },
    {
      label: 'Compania',
      key: 'company_name',
      type: 'input',
      required: true,
      placeholder: 'Ex: Scala Data Center',
    },
    {
      label: 'Localização',
      key: 'location',
      type: 'select',
      selectItem: 'sites',
      selectKey: '_id',
      multiple: true,
      required: true,
      items: listLocations,
    },
  ];
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!listLocations) {
          const resultLocations = await getLocations(undefined, undefined, { parent: null });
          if (resultLocations) {
            setListLocations(resultLocations.items);
          } else {
            setListLocations([]);
          }
        }
      } catch (error) {
        console.error(error);
        setListLocations([]);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      await createItem(values);
      form.resetFields();
      handleFinish();
    } catch (error) {
      message.error('Erro ao enviar o formulário');
    }
  };

  return (
    <Modal
      title={'Adicionar novo executor'}
      open={visible}
      footer={null}
      width={'68vw'}
      onClose={() => handleClose()}
      onCancel={() => handleCancel()}
    >
      <CustomForm
        form={form}
        fields={fields}
        creating={creating}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
      />
    </Modal>
  );
}
