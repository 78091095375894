/**
 * Helper para verificar se o usuário é externo.
 * No futuro, mais funções de roles podem ser adicionadas aqui.
 * Sugestão de estrutura para escalabilidade:
 *
 * 1. Criar diferentes funções para cada tipo de role (Ex: isAdmin, isManager).
 * 2. Usar uma abordagem baseada em permissões: verificar permissões específicas ao invés de roles estáticas.
 * 3. Implementar um sistema de roles baseado em um objeto de permissões ou uma lista de permissões associadas ao usuário.
 */

/**
 * Verifica se o usuário é externo, baseado na presença de "#EXT#" no username.
 * @param {string} username - Nome de usuário do usuário logado.
 * @returns {boolean} Retorna true se o usuário for externo.
 */
export const isExternalUser = (username) => {
  const regex = /#EXT#/;
  return regex.test(username);
};

/**
 * Verifica se o usuário é um administrador.
 * @param {Object} user - Objeto do usuário logado com informações de roles.
 * @returns {boolean} Retorna true se o usuário tiver a role de administrador.
 */
export const isAdmin = (user) => {
  return user?.roles?.includes('admin');
};

/**
 * Verifica se o usuário é um manager.
 * @param {Object} user - Objeto do usuário logado com informações de roles.
 * @returns {boolean} Retorna true se o usuário tiver a role de manager.
 */
export const isManager = (user) => {
  return user?.roles?.includes('manager');
};

/**
 * Função para verificar se o usuário tem uma permissão específica.
 * Uma abordagem baseada em permissões pode ser mais flexível no longo prazo, permitindo verificar várias permissões.
 * @param {Object} user - Objeto do usuário logado com permissões associadas.
 * @param {string} permission - Permissão a ser verificada (ex: 'canApproveReport').
 * @returns {boolean} Retorna true se o usuário tiver a permissão.
 */
export const hasPermission = (user, permission) => {
  return user?.permissions?.includes(permission);
};

/**
 * Exemplo de função para determinar se o usuário pode aprovar relatórios.
 * Esse tipo de verificação pode se basear em permissões específicas associadas ao usuário.
 * @param {Object} user - Objeto do usuário logado com permissões associadas.
 * @returns {boolean} Retorna true se o usuário tiver a permissão de aprovar relatórios.
 */
export const canApproveReports = (user) => {
  return hasPermission(user, 'canApproveReport');
};
