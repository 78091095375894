import React, { useState, useRef, useEffect } from 'react';
import { Layout, Card } from 'antd';
import AttachmentsTable from './Attachments';
import './style.css';
import Navigation from '../../../../../../components/Navigation';
import Report from './Report';

const Overview = ({ task, invisible, onTaskUpdate, isLatestAttempt, interconnection }) => {
  const targetRef = useRef(null);

  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const [navigationSelected, setNavigationSelected] = useState('overview');

  const handleNavigationSelection = (key) => {
    setNavigationSelected(key);
  };

  const navigation = {
    selected: navigationSelected,
    onSelect: handleNavigationSelection,
    content: [
      { key: 'overview', name: 'Relatório' },
      { key: 'attachments', name: 'Anexos' },
    ],
  };

  const contentMap = {
    overview: {
      component: (
        <Report
          task={task}
          isLatestAttempt={isLatestAttempt}
          invisible={invisible}
          onTaskUpdate={onTaskUpdate}
          interconnection={interconnection}
        />
      ),
      title: 'Relatório',
    },
    attachments: {
      component: (
        <Card>
          <AttachmentsTable task={task} />
        </Card>
      ),
      title: 'Anexos',
    },
  };

  return (
    <Layout className='layout-viewer-overview'>
      {task && (
        <>
          {!invisible && (
            <Layout ref={targetRef} className='layout-nav-overview'>
              <Navigation props={navigation} />
            </Layout>
          )}
          <Layout className='overview-content'>{contentMap[navigationSelected].component}</Layout>
        </>
      )}
    </Layout>
  );
};

export default Overview;
