import React, { useState, useEffect } from 'react';
import { Typography, Button, message } from 'antd';
import { useRecoilState } from 'recoil';
import { uploadStatusState, responseApiState } from '../../../atoms/globalVariables';
import useCrudApi from '../../../hooks/useCrudApi';
import Loading from '../../Loading/index';
import UploadSummary from '../UploadResume/index';
import FileDragger from '../../FileDragger/index';
import DynamicSelect from '../../DynamicSelect';
import DownloadTemplateButton from '../../DownloadTemplateButton';

import './style.css';
import { useParams } from 'react-router-dom';

const { Text } = Typography;

const UploadComponent = ({ onCancel, setEquipments, loadData }) => {
  const { plantId } = useParams();
  const [status, setStatus] = useRecoilState(uploadStatusState);
  const [response, setResponse] = useRecoilState(responseApiState);
  const { uploadFile } = useCrudApi('interconnections');
  const [fileList, setFileList] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const getCategories = useCrudApi('equipment_category').getItems;

  const [listCategories, setListCategories] = useState(null);

  const handleFileUpload = async () => {
    if (!selectedValue) {
      message.error('Por favor, selecione um item para fazer o upload.');
      return;
    }
    setStatus('uploading');
    let result = await uploadFile(fileList, plantId, selectedValue);
    if (result) {
      setFileList([]);
      setResponse(result);
      setStatus('done');
    } else setStatus('error');
    setSelectedValue(null);
  };

  const handleSelectChange = (value, option) => {
    setSelectedValue(value);
    setSelectedOption(option);
  };

  const getTemplateName = (selectedOption) => {
    return `template_${selectedOption.toLowerCase().replace(/\s+/g, '')}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultCategories = await getCategories();
        if (resultCategories) {
          setListCategories(resultCategories.items);
        }
      } catch (error) {
        console.error(error);
        setListCategories([]);
      }
    };
    fetchData();
    setSelectedValue(null);
  }, [onCancel]);

  const renderContent = () => {
    const contentMap = {
      uploading: <Loading loading={true} />,
      done: (
        <UploadSummary
          onCancel={onCancel}
          data={response}
          setEquipments={setEquipments}
          loadData={loadData}
        />
      ),
      idle: (
        <>
          <DynamicSelect
            width='100%'
            placeholder='Selecione uma categoria de interconexão'
            element={'equipment_subcategory'}
            onChange={handleSelectChange}
            selectedValue={selectedValue}
            items={listCategories}
          />
          {selectedValue ? (
            <>
              <DownloadTemplateButton
                filePath={`/templates/${getTemplateName(selectedOption.children)}.xlsx`}
                label={`Baixar Planilha Modelo de ${selectedOption.children}`}
              />
              <FileDragger fileList={fileList} setFileList={setFileList} />
            </>
          ) : (
            <div>
              <Text
                type='danger'
                style={{ display: 'flex', justifyContent: 'center', margin: '11vh 0' }}
              >
                Por favor, selecione uma categoria antes de prosseguir com o upload!
              </Text>
            </div>
          )}

          <div className='next-button'>
            {fileList.length > 0 && (
              <Button size='small' onClick={handleFileUpload}>
                Próximo
              </Button>
            )}
          </div>
        </>
      ),
      error: (
        <div>
          <p>Ocorreu um erro ao fazer o upload do arquivo.</p>
          <button onClick={() => setStatus('idle')}>Tente novamente</button>
        </div>
      ),
    };

    return contentMap[status] || null;
  };

  return renderContent();
};

export default UploadComponent;
