import React from 'react';
import { Button, Space } from 'antd';
import { DeleteTwoTone, UploadOutlined, HistoryOutlined } from '@ant-design/icons';
import AddFilesModal from '../../../../../../../Template/layoutComponents/UploadDocumentPage/components/AddFilesModal';

export const getTableConfig = ({
  t,
  isLocked,
  deleteDocument,
  fetchDocuments,
  asset,
  type,
  saveFile,
}) => {
  return {
    columnsConfig: {
      documentName: { label: t('document_name') },
      documentType: { label: t('document_type') },
      uploadedBy: { label: t('uploaded_by') },
      dateTime: { label: t('date_and_time') },
      manage: {
        label: t('manage'),
        customElement: (row) => (
          <Space>
            {row.documentName !== '-' && (
              <Button
                type="text"
                icon={<DeleteTwoTone twoToneColor="#FF4D4F" />}
                onClick={() => deleteDocument(row)}
                disabled={isLocked}
                style={{
                  opacity: isLocked ? 0.5 : 1,
                }}
              />
            )}

            <AddFilesModal
              id={asset._id}
              type={type}
              uploadCall={saveFile}
              reloadFileList={fetchDocuments}
              fileTransformer={(file) => {
                const customFileName = `index_${row.index}_${file.name}`;
                return new File([file.originFileObj], customFileName, { type: file.type });
              }}
              customButton={
                row.documentName === '-' ? (
                  <Button type="primary" icon={<UploadOutlined />} disabled={isLocked}>
                    Upload
                  </Button>
                ) : (
                  <Button
                    type="text"
                    icon={<HistoryOutlined style={{ color: '#1677FF' }} />}
                    disabled={isLocked}
                    style={{
                      opacity: isLocked ? 0.5 : 1,
                    }}
                  />
                )
              }
            />
          </Space>
        ),
      },
    },
  };
};
