import { Outlet } from 'react-router-dom';
import { notification } from 'antd';

const OrganizationsOutlet = () => {
  const [notificationApi, contextHolder] = notification.useNotification();

  return (
    <>
      {contextHolder}
      <Outlet />
    </>
  );
};

export default OrganizationsOutlet;
