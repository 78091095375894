import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingModal from './LoadingModal';
import EditModal from './EditModal';
import useVirtualEngineerState from '../../../../atoms/variables';
import { regenerateKnowledge, generateKnowledge } from '../../../../helpers/conversation';
import { useMsal } from '@azure/msal-react';
import './style.css';

const AddKnowledge = () => {
  const { currentEquipment } = useVirtualEngineerState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [generatorId, setGeneratorId] = useState('');
  const [result, setResult] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [initialInput, setInitialInput] = useState('');
  const [inputsUsed, setInputsUsed] = useState([]);
  const { instance } = useMsal();
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseResult = () => {
    setInputsUsed([]);
    setInitialInput('');
    setShowResult(false);
    setGeneratorId('');
    setPageIndex(1);
    setTotalPages(1);
    setResult([]);
    setShowResult(false);
  };

  const handleGenerate = async () => {
    setLoading(true);
    try {
      const response = await generateKnowledge(instance, initialInput, currentEquipment._id);
      if (response && response.content) {
        setResult((prevResults) => [...prevResults, response]);
        setInputsUsed((prevInputs) => [...prevInputs, initialInput]);
        setGeneratorId(response.generatorId);
      }
      setIsModalVisible(false);
      setShowResult(true);
    } catch (error) {
      console.error('Error generating knowledge:', error);
      message.error(`${t('notification_error_generate')}: ${error.message}`, 5);
    } finally {
      setLoading(false);
    }
  };

  const handleRegenerate = async (newInput) => {
    setLoading(true);
    try {
      const response = await regenerateKnowledge(instance, generatorId, newInput);
      setResult((prevResults) => [...prevResults, response]);
      setInputsUsed((prevInputs) => [...prevInputs, newInput]);
      setTotalPages((prev) => prev + 1);
      setPageIndex((prev) => prev + 1);
    } catch (error) {
      console.error('Error regenerating knowledge:', error);
      if (error.response?.status === 400) {
        message.error(t('generator_expired_notification'), 7);
        setIsModalVisible(true);
        handleCloseResult();
      } else {
        message.error(
          `${t('notification_error_generate')}: ${error.response?.data?.message ?? error.message}`,
          5,
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPageIndex) => {
    if (newPageIndex >= 1 && newPageIndex <= totalPages) {
      setPageIndex(newPageIndex);
      setInitialInput(inputsUsed[newPageIndex - 1] || '');
    }
  };

  return (
    <>
      <Button
        size='large'
        icon={<PlusOutlined />}
        style={{
          background: 'black',
          color: 'white',
          border: 'black',
        }}
        className='addKnowledgeButton'
        onClick={showModal}
      >
        {t('add_knowledge')}
      </Button>
      <Modal
        centered
        title={
          <span style={{ fontSize: '1.4rem', fontWeight: '600' }}>{t('add_knowledge_title')}</span>
        }
        className='add-knowledge-modal'
        maskClosable={false}
        visible={isModalVisible}
        onOk={handleGenerate}
        footer={[
          <Button
            key='generate'
            type='primary'
            disabled={!initialInput}
            onClick={handleGenerate}
            style={{
              width: '100%',
              height: '60px',
              background: '#1890ff',
              color: 'white',
              fontSize: '1.1rem',
              opacity: initialInput ? 1 : 0.5,
            }}
          >
            {t('generate_knowledge')}
          </Button>,
        ]}
        width='45%'
        onCancel={() => {
          setIsModalVisible(false);
          handleCloseResult();
        }}
        bodyStyle={{
          minHeight: '40vh',
        }}
      >
        <p style={{ marginBottom: '10px', fontSize: '1rem' }}>{t('add_knowledge_description')}</p>
        <textarea
          placeholder={t('add_knowledge_placeholder')}
          value={initialInput}
          onChange={(e) => setInitialInput(e.target.value)}
          style={{
            width: '100%',
            maxWidth: '100%',
            height: '250px',
            maxHeight: '250px',
            borderRadius: '8px',
            padding: '10px',
            border: '1px solid #d9d9d9',
            boxSizing: 'border-box',
            fontSize: '1rem',
          }}
        ></textarea>
      </Modal>

      <LoadingModal visible={loading && isModalVisible} />

      <EditModal
        visible={showResult}
        onClose={handleCloseResult}
        onRegenerate={handleRegenerate}
        result={result}
        setResult={setResult}
        initialInput={initialInput}
        pageIndex={pageIndex}
        onPageChange={handlePageChange}
        loadingGenerate={loading}
        generatorId={generatorId}
        totalPages={totalPages}
        inputsUsed={inputsUsed}
      />
    </>
  );
};

export default AddKnowledge;
