import React from 'react';
import { Tag, Tooltip } from 'antd';

const taskColors = {
  Required: {
    color: 'default',
    tip: 'Tarefa ainda não agendada',
  },
  Pending: {
    color: 'orange',
    tip: 'Tarefa ainda não executada',
  },
  'Pending AI validation': {
    color: 'blue',
    tip: 'Tarefa em validação da IA',
  },
  'Waiting for files': {
    color: 'blue',
    tip: 'Tarefa aguardando dados',
  },
  Validated: {
    color: 'green',
    tip: 'Tarefa finalizada com sucesso',
  },
  Fail: {
    color: 'red',
    tip: 'Tarefa com erro na validação',
  },
};

const TaskTag = ({ status, name }) => {
  const color = taskColors[status].color || 'default';
  const tip = taskColors[status].tip || 'Status não mapeado';
  return (
    <Tooltip
      size
      color='white'
      overlayInnerStyle={{ color: 'black' }}
      placement='topRight'
      title={tip}
    >
      <Tag color={color}>{name}</Tag>
    </Tooltip>
  );
};

export default TaskTag;
