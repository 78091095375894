import { message } from 'antd';

import watermarkImage from '../images/commissioning/logo_s.png';
import customFont from '../fonts/DrSugiyama-Regular.js';
import symbolFont from '../fonts/Roboto-Regular.js';

import jsPDF from 'jspdf';
import axios from 'axios';
import { protectedResourcesScopes } from '../../auth/config';
import { acquireToken } from '../../auth/msal';
import apiErrorResponseValidation from '../utils/apiErrorResponseValidation';

export const uploadReport = async (instance, file, path) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);
    formData.append('isReport', true);

    const accessToken = await acquireToken(instance, protectedResourcesScopes.core);

    const { data } = await axios.post(
      `${process.env.REACT_APP_CORE_API}/files/${path.split('/')[0]}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data;
  } catch (e) {
    console.log(e);
    message.error(apiErrorResponseValidation(e, 'Erro ao enviar arquivo'));
    return;
  } finally {
  }
};

export const getHiddenPDFBlob = async (id) => {
  const element = document.getElementById(`invisible-overview-content-${id}`);
  const clone = element.cloneNode(true);

  if (!element) return;

  if (clone) {
    clone.style.height = '1775px';
    clone.style.width = '1461px';
  }

  const containsAntInput = element.querySelector('.ant-input') !== null;
  const containsAntSelect = element.querySelector('.ant-select') !== null;
  if (containsAntInput || containsAntSelect) {
    message.info('Por favor, finalize a edição antes de baixar o arquivo!');
    return;
  }

  const pdf = new jsPDF('p', 'mm', 'a4');
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  pdf.setFillColor(255, 255, 255);
  pdf.rect(0, 0, pageWidth, pageHeight, 'F');

  pdf.addFileToVFS('DrSugiyama-Regular.ttf', customFont);
  pdf.addFont('DrSugiyama-Regular.ttf', 'DrSugiyama-Regular', 'normal');
  pdf.addFileToVFS('Roboto-Regular.ttf', symbolFont);
  pdf.addFont('Roboto-Regular.ttf', 'Roboto-Regular', 'normal');

  await pdf.html(clone, {
    width: pageWidth,
    windowWidth: 1459,
    filename: 'jspdf',
    x: 0,
    y: 0,
    callback: () => {
      addWaterMark(pdf, pageWidth, pageHeight);
    },
  });

  return pdf.output('blob');
};

function addWaterMark(doc, pageWidth, pageHeight) {
  var totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    doc.addImage(watermarkImage, 'PNG', 0, 0, pageWidth, pageHeight, '', 'SLOW');
  }

  return doc;
}

export const popupCenter = ({ url, title, w, h }) => {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `,
  );

  if (window.focus) newWindow.focus();
};
