import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useMaintenanceState from '../../../atoms/variables';
import Tabs from '../../../../Template/layoutComponents/Tabs';
import ArrowSteps from '../../../components/CommissioningProgress/index';
import L1Component from '../../../pages/plants/Details/components/CommissioningTab/Steps/L1Component/index';
import './style.css';
import useTemplateState from '../../../../Template/atoms/variables';
import { useTranslation } from 'react-i18next';
import UploadDocumentPage from '../../../../Template/layoutComponents/UploadDocumentPage';
import { deleteFile, getFiles, saveFile } from '../../../helpers/files';
import { getAssetById, updateAsset } from '../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';

const assetTypes = ['machine', 'assembly', 'equipment', 'component'];

const Details = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const { existingAssetTrees, chosenPlant } = useMaintenanceState();
  const location = useLocation();
  const { companyInfo } = useTemplateState();
  const [chosenLevel, setChosenLevel] = useState(null);
  const { plantId, assetId } = useParams();
  const [stepProgress, setStepProgress] = useState({
    L1: 0,
    L2: 0,
    L3: 0,
    L4: 0,
    L5: 0,
  });
  const [mandatoryStepsCompletion, setMandatoryCompletion] = useState({
    L1: false,
    L2: false,
    L3: false,
    L4: false,
    L5: false,
  });
  const [lockedSteps, setLockedSteps] = useState({
    L1: false,
    L2: false,
    L3: false,
    L4: false,
    L5: false,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [asset, setAsset] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (assetId) {
      setIsLoading(false);
      loadLockedSteps();
    }
  }, [assetId]);

  const loadLockedSteps = async () => {
    try {
      const asset = await getAssetById(instance, assetId);
      setAsset(asset);
      if (asset?.lockedSteps) {
        setLockedSteps(asset.lockedSteps);
      }
    } catch (err) {
      console.error('Failed to load locked steps:', err);
    }
  };

  const saveLockedSteps = async (updatedLockedSteps) => {
    try {
      const payload = {
        lockedSteps: updatedLockedSteps,
      };
      const response = await updateAsset(instance, assetId, payload);
    } catch (err) {
      console.error('Failed to update locked steps:', err);
    }
  };

  const handleNextStep = async () => {
    const currentLabel = `L${currentStep + 1}`;
    if (mandatoryStepsCompletion[currentLabel]) {
      const updatedLockedSteps = {
        ...lockedSteps,
        [currentLabel]: true,
      };
      setLockedSteps(updatedLockedSteps);
      try {
        await saveLockedSteps(updatedLockedSteps);
      } catch (err) {
        console.error('Failed to save locked steps:', err);
      }
      setCurrentStep((prev) => Math.min(prev + 1, 5));
    } else {
      console.warn(`Cannot proceed. Step ${currentLabel} is not completed.`);
    }
  };

  useEffect(() => {
    if (currentStep === 1) {
      setStepProgress((prev) => ({
        ...prev,
        L2: 100,
      }));
      setMandatoryCompletion((prev) => ({
        ...prev,
        L2: true,
      }));
    }
  }, [currentStep]);

  useEffect(() => {
    if (!chosenPlant || !plantId || !assetId) {
      navigate(`/maintenance/plants/${plantId}`);
    } else {
      setChosenLevel(findById(chosenPlant, assetId));
    }
  }, [location, assetId]);

  function findById(tree, targetId) {
    if (tree.id === targetId) {
      return tree;
    }

    for (let child of tree.children) {
      const result = findById(child, targetId);
      if (result) {
        return result;
      }
    }

    return null;
  }

  function findItem(id) {
    if (existingAssetTrees) {
      let tree = existingAssetTrees.find((tree) => tree?.id === plantId);
      if (tree) {
        return findById(tree, id);
      }
    }
  }

  function findItemType(id) {
    const item = findItem(id);
    if (item) return item.type;
  }

  return (
    <div id='assetDetails'>
      <Tabs
        tabs={[
          { label: t('general_tab'), content: <div></div>, disabled: true },

          assetTypes.includes(findItemType(assetId)) && {
            label: t('commissioning_tab'),
            content: (
              <ArrowSteps
                currentStep={currentStep}
                mandatoryStepsCompletion={mandatoryStepsCompletion}
                stepProgress={stepProgress}
                components={[
                  <L1Component
                    asset={asset}
                    type={assetTypes.includes(findItemType(assetId)) ? 'asset' : 'position'}
                    setStepProgress={setStepProgress}
                    onNextStep={handleNextStep}
                    isLocked={lockedSteps.L1}
                    setMandatoryCompletion={setMandatoryCompletion}
                  />,
                  <div>L2</div>,
                  <div>L3</div>,
                  <div>L4</div>,
                  <div>L5</div>,
                  <div>Operating</div>,
                ]}
                onStepChange={(step) => setCurrentStep(step)}
              />
            ),
            disabled: !companyInfo?.commissioning,
          },
          { label: t('work_orders_tab'), content: <div></div>, disabled: true },
          {
            label: t('documents_tab'),
            content: (
              <UploadDocumentPage
                id={assetId}
                type={assetTypes.includes(findItemType(assetId)) ? 'asset' : 'position'}
                getCall={getFiles}
                uploadCall={saveFile}
                deleteCall={deleteFile}
              />
            ),

            disabled: false,
          },
          assetTypes.includes(findItemType(assetId)) && {
            label: t('chat_tab'),
            content: <div></div>,
            disabled: !companyInfo.modules?.includes('/virtual-engineer'),
            onClick: () => navigate(`/virtual-engineer/equipments/${assetId}/threads/new`),
          },
        ].filter((tab) => tab)}
        defaultOpen={3}
      />
    </div>
  );
};

export default Details;
