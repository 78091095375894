const sessionKeys = {
  selectedKeys: 'sessionSelectedKeys',
  selectedRows: 'sessionSelectedRows',
};

export const saveSessionSelections = (selectedKeys, selectedRows) => {
  sessionStorage.setItem(sessionKeys.selectedKeys, JSON.stringify(selectedKeys));
  sessionStorage.setItem(sessionKeys.selectedRows, JSON.stringify(selectedRows));
};

export const restoreSessionSelections = () => {
  const sessionStoredKeys = sessionStorage.getItem(sessionKeys.selectedKeys);
  const sessionStoredRows = sessionStorage.getItem(sessionKeys.selectedRows);

  return {
    selectedKeys: sessionStoredKeys && sessionStoredKeys !== undefined ? JSON.parse(sessionStoredKeys) : [],
    selectedRows: sessionStoredRows && sessionStoredRows !== undefined ? JSON.parse(sessionStoredRows) : [],
  };
};

export const clearSessionSelections = () => {
  sessionStorage.removeItem(sessionKeys.selectedKeys);
  sessionStorage.removeItem(sessionKeys.selectedRows);
};

export const updateSessionSelections = (items, newKeys, newRows) => {
  const sessionSelectedInterconnectionsKeys = sessionStorage.getItem(sessionKeys.selectedKeys);
  if(sessionSelectedInterconnectionsKeys) {
    const listSessionKeys = JSON.parse(sessionSelectedInterconnectionsKeys);
    let newListSelected = [];
    listSessionKeys.forEach((equipment) => {
      const inPage = items.find(equip => equip._id === equipment);
      if(!inPage) newListSelected.push(equipment);
    });
    newListSelected = [...newListSelected, ...newKeys];
    sessionStorage.setItem(sessionKeys.selectedKeys, JSON.stringify(newListSelected));
  } else {
    sessionStorage.setItem(sessionKeys.selectedKeys, JSON.stringify(newKeys));
  }

  const sessionSelectedInterconnectionsRows = sessionStorage.getItem(sessionKeys.selectedRows);
  if(sessionSelectedInterconnectionsRows) {
    const listSessionRows = JSON.parse(sessionSelectedInterconnectionsRows);
    let newListRowsSelected = [];
    listSessionRows.forEach((equipment) => {
      const inPage = items.find(equip => equip._id === equipment._id);
      if(!inPage) newListRowsSelected.push(equipment);
    });
    newListRowsSelected = [...newListRowsSelected, ...newRows];
    sessionStorage.setItem(sessionKeys.selectedRows, JSON.stringify(newListRowsSelected));
  } else {
    sessionStorage.setItem(sessionKeys.selectedRows, JSON.stringify(newRows));
  }
};
