import axios from 'axios';
import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { message } from 'antd';
import elementsConfig from '../helpers/elementsConfig.js';
import apiErrorResponseValidation from '../utils/apiErrorResponseValidation.js';
import { acquireToken } from '../../auth/msal.js';
import { getHiddenPDFBlob } from '../helpers/reportAPI.js';
import { useGlobalState } from './useGlobalState.js';

export default function useCrudApi(element) {
  const { instance } = useMsal();
  const {
    apiUrlByField,
    scope,
    apiUrl,
    apiUrlFiltered,
    apiUrlWorksheet,
    apiUrlNew,
    apiUrlTask,
    apiUrlUpdate,
    apiUrlUnlock,
  } = elementsConfig[element];

  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);


  const getAllItems = async (params) => {
    setLoading(true)
    const accessToken = await acquireToken(instance, scope);
    try {
      const { data } = await axios.get(`${apiUrl}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: params
      });

      return data;
    } catch (e) {
      throw e;
    } finally {
      setLoading(false)
    }
  }


  const getItems = async (page, limit, filters, sort) => {
    setLoading(true);
    try {
      const accessToken = await acquireToken(instance, scope);
      let apiUrlMounted = apiUrl;
      if (page !== undefined) {
        apiUrlMounted = apiUrlFiltered.replace('%page%', page).replace('%limit%', limit);
      }
      if (sort && Object.keys(sort).length !== 0) {
        apiUrlMounted = `${apiUrlMounted}${page != undefined ? '&' : '?'}sort=${JSON.stringify(sort)}`;
      }
      const { data } = await axios.get(apiUrlMounted, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          filters: JSON.stringify(filters),
        },
      });
      return data;
    } catch (e) {
      return [];
    } finally {
      setLoading(false);
    }
  };

  const getItem = async (itemId) => {
    setLoading(true);
    try {
      const accessToken = await acquireToken(instance, scope);
      const { data } = await axios.get(`${apiUrl}/${itemId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data;
    } catch (e) {
      return [];
    } finally {
      setLoading(false);
    }
  };

  const getItemByName = async (filter, noMessage = null) => {
    setLoading(true);
    try {
      const accessToken = await acquireToken(instance, scope);
      const { data } = await axios.get(`${apiUrlByField}`, {
        headers: { Authorization: `Bearer ${accessToken}`, filter: filter },
      });
      return data;
    } catch (e) {
      if (!noMessage) message.error(apiErrorResponseValidation(e, 'Erro ao carregar item'));
      return [];
    } finally {
      setLoading(false);
    }
  };

  const createItem = async (item) => {
    setCreating(true);
    try {
      const accessToken = await acquireToken(instance, scope);
      const { data } = await axios.post(apiUrlNew, item, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data;
    } catch (e) {
      message.error(apiErrorResponseValidation(e, 'Erro ao criar item'));
      return;
    } finally {
      setCreating(false);
    }
  };

  const updateItem = async (itemId, item) => {
    setUpdating(true);
    try {
      const accessToken = await acquireToken(instance, scope);
      const response = await axios.put(`${apiUrlUpdate}/${itemId}`, item, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (e) {
      message.error(apiErrorResponseValidation(e, 'Erro ao atualizar item'));
    } finally {
      setUpdating(false);
    }
  };

  const deleteItem = async (userId) => {
    setDeleting(true);
    try {
      const accessToken = await acquireToken(instance, scope);
      const response = await axios.delete(`${apiUrl}/${userId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (response.status === 200) {
        // message.success('Item deletado com sucesso');
        return true;
      } else {
        throw new Error('Erro ao deletar item');
      }
    } catch (e) {
      message.error(apiErrorResponseValidation(e, 'Erro ao deletar item'));
      return false;
    } finally {
      setTimeout(() => {
        setDeleting(false);
      }, 2000);
    }
  };

  const uploadFile = async (fileList, locationId, equipmentCategoryId) => {
    setUploading(true);
    try {
      const formData = new FormData();
      fileList.map((file) => formData.append('file', file.originFileObj));
      const accessToken = await acquireToken(instance, scope);
      const { data } = await axios.post(
        `${apiUrlWorksheet}/${locationId}/${equipmentCategoryId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    } catch (e) {
      message.error(apiErrorResponseValidation(e, 'Erro ao enviar arquivo'));
      return;
    } finally {
      setUploading(false);
    }
  };

  const createBatchItems = async (items) => {
    setCreating(true);
    try {
      const accessToken = await acquireToken(instance, scope);
      const { data } = await axios.post(`${apiUrl}/batch`, items, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data;
    } catch (e) {
      return e.response.data;
    } finally {
      setCreating(false);
    }
  };

  const createBatchItemsAprove = async (items, action) => {
    setCreating(true);
    try {
      const accessToken = await acquireToken(instance, scope);
      const { data } = await axios.post(`${apiUrlTask}/signature/${action}`, items, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data;
    } catch (e) {
      message.error(apiErrorResponseValidation(e, 'Erro ao aprovar itens'));
      return;
    } finally {
      setCreating(false);
    }
  };

  const mountPath = (location_id, equipment_id, task_id) => {
    if (location_id && equipment_id && task_id) return `${location_id}/${equipment_id}/${task_id}`;
    else if (location_id && equipment_id) return `${location_id}/${equipment_id}`;
    else return `${location_id}`;
  };

  const getReports = async (locationId, equipmentId = null, taskId = null) => {
    setDownloading(true);
    try {
      const accessToken = await acquireToken(instance, scope);
      const url = `${apiUrl}/get/${mountPath(locationId, equipmentId, taskId)}`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    } catch (e) {
      return [];
    } finally {
      setDownloading(false);
    }
  };

  const decodeFilenameToUTF8 = (filename) => {
    return new TextDecoder('utf-8').decode(new TextEncoder().encode(filename));
  };

  const getNormalizedFileName = (task) => {
    const normalized_name = task?.interconnection?.name.replace(' ', '');
    let version = task.versions ? task.versions.current + 1 : 1;
    let fileName = `${task?.interconnection?.system?.name}_${normalized_name}_${task?.instrument?.name}_${task.attempt}_v${version}.pdf`;
    fileName = fileName.replace(/ /g, '').replace(/\//g, '_');
    return fileName;
  };

  const uploadReport = async (locationId, equipmentId, selectedRows) => {
    setUploading(true);
    try {
      for (const task of selectedRows) {
        const pdfBlob = await getHiddenPDFBlob(task._id);
        const pdf = new File([pdfBlob], getNormalizedFileName(task), { type: 'application/pdf' });
        const formData = new FormData();
        formData.append('file', pdf, decodeFilenameToUTF8(pdf.name));
        const accessToken = await acquireToken(instance, scope);
        await axios.post(
          `${apiUrl}/upload/${mountPath(locationId, equipmentId, task._id)}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'multipart/form-data',
              'Content-Encoding': 'UTF-8',
            },
          },
        );
      }
      return;
    } catch (e) {
      message.error(apiErrorResponseValidation(e, 'Erro ao salvar o relatório!'));
      return;
    } finally {
      setUploading(false);
    }
  };

  const deleteReports = async (locationId, equipmentId = null, taskId = null) => {
    setLoading(true);
    try {
      const accessToken = await acquireToken(instance, scope);
      const url = `${apiUrl}/${mountPath(locationId, equipmentId, taskId)}`;
      const { data } = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    } catch (e) {
      return [];
    } finally {
      setLoading(false);
    }
  };

  const unlockItem = async (itemId, item) => {
    setUpdating(true);
    try {
      const accessToken = await acquireToken(instance, scope);
      const response = await axios.put(`${apiUrlUnlock}/${itemId}`, item, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (e) {
      message.error(apiErrorResponseValidation(e, 'Erro ao atualizar item'));
    } finally {
      setUpdating(false);
    }
  };

  return {
    getAllItems,
    getItems,
    getItem,
    getItemByName,
    loading,
    createItem,
    creating,
    updateItem,
    unlockItem,
    updating,
    deleteItem,
    deleting,
    setDeleting,
    uploadFile,
    uploading,
    createBatchItems,
    createBatchItemsAprove,
    getReports,
    uploadReport,
    deleteReports,
    downloading,
  };
}
