import React from 'react';
import { Typography, Flex } from 'antd';

const { Title, Text } = Typography;

export default function WorkOrderTitles({ titleForms, titleTable, workOrder }) {
  if (titleForms) {
    return (
      <Flex style={{ padding: 0 }}>
        <Title style={{ margin: '0' }} level={5}>
          {titleForms}: {workOrder?.token}
        </Title>
      </Flex>
    );
  }
  return (
    <Title level={5}>
      {titleTable}
    </Title>
  );
}
