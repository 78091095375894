import React from 'react';
import { Tag } from 'antd';

const statusColors = {
  Scheduled: 'default',
  Pending: 'orange',
  Fail: 'red',
  Success: 'green',
  Validated: 'green',
  Ongoing: 'blue',
  Incomplete: 'orange',
  Completed: 'green',
  Rejected: 'red',
  'Pending Approval': 'blue',
  'Ongoing approval': 'blue',
  Approved: 'green',
};

const StatusTag = ({ status }) => {
  const color = statusColors[status] || 'default';

  return <Tag color={color}>{status}</Tag>;
};

export default StatusTag;
