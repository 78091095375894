import { atom, atomFamily, selector, useRecoilValue, useSetRecoilState } from 'recoil';

export const existingAssetTrees = atom({
  key: 'existingAssetTrees',
  default: [],
});
export const chosenPlant = atom({
  key: 'chosenPlant',
  default: null,
});
export const instruments = atom({
  key: 'instruments',
  default: [],
});
export const chosenInstrument = atom({
  key: 'chosenInstrument',
  default: null,
});
export const assets = atom({
  key: 'assets',
  default: [],
});
export const chosenAsset = atom({
  key: 'chosenAsset',
  default: null,
});
export const orgs = atom({
  key: 'orgs',
  default: [],
});
export const departments = atom({
  key: 'departments',
  default: [],
});
export const systems = atom({
  key: 'systems',
  default: [],
});
export const manufacturers = atom({
  key: 'manufacturers',
  default: [],
});

export const costcenters = atom({
  key: 'costcenters',
  default: [],
});

export const chosenSystem = atom({
  key: 'chosenSystem',
  default: null
})

export default function useMaintenanceState() {
  return {
    existingAssetTrees: useRecoilValue(existingAssetTrees),
    setExistingAssetTrees: useSetRecoilState(existingAssetTrees),
    chosenPlant: useRecoilValue(chosenPlant),
    setChosenPlant: useSetRecoilState(chosenPlant),
    instruments: useRecoilValue(instruments),
    setInstruments: useSetRecoilState(instruments),
    chosenInstrument: useRecoilValue(chosenInstrument),
    setChosenInstrument: useSetRecoilState(chosenInstrument),
    assets: useRecoilValue(assets),
    setAssets: useSetRecoilState(assets),
    chosenAsset: useRecoilValue(chosenAsset),
    setChosenAsset: useSetRecoilState(chosenAsset),
    orgs: useRecoilValue(orgs),
    setOrgs: useSetRecoilState(orgs),
    departments: useRecoilValue(departments),
    setDepartments: useSetRecoilState(departments),
    systems: useRecoilValue(systems),
    setSystems: useSetRecoilState(systems),
    manufacturers: useRecoilValue(manufacturers),
    setManufacturers: useSetRecoilState(manufacturers),
    costcenters: useRecoilValue(costcenters),
    setCostcenters: useSetRecoilState(costcenters),
    chosenSystem: useRecoilValue(chosenSystem),
    setChosenSystem: useSetRecoilState(chosenSystem),
  };
}
