import { Outlet, useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import useGlobalState from '../../../../Template/atoms/variables';
import { useEffect } from 'react';
import { sleep } from '../../../../helpers/misc';
import { getSystems } from '../../../helpers/apiCalls';
import { useTranslation } from 'react-i18next';
import useMaintenanceState from '../../../atoms/variables';
import { useMsal } from '@azure/msal-react';

const SystemsOutlet = () => {
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { t } = useTranslation();

  const { instance } = useMsal();

  const { setPendingApiCalls } = useGlobalState();
  const { setSystems, chosenInstrument, chosenPlant } = useMaintenanceState();

  useEffect(() => {
    if (chosenPlant) triggerApiCalls();
    else {
      navigate('/maintenance/plants');
    }
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getSystems']));

      const response = await getSystems(instance);
      console.log(response);
      setSystems(response);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getSystems'));
    }
  };

  return (
    <>
      {contextHolder}
      <Outlet />
    </>
  );
};

export default SystemsOutlet;
