import { useEffect, useState } from 'react';
import { Button } from 'antd';

import { PlusOutlined, CloseCircleOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import Input from '../Input';
import Forms from '../Forms';

import search from '../../images/search.svg';
import viewDetails from '../../images/viewDetails.svg';

import { useNavigate, useParams } from 'react-router-dom';
import useGlobalState from '../../atoms/variables';
import { useTranslation } from 'react-i18next';
import CustomOrderByButton from '../OrderButton';

import { useMsal } from '@azure/msal-react';
import { notification } from 'antd';

import DoubleColumnTemplate from '../DoubleColumn';

import useSettingsState from '../../../Settings/atoms/variables';

import './style.css';

const CrudPage = ({
  title,
  newText,
  detailsLink,
  defaultItem,
  getAllItems,
  newItem,
  updateItem,
}) => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const { t } = useTranslation();

  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls } = useGlobalState();

  const { setViewDetailsCategory, setViewDetailsCategories } = useSettingsState();

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getAllItems']));
      let response;

      response = await getAllItems(instance);

      setItems(response);
    } catch (err) {
      // API fail notification
      notificationApi.error({
        message: 'Falha ao carregar categorias existentes',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getAllItems'));
    }
  };

  const [chosenItem, setChosenItem] = useState(null);

  const [searchString, setSearchString] = useState(null);
  const [asc, setAsc] = useState(true);

  const [creatingItem, setCreatingItem] = useState(false);
  const [editingItem, setEditingItem] = useState(false);

  const [items, setItems] = useState([]);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState(false);

  const disableCreateSave = () => {
    if (creatingItem) {
      return (
        items?.some((cat) =>
          cat.properties
            .filter((prop) => prop.main == true)
            ?.some((prop) =>
              chosenItem?.properties
                ?.filter((currentProp) => currentProp.main == true)
                ?.some(
                  (currentProp) =>
                    (prop.key == currentProp.key && prop.value == currentProp.value) ||
                    currentProp.value == '',
                ),
            ),
        ) ||
        chosenItem.properties.some(
          (prop) =>
            prop.value === null ||
            !prop.type ||
            prop.label == '' ||
            prop.label == t('new_property') ||
            prop.options?.some((opt) => opt?.label == '') ||
            (prop.type.toLowerCase().includes('select') && prop.options?.length === 0),
        )
      );
    }
    if (editingItem)
      return chosenItem.properties.some(
        (prop) =>
          !prop.type ||
          prop.label == '' ||
          prop.label == t('new_property') ||
          prop.options?.some((opt) => opt?.label == '') ||
          (prop.type.toLowerCase().includes('select') && prop.options?.length === 0),
      );

    return true;
  };

  useEffect(() => {
    setChosenItem(null);
    setCreatingItem(false);
    setEditingItem(false);
  }, [t]);

  useEffect(() => {
    console.log(chosenItem);
    const alreadyExists = items?.some((cat) =>
      cat.properties
        .filter((prop) => prop.main == true)
        .some((prop) =>
          chosenItem?.properties
            .filter((currentProp) => currentProp.main == true)
            .some((currentProp) => {
              if (
                prop.key == currentProp.key &&
                prop.value !== '' &&
                prop.value == currentProp.value
              ) {
                setNotificationContent(currentProp.label);
                return true;
              }
            }),
        ),
    );

    setShowNotification(alreadyExists);
  }, [chosenItem]);

  const listColumn = (
    <div className='columnContainer' style={{ padding: 0 }}>
      <div className='columnHeader' style={{ padding: '2rem 2rem 0 2rem' }}>
        <div className='columnTitle'>{title}</div>
        <Button
          disabled={creatingItem || editingItem}
          icon={<PlusOutlined />}
          size='large'
          style={{
            background: creatingItem || editingItem ? 'grey' : 'black',
            color: 'white',
          }}
          onClick={() => {
            setEditingItem(false);
            setCreatingItem(true);
            setChosenItem(JSON.parse(JSON.stringify(defaultItem)));
          }}
        >
          {newText}
        </Button>
      </div>
      <div className='searchControls' style={{ padding: '1.5rem 2rem 0 2rem' }}>
        <Input
          value={searchString ?? ''}
          onChange={(event) => {
            setSearchString(event.target.value ?? null);
          }}
          style={{ maxWidth: '80%' }}
          placeholder={t('search')}
          icon={<img src={search} />}
        />
        <CustomOrderByButton
          onClick={() => {
            setAsc((current) => !current);
          }}
        />
      </div>
      <div className='categoryList'>
        {items
          ?.filter((category) => {
            if (searchString)
              return category.label.toLowerCase().includes(searchString?.toLowerCase());
            else return true;
          })
          .concat(creatingItem ? [defaultItem] : [])
          .sort((a, b) =>
            a.new == true ? -1 : asc ? (a.label < b.label ? -1 : 1) : a.label < b.label ? 1 : -1,
          )
          ?.map((category, index) => {
            if (category.new) {
              return (
                <div
                  className={`categoryListItem ${chosenItem?.id == category.id ? 'selected' : ''} creating`}
                >
                  {category.label}
                </div>
              );
            } else
              return (
                <>
                  {index === 0 && <div className='categoryListDivider'></div>}
                  <div
                    className={`categoryListItem ${chosenItem?.id == category.id ? 'selected' : ''} ${creatingItem || editingItem ? 'disabled' : ''}`}
                    onClick={() => {
                      if (!creatingItem && !editingItem) {
                        setChosenItem(category);
                        setSearchString(null);
                      }
                    }}
                  >
                    {category.label}
                  </div>
                  <div className='categoryListDivider'></div>
                </>
              );
          })}
      </div>
    </div>
  );

  const editColumn = (
    <div
      className='columnContainer'
      style={{
        padding: 0,
        maxHeight: 'calc(100vh - 6rem)',
        overflow: 'auto',
        gap: '2rem',
      }}
    >
      {chosenItem && (
        <div className='columnHeader' style={{ padding: '2rem 2rem 0 2rem' }}>
          <div className='columnTitle'>
            {chosenItem?.label?.length > 0 ? chosenItem.label : newText}
          </div>
          {chosenItem && !chosenItem?.new && !creatingItem && (
            <div
              className='viewDetailsContainer'
              onClick={() => {
                setViewDetailsCategory(chosenItem);
                setViewDetailsCategories(items);
                navigate(`${detailsLink}/${chosenItem.id}`);
              }}
            >
              <div className='viewDetailsIcon'>
                <img src={viewDetails} />
              </div>
              <div className='viewDetailsText'>{t('view_details')}</div>
            </div>
          )}
        </div>
      )}
      <div className='categoryPropertiesContainer' style={{ padding: '1.5rem 2rem 5rem 2rem' }}>
        <Forms
          inputs={chosenItem?.properties}
          onInputBlur={() => {
            if (showNotification) {
              notificationApi.info({
                message: `Valores repetidos`,
                description: `Valor do campo '${notificationContent}' já cadastrado. Use outro valor.`,
                duration: 5,
              });
            }
          }}
          onInputChange={(event, inputIndex) => {
            if (!creatingItem) {
              setEditingItem(true);
            }

            setChosenItem((current) => {
              if (current) {
                return {
                  ...current,
                  properties: current.properties?.map((item, index) => {
                    if (index == inputIndex) {
                      item.value = item.type.toLowerCase().includes('select')
                        ? { id: event.target.value, label: '' }
                        : event.target.value;
                    }
                    return item;
                  }),
                  label: current.properties[0].value,
                };
              }
            });
          }}
        />
      </div>
      <div className='manageCategoriesButtonsContainer'>
        {(creatingItem || editingItem) && (
          <Button
            id='cancel'
            size='large'
            icon={<CloseCircleOutlined />}
            style={{
              borderColor: 'black',
              background: 'white',
              color: 'black',
            }}
            onClick={() => {
              setEditingItem(false);
              setCreatingItem(false);
              setChosenItem(null);
            }}
          >
            {t('cancel_changes')}
          </Button>
        )}
        {chosenItem && (
          <Button
            id='create'
            className={disableCreateSave() ? 'disabled' : ''}
            size='large'
            disabled={disableCreateSave()}
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            icon={<AppstoreAddOutlined />}
            onClick={async () => {
              if (creatingItem) {
                // SEND ITEM TO BFF (CREATE)
                try {
                  setPendingApiCalls((current) => current.concat(['newItem']));
                  let mongoId;

                  mongoId = await newItem(instance, chosenItem);

                  setItems((current) => {
                    return current.concat([
                      {
                        ...chosenItem,
                        properties: chosenItem.properties?.map((prop, index) => {
                          if (prop.main == true) prop.disabled = true;
                          return prop;
                        }),
                        new: false,
                        id: mongoId,
                      },
                    ]);
                  });
                } catch (err) {
                  // API fail notification
                  notificationApi.error({
                    message: 'Falha ao criar nova categoria',
                    description: err?.response?.data?.message
                      ? `${err?.response?.data?.message} (${err?.response?.status})`
                      : err?.message,
                    duration: 5,
                  });
                } finally {
                  setChosenItem(null);
                  setPendingApiCalls((current) => current.filter((item) => item !== 'newItem'));
                }
              } else {
                setItems((current) => {
                  return current
                    .filter((cat) => cat.id != chosenItem.id)
                    .concat([{ ...chosenItem }]);
                });

                // SEND ITEM TO BFF (UPDATE)
                try {
                  setPendingApiCalls((current) => current.concat(['updateItem']));
                  await updateItem(instance, chosenItem);
                } catch (err) {
                  // API fail notification
                  notificationApi.error({
                    message: 'Falha ao atualizar categoria existente',
                    description: err?.response?.data?.message
                      ? `${err?.response?.data?.message} (${err?.response?.status})`
                      : err?.message,
                    duration: 5,
                  });
                } finally {
                  setPendingApiCalls((current) => current.filter((item) => item !== 'updateItem'));
                }
              }
              setCreatingItem(false);
              setEditingItem(false);
            }}
          >
            {creatingItem ? t('create_item_settings') : t('save_item_settings')}
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <div id='CrudPage'>
      {contextHolder}
      <DoubleColumnTemplate
        columnColors={['linear-gradient(180deg, #FDFDFD 0%, #F7F7F7 100%)', '#FFFFFF']}
        firstColumnContent={listColumn}
        secondColumnContent={editColumn}
      />
    </div>
  );
};

export default CrudPage;
