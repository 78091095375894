import React, { useMemo } from 'react';
import { Modal } from 'antd';
import { useRecoilValue } from 'recoil';
import Upload from '../UploadArea/index';
import { uploadStatusState } from '../../../atoms/globalVariables';

const UploadTaskModal = ({ visible, onCancel, setEquipments, loadData }) => {
  const uploadStatus = useRecoilValue(uploadStatusState);
  const isUploadingOrDone = useMemo(
    () => uploadStatus === 'uploading' || uploadStatus === 'done',
    [uploadStatus],
  );

  const isDone = useMemo(() => uploadStatus === 'done', [uploadStatus]);

  return (
    <Modal
      title={!isUploadingOrDone && 'Que tipo de equipamento você deseja adicionar?'}
      open={visible}
      onCancel={onCancel}
      closable={!isUploadingOrDone}
      footer={null}
      width={!isDone ? '30vw' : '70vw'}
      centered
    >
      <Upload onCancel={onCancel} setEquipments={setEquipments} loadData={loadData} />
    </Modal>
  );
};

export default UploadTaskModal;
