import React, { useState, useEffect, useMemo, useCallback } from 'react';
import CustomTable from '../../../../components/CustomTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageContent from '../../../../layoutComponents/PageContent';
import PageHeader from '../../../../layoutComponents/PageHeader';
import { useGlobalState } from '../../../../hooks/useGlobalState';
import useCrudApi from '../../../../hooks/useCrudApi';
import { message, Spin, Tag } from 'antd';
import { mapCompanyNameColumn, mapSearchableColumn, mapRoleColumn } from './helpers/filters';
import Register from '../Register';
import { ClearOutlined } from '@ant-design/icons';
import './style.css';
import EditModal from '../EditModal';

export default function List() {
  const navigate = useNavigate();
  const element = 'executors';
  const { getItems, deleteItem } = useCrudApi(element);
  const [listExecutors, setListExecutors] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const { setCommissioningTree } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [searchParams] = useSearchParams();
  const [filtersState, setFiltersState] = useState({});
  const [sorterState, setSorterState] = useState({});
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const [registerVisible, setRegisterVisible] = useState(false);

  const loadData = useCallback(async () => {
    if (!isInitialized) return;
    setLoading(true);
    const filterParams = mapFilters();
    const sortParams = getSortParams();
    try {
      const result = await getItems(undefined, undefined, filterParams, sortParams);
      setListExecutors(result?.items || []);
    } catch (error) {
      console.error('Erro ao carregar os executores:', error);
      message.error('Erro ao carregar os executores');
    } finally {
      setLoading(false);
    }
  }, [filtersState, sorterState, getItems]);

  const loadAllFiltersData = async () => {
    try {
      const fullData = await getItems();
      const companies = Array.from(new Set(fullData.map((item) => item.company_name)));
      const roles = [...new Set(fullData.map((item) => item.role))].filter(Boolean);
      setAllCompanies(companies);
      setAllRoles(roles);
    } catch (error) {
      console.error('Erro ao carregar dados completos:', error);
    }
  };

  useEffect(() => {
    loadAllFiltersData();
  }, []);

  const mapFilters = () => ({
    name: filtersState.name?.[0],
    email: filtersState.email?.[0],
    phone: filtersState.phone?.[0],
    role: filtersState.role?.[0],
    company_name: filtersState.company_name?.[0],
    'location.name': filtersState['location.name']?.[0],
  });

  const getSortParams = () => {
    const sortField = searchParams.get('sortField');
    const sortOrder = searchParams.get('sortOrder');
    if (sortField && sortOrder) {
      setSorterState({ field: sortField, order: sortOrder });
    }
    return sortField ? { [sortField]: sortOrder === 'ascend' ? 1 : -1 } : {};
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });

    const updatedFilters = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        updatedFilters[key] = filters[key];
      }
    });
    if (Object.keys(updatedFilters).length > 0 || sorter.order) {
      setFiltersState(updatedFilters);
      setSorterState({ field: sorter.field, order: sorter.order || null });
      updateSearchParams(updatedFilters, { field: sorter.field, order: sorter.order });
    }
  };

  const initializeParams = () => {
    const filters = getFiltersFromSearchParams();
    const sortField = searchParams.get('sortField');
    const sortOrder = searchParams.get('sortOrder');
    setFiltersState(filters);
    if (sortField && sortOrder) {
      setSorterState({ field: sortField, order: sortOrder });
    }
    setIsInitialized(true);
  };

  useEffect(() => {
    initializeParams();
  }, [searchParams]);

  useEffect(() => {
    if (!loading) {
      loadData();
    }
  }, [filtersState, sorterState]);

  const getFiltersFromSearchParams = () => {
    const filters = {};
    searchParams.forEach((value, key) => {
      if (key.startsWith('filter_')) {
        filters[key.replace('filter_', '')] = [value];
      }
    });
    return filters;
  };

  const updateSearchParams = useCallback(
    (filters, sorter) => {
      const params = new URLSearchParams(searchParams);

      Object.keys(filters).forEach((key) => {
        if (filters[key] && filters[key].length > 0) {
          params.set(`filter_${key}`, filters[key][0]);
        } else {
          params.delete(`filter_${key}`);
        }
      });

      if (sorter.field) {
        if (sorter.order) {
          params.set('sortField', sorter.field);
          params.set('sortOrder', sorter.order);
        } else {
          params.delete('sortField');
          params.delete('sortOrder');
          setFiltersState({});
          setSorterState({});
        }
      }

      setFiltersState(filters);
      setSorterState(sorter);

      navigate(`/maintenance/executors?${params.toString()}`, { replace: true });
    },
    [navigate, searchParams],
  );

  const handleDeleteClick = async () => {
    if (!selectedKeys.length) return;

    try {
      await Promise.all(selectedKeys.map((id) => deleteItem(id)));
      message.success('Executores excluídos com sucesso');
      setListExecutors((prev) => prev.filter((item) => !selectedKeys.includes(item._id)));
      setSelectedKeys([]);
      setSelectedRows([]);
    } catch (error) {
      message.error('Erro ao excluir executores');
    }
  };

  const handleClearFilters = () => {
    setFiltersState({});
    setSorterState({});
    setListExecutors(null);

    navigate(`/maintenance/executors`, { replace: true });
  };

  const handleEditFinish = () => {
    loadData();
  };

  const tableColumns = [
    { title: 'Nome', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Telefone', dataIndex: 'phone', key: 'phone' },
    { title: 'Função/Cargo', dataIndex: 'role', key: 'role' },
    {
      title: 'Localização',
      dataIndex: 'location.name',
      key: 'location.name',
      render: (text, record) => record.location?.map((local) => <Tag>{local?.name}</Tag>),
    },
    { title: 'Compania', dataIndex: 'company_name', key: 'company_name' },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => <EditModal record={record} handleEditFinish={handleEditFinish} />,
    },
  ];

  const modifiedColumns = tableColumns.map((column) => {
    if (column.dataIndex === 'company_name') {
      // return mapCompanyNameColumn(column, allCompanies, sorterState, filtersState);
      return column;
    }
    if (column.dataIndex === 'role') {
      // return mapRoleColumn(column, allRoles, sorterState, filtersState);
      return column;
    }
    if (column.dataIndex === 'actions') return column;
    // return mapSearchableColumn(column, sorterState, filtersState);
    return column;
  });

  const handleCloseModal = () => {
    setRegisterVisible(false);
  };

  const handleFinishModal = () => {
    setRegisterVisible(false);
    loadData();
  };

  const hasSorterOrFilter = useMemo(() => {
    const hasFilter = Object.keys(filtersState).some((key) => filtersState[key]?.length > 0);
    const hasSorter = !!sorterState.field;
    return hasFilter || hasSorter;
  }, [sorterState, filtersState, searchParams]);

  return (
    <div>
      <PageHeader
        keyElement='Executores'
        buttons={[
          {
            name: 'Novo executor',
            visible: true,
            function: setRegisterVisible,
          },
          {
            name: 'Limpar Filtros',
            function: handleClearFilters,
            visible: hasSorterOrFilter,
            disabled: false,
            design: 'default',
            icon: <ClearOutlined />,
          },
        ]}
      />
      <PageContent
        Content={() =>
          loading ? (
            <Spin tip='Carregando executores...' />
          ) : (
            <>
              <Register
                executors={listExecutors}
                visible={registerVisible}
                handleClose={handleCloseModal}
                handleCancel={handleCloseModal}
                handleFinish={handleFinishModal}
              />
              <CustomTable
                items={listExecutors}
                columns={modifiedColumns}
                selectedRowKeys={selectedKeys}
                setSelectedRowKeys={setSelectedKeys}
                setSelectedRows={setSelectedRows}
                onDelete={handleDeleteClick}
                onPageChange={handleTableChange}
                pagination={{
                  current: pagination?.current,
                  pageSize: pagination?.pageSize,
                  total: listExecutors?.length || 1,
                  showSizeChanger: true,
                }}
                loading={loading}
              />
            </>
          )
        }
      />
    </div>
  );
}
