import { useTranslation } from 'react-i18next';
import deleteIcon from '../../images/deleteIcon.svg';
import openLine from '../../images/openLine.svg';
import closeLine from '../../images/closeLine.svg';
import FormsBody from '../CategoryFormsBody';

const SecondaryCategories = ({
  detailPage = false,
  chosenCategory,
  setChosenCategory,
  setEditingCategory,
  companyInfo,
}) => {
  const { t } = useTranslation();
  return (
    <div className='categorySecondaryPropertiesContainer'>
      {chosenCategory?.properties
        .filter((prop) => !prop.main)
        .map((prop, index) => {
          return (
            <div
              className={`categorySecondaryPropertyContainer ${detailPage ? 'detailPage' : ''}`}
              style={!companyInfo?.commissioning ? { borderTop: '1px solid #ebecee' } : {}}
              key={`categorySecondaryPropertyContainer-${index}`}
            >
              <div
                className='categorySecondaryPropertyHeader'
                onClick={() => {
                  setChosenCategory((current) => {
                    return {
                      ...current,
                      properties: current.properties.map((item, ind) => {
                        if (ind === index) {
                          // Crie uma cópia do objeto antes de alterá-lo
                          return {
                            ...item,
                            open: !item.open, // Inverta o valor de 'open'
                          };
                        }
                        return item; // Retorne o item sem alterações
                      }),
                    };
                  });
                }}
              >
                <div className='categorySecondaryTitle'>
                  <div className='categorySecondaryTitleText'>
                    {prop?.label?.length > 0 ? prop.label : `${t('new_property')}`}
                  </div>
                  <div
                    className='categorySecondaryTitleDelete'
                    onClick={() => {
                      setEditingCategory(true);
                      setChosenCategory((current) => {
                        return {
                          ...current,
                          properties: [
                            ...current.properties.filter((cat) => cat.main),
                            ...current.properties
                              .filter((cat) => !cat.main)
                              .filter((item, ind) => {
                                if (item.main) return true;
                                else return ind != index;
                              }),
                          ],
                        };
                      });
                    }}
                  >
                    <img src={deleteIcon} />
                  </div>
                </div>
                <div className='categorySecondaryArrow'>
                  <img src={chosenCategory?.properties?.[index]?.open ? closeLine : openLine} />
                </div>
              </div>
              <div
                className={`categorySecondaryPropertyContent ${chosenCategory?.properties?.[index]?.open ? 'open' : 'close'} `}
              >
                <FormsBody
                  setEditingCategory={setEditingCategory}
                  setChosenCategory={setChosenCategory}
                  index={index}
                  prop={prop}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SecondaryCategories;
