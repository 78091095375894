import React, { useEffect, useState } from 'react';
import { Button, Typography, Space, message, Layout, Collapse, Spin } from 'antd';

import useCrudApi from '../../../hooks/useCrudApi';
import { useRecoilState } from 'recoil';
import { responseApiState } from '../../../atoms/globalVariables';
import elementsConfig from '../../../helpers/elementsConfig';

import './style.css';
import CustomTable from '../../CustomTable';
import Loading from '../../Loading';

const { Title } = Typography;

const UploadSummary = ({ onCancel, setEquipments, loadData }) => {
  const { Panel } = Collapse;
  const element = 'interconnections';
  const [response, setResponse] = useRecoilState(responseApiState);
  const { createBatchItems, creating } = useCrudApi('equipments');
  const [uploadResponse, setUploadResponse] = useState(null);
  const [countSuccess, setCountSuccess] = useState(0);
  const [countFail, setCountFail] = useState(0);
  const [activeKey, setActiveKey] = useState(1);
  const [pageSuccess, setPageSuccess] = useState(1);
  const [pageFail, setPageFail] = useState(1);
  const limit = 6;

  useEffect(() => {
    const process = async () => {
      let items = Object.values(response).reduce((prev, curr) => prev.concat(curr), []);
      const result = await createBatchItems(items);
      setUploadResponse(result);
      setCountFail(result.failedCount);
      setCountSuccess(result.insertedCount);
    };
    process();
  }, []);

  const handlePageSuccessChange = (pagination) => {
    setPageSuccess(pagination.current);
  };

  const handlePageFailChange = (pagination) => {
    setPageFail(pagination.current);
  };

  return (
    <div style={{ height: '70vh', backgroundColor: 'white', alignContent: 'center' }}>
      {!uploadResponse ? (
        <Loading loading />
      ) : (
        <Layout
          style={{
            display: 'grid',
            gridTemplateRows: '10% 80% 10%',
            gridTemplateColumns: '100%',
            height: '70vh',
          }}
        >
          <Layout id='layout-title'>
            <Title level={5} style={{ margin: 0 }}>
              Verifique os itens antes de submeter no sistema
            </Title>
          </Layout>
          <Layout id='layout-info-content'>
            {uploadResponse && (
              <Collapse
                style={{ fontSize: '15px', maxHeight: '100%', overflowY: 'auto' }}
                activeKey={activeKey}
                onChange={() => (activeKey === 1 ? setActiveKey(2) : setActiveKey(1))}
              >
                <Panel
                  header={
                    <span style={{ color: 'green', fontWeight: 'bold' }}>
                      Sucesso ({countSuccess})
                    </span>
                  }
                  key='1'
                >
                  <CustomTable
                    items={uploadResponse && uploadResponse.insertedDocs}
                    columns={elementsConfig[element].uploadTableColumns}
                    pagination={{
                      current: pageSuccess,
                      pageSize: limit,
                      total: uploadResponse ? uploadResponse.insertedCount : 0,
                      showSizeChanger: false,
                    }}
                    onPageChange={handlePageSuccessChange}
                  />
                </Panel>
                <Panel
                  header={
                    <span style={{ color: 'red', fontWeight: 'bold' }}>Falha ({countFail})</span>
                  }
                  key='2'
                >
                  <CustomTable
                    items={uploadResponse && uploadResponse.errors}
                    columns={elementsConfig[element].uploadErrorTableColumns}
                    pagination={{
                      current: pageFail,
                      pageSize: limit,
                      total: uploadResponse ? uploadResponse.failedCount : 0,
                      showSizeChanger: false,
                    }}
                    onPageChange={handlePageFailChange}
                  />
                </Panel>
              </Collapse>
            )}
          </Layout>
          <Space id='space-buttons'>
            <Button
              loading={creating}
              type='primary'
              onClick={() => {
                onCancel();
                // setEquipments(null);
                loadData();
              }}
            >
              Confirmar
            </Button>
          </Space>
        </Layout>
      )}
    </div>
  );
};

export default UploadSummary;
