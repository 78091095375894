import React from 'react';
import { Form, Input, DatePicker, Button, Spin, InputNumber, Row, Col } from 'antd';
import { useRecoilValue } from 'recoil';
import { modalTypeState } from '../../atoms/globalVariables';
import DynamicSelect from '../DynamicSelect';
import StatusTag from '../StatusTag';
import './style.css';
import moment from 'moment';

export default function CustomForm({
  form,
  fields,
  creating,
  editing,
  handleSubmit,
  handleCancel,
  readOnly,
  hideButtons,
  informationMenu,
  isTwoColumns,
  cancelLabel = 'Cancelar',
  confirmLabel = 'Confirmar',
}) {
  const modalType = useRecoilValue(modalTypeState);

  const getFieldValidator = (field) => {
    if (field.regex) {
      return (_, value) => {
        const regexPattern = new RegExp(field.regex);
        if (regexPattern.test(value)) return Promise.resolve();
        return Promise.reject(new Error(field.regexMessage));
      };
    }
    return field.validateFunction ? field.validateFunction : undefined;
  };

  const renderField = (field) => {
    switch (field.type) {
      case 'tag':
        return <StatusTag status={field.value} />;
      case 'input':
        return (
          <Input
            disabled={field.disabled || readOnly}
            defaultValue={field.value}
            placeholder={field?.placeholder}
            maxLength={field?.maxLength}
          />
        );
      case 'input-number':
        return (
          <InputNumber
            disabled={field.disabled || readOnly}
            defaultValue={field.value}
            placeholder={field?.placeholder}
            maxLength={field?.maxLength}
            style={{ width: '100%' }}
            min={0}
          />
        );
      case 'select-predefined':
        return (
          <DynamicSelect
            label={field.label}
            element={field.selectItem}
            multiple={field.multiple}
            disabled={field.disabled || readOnly}
            {...(field.preSelectedItems && { selectedValue: field.preSelectedItems })}
            items={field.preSelectedItems}
          />
        );
      case 'select':
        const fieldValue = form.getFieldValue(field.key);
        return (
          <DynamicSelect
            label={field.label}
            element={field.selectItem}
            multiple={field.multiple}
            disabled={field.disabled || readOnly}
            selectedValue={fieldValue}
            items={field.items}
          />
        );
      case 'date':
        return (
          <>
            {editing ? (
              <DatePicker allowClear format='YYYY-MM-DD' />
            ) : (
              <DatePicker disabled={true} defaultValue={moment(field.value)} />
            )}
          </>
        );
      case 'custom':
        return field.renderComponent ? field.renderComponent() : null;
      default:
        return null;
    }
  };

  const CreateField = ({ field }) =>
    field.regex || field.validateFunction ? (
      <Form.Item
        name={field.key}
        label={`${field.label}:`}
        rules={[
          { required: field.required, message: `${field.label} é obrigatório` },
          { validator: getFieldValidator(field) },
        ]}
        colon={false}
      >
        {renderField(field)}
      </Form.Item>
    ) : (
      <Form.Item
        name={field.key}
        label={`${field.label}:`}
        rules={[{ required: field.required, message: `${field.label} é obrigatório` }]}
        colon={false}
      >
        {renderField(field)}
      </Form.Item>
    );

  return (
    <div id='forms'>
      <Spin spinning={creating}>
        <Form
          form={form}
          layout='horizontal'
          className={`custom-form ${informationMenu ? 'informationMenu' : ''} 
          ${hideButtons ? 'core-format' : ''}`}
          onFinish={handleSubmit}
          labelAlign='left'
          labelCol={{ span: isTwoColumns ? 6 : 4 }}
          wrapperCol={{ span: 24 }}
          style={{ padding: modalType === 'manual' ? '0px' : '30px' }}
        >
          {isTwoColumns ? (
            <Row gutter={[0, 0]}>
              {fields.map((field, index) => (
                <Col span={12} key={index}>
                  <CreateField field={field} />
                </Col>
              ))}
            </Row>
          ) : (
            fields.map((field, index) => <CreateField field={field} key={index} />)
          )}
          {!hideButtons && (
            <div className='form-item-buttons'>
              {modalType !== 'manual' && (
                <Button onClick={handleCancel} className='cancel-button'>
                  {cancelLabel}
                </Button>
              )}
              <Button
                type='primary'
                htmlType='submit'
                className={`confirm-button ${modalType === 'manual' ? 'single-button' : ''}`}
              >
                {confirmLabel}
              </Button>
            </div>
          )}
        </Form>
      </Spin>
    </div>
  );
}
