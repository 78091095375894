import React, { useEffect, useState } from 'react';
import { Table, Spin, Dropdown, Menu, Button, Upload, message } from 'antd';
import { EllipsisOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import useFileApi from '../../../../../hooks/useFileApi';
import moment from 'moment';

import './style.css';

const AttachmentsTable = ({ interconnection }) => {
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span style={{ fontSize: '14px' }}>{record.name.split('/').at(-1)}</span>
      ),
    },
    {
      title: 'Modificado em',
      dataIndex: 'lastModified',
      key: 'lastModified',
      render: (_, record) => (
        <span style={{ fontSize: '14px' }}>
          {moment(record.lastModified).format('DD/MM/YYYY h:mm')}
        </span>
      ),
    },
    {
      key: 'action',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key='2'
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(record.name)}
                >
                  Apagar
                </Menu.Item>
              </Menu>
            }
          >
            <Button type='text' icon={<EllipsisOutlined />} />
          </Dropdown>
        </div>
      ),
    },
  ];
  const [files, setFiles] = useState([]);

  const { uploadFile, getEquipmentFiles, deleteFile, loading } = useFileApi();

  useEffect(() => {
    if (interconnection) getFiles();
  }, [interconnection]);

  function handleUpload(e) {
    if (e.file) {
      uploadFile(interconnection._id, e.file)
        .then((res) => {
          message.success('Arquivo adicionado com sucesso!');
          getFiles();
        })
        .catch((e) => {
          message.error('Ocorreu um erro ao adicionar o arquivo.');
        });
    }
  }

  function handleDelete(fileName) {
    deleteFile(fileName)
      .then((res) => {
        message.success('Arquivo removido com sucesso!');
        getFiles();
      })
      .catch((e) => {
        message.error('Ocorreu um erro ao remover o arquivo.');
      });
  }

  async function getFiles() {
    try {
      const data = await getEquipmentFiles(interconnection._id);
      if (data) {
        setFiles(data);
      }
    } catch (error) {
      message.error('Ocorreu um erro ao listar os arquivos.');
    }
  }

  return (
    <Spin spinning={loading}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          margin: '16px 0 0',
          padding: '0 2rem',
        }}
      >
        <Upload showUploadList={false} customRequest={handleUpload}>
          <Button
            type='text'
            style={{ background: 'black', color: 'white' }}
            icon={<PlusOutlined />}
          >
            Upload
          </Button>
        </Upload>
      </div>
      <Table
        rowClassName={() => 'custom-row'}
        className='custom-table'
        style={{ padding: '30px 30px 10px' }}
        dataSource={files}
        columns={columns}
        pagination={false}
      />
    </Spin>
  );
};

export default AttachmentsTable;
