import React from 'react';

import logo from '../../../../../../../images/commissioning/logo-white.png';
import './style.css';

const ReportHeader = ({ task, interconnection }) => {
  return (
    <div id='report-header'>
      <img src={logo} className='logo-overview' />
      <div className='title'>
        <h3 style={{ margin: '0' }}>Relatório de Comissionamento</h3>
      </div>
      <div className='title'>
        <h3 style={{ margin: '0' }}>
          Teste de {task?.instrument?.name} - {interconnection?.category?.name}
        </h3>
      </div>
    </div>
  );
};

export default ReportHeader;
