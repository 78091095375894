import { useTranslation } from 'react-i18next';
import CrudPage from '../../../../Template/layoutComponents/CrudPage';

import { getOrgs } from '../../../../Maintenance/helpers/apiCalls';

import { newOrganization, updateOrganization } from '../../../helpers/apiCalls';

import './style.css';
import { countries, currencies, timezones } from './options';

const Organizations = () => {
  const { t } = useTranslation();

  return (
    <div id='organizationsPage'>
      <CrudPage
        title={t('organization')}
        newText={t('new_organization')}
        detailsLink={'/settings/organizations'}
        defaultItem={{
          new: true,
          label: t('new_organization'),
          properties: [
            {
              label: t('name'),
              type: 'text',
              value: t('new_organization'),
              main: true,
              required: true,
              disabled: false,
              key: 'name',
            },
            {
              label: t('description'),
              type: 'text',
              value: '',
              main: true,
              required: true,
              disabled: false,
              key: 'description',
            },
            {
              label: t('country'),
              type: 'singleSelect',
              value: null,
              options: countries,
              main: true,
              required: true,
              disabled: false,
              key: 'country',
            },
            {
              label: t('timezone'),
              type: 'singleSelect',
              value: null,
              options: timezones,
              main: true,
              required: true,
              disabled: false,
              key: 'timezone',
            },
            {
              label: t('currency'),
              type: 'singleSelect',
              value: null,
              options: currencies,
              main: true,
              required: true,
              disabled: false,
              key: 'currency',
            },
          ],
        }}
        getAllItems={getOrgs}
        newItem={newOrganization}
        updateItem={updateOrganization}
      />
    </div>
  );
};

export default Organizations;
