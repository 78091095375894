import React, { useEffect, useState } from 'react';
import { Button, Card, Dropdown, Form } from 'antd';

import { CheckOutlined, EditOutlined, HistoryOutlined, SyncOutlined } from '@ant-design/icons';
import useCrudApi from '../../../../../../../hooks/useCrudApi';
import { useMsal } from '@azure/msal-react';

import './style.css';
import ReportHeader from '../ReportHeader';
import ReportContent from '../ReportContent';

export default function Report({
  task,
  invisible,
  onTaskUpdate,
  isLatestAttempt,
  interconnection,
}) {
  const element = 'equipment_history';
  const { updating, updateItem } = useCrudApi(element);
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [saved, setSaved] = useState(false);
  const [version, setVersion] = useState(null);
  const [latestVersion, setLatestVersion] = useState(null);
  const [editor, setEditor] = useState(null);
  const [editedTime, setEditedTime] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [editHistory, setEditHistory] = useState(null);
  const [nonEditState, setNonEditState] = useState(true);
  const [rejected, setRejected] = useState(false);
  const [approving, setApproving] = useState(false);

  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const { given_name: givenName, family_name: familyName, name } = account.idTokenClaims || {};
  const sessionVersion = 'sessionVersion';

  const mapFields = {
    'Classe de Tensão (kV)': {
      phaseIndex: 0,
      fieldIndex: 0,
    },
    'Bitola de cabos de Fase': {
      phaseIndex: 0,
      fieldIndex: 2,
    },
    'Bitola de Cabos de Neutro': {
      phaseIndex: 0,
      fieldIndex: 3,
    },
    'Condutores por Fase': {
      phaseIndex: 0,
      fieldIndex: 1,
    },
    'Bitola de Cabos de Terra': {
      phaseIndex: 0,
      fieldIndex: 4,
    },
    'Maneira de Instalar': {
      phaseIndex: 0,
      fieldIndex: 7,
    },
    Sistema: {
      phaseIndex: 0,
      fieldIndex: 5,
    },
    Isolação: {
      phaseIndex: 0,
      fieldIndex: 6,
    },
  };

  const handleEditClick = () => {
    if (editMode) {
      setEditMode(false);
    }

    if (!editMode) {
      setEditMode(true);
      setSaved(false);
      if (latestVersion) {
        setEditor(task.report.versions.editions[latestVersion - 1].editor);
        setEditedTime(transformDate(task.report.versions.editions[latestVersion - 1].timestamp));
      }
      sessionStorage.setItem(sessionVersion, String(latestVersion));
      setVersion(latestVersion);
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    setSaved(false);
    setVersion(latestVersion);
    sessionStorage.setItem(sessionVersion, String(latestVersion));
    form.resetFields();
  };

  const transformDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}/${month}/${year} - ${hours}:${minutes}`;
    }
    return null;
  };

  const handleChangeVersion = (e) => {
    const newEditor = Number(e.key);
    if (newEditor === 0) {
      setVersion(null);
      sessionStorage.removeItem(sessionVersion);
      setEditor(null);
      setEditedTime(null);
    } else {
      setVersion(newEditor);
      sessionStorage.setItem(sessionVersion, String(newEditor));
      setEditor(task?.report.versions?.editions[newEditor - 1].editor);
      setEditedTime(transformDate(task?.report.versions?.editions[newEditor - 1].timestamp));
    }
  };

  const formHistory = (editions) => {
    const history = [
      {
        key: 0,
        label: 'Original',
      },
    ];
    editions?.forEach((edition, index) => {
      history.push({
        key: Number(index) + 1,
        label: `${edition.editor} (${transformDate(edition.timestamp)})`,
      });
    });
    return history.reverse();
  };

  const handleSubmit = async () => {
    const formResult = { ...form.getFieldsValue() };

    if (task.report.versions) {
      task.report.versions.current = Number(task.report.versions.current) + 1;
      task.report.versions.editions.push({
        editor: `${givenName} ${familyName}`,
        timestamp: new Date().toISOString(),
      });
    } else {
      task.report.versions = {
        current: 1,
        editions: [
          {
            editor: `${name ?? `${givenName} ${familyName}`}`,
            timestamp: new Date().toISOString(),
          },
        ],
      };
    }

    for (const key in formResult) {
      if (formResult.hasOwnProperty(key)) {
        if (mapFields[key] && mapFields[key].fieldIndex !== undefined) {
          const oldUserGivenValue =
            task.phase[mapFields[key].phaseIndex].fields[mapFields[key].fieldIndex]
              .userGivenValue &&
            task.phase[mapFields[key].phaseIndex].fields[mapFields[key].fieldIndex]
              .userGivenValue[0];

          if (formResult[key]) {
            task.phase[mapFields[key].phaseIndex].fields[mapFields[key].fieldIndex].userGivenValue =
              [formResult[key]];
          } else if (formResult[key] === '') {
            task.phase[mapFields[key].phaseIndex].fields[mapFields[key].fieldIndex].userGivenValue =
              [null];
          } else {
            task.phase[mapFields[key].phaseIndex].fields[mapFields[key].fieldIndex].userGivenValue =
              [oldUserGivenValue];
          }

          if (
            task.phase[mapFields[key].phaseIndex].fields[mapFields[key].fieldIndex]
              .previousUserGivenValue
          ) {
            task.phase[mapFields[key].phaseIndex].fields[
              mapFields[key].fieldIndex
            ].previousUserGivenValue.push(oldUserGivenValue);
          } else {
            task.phase[mapFields[key].phaseIndex].fields[
              mapFields[key].fieldIndex
            ].previousUserGivenValue = [oldUserGivenValue];
          }
        } else {
          let field = key;
          let field_unit = null;
          if (field.includes('-unit')) {
            field_unit = field.split('-')[0];
          }

          if (field_unit) {
            const step = task.phase[3].steps.find((step) => step.title === field_unit);
            const oldStepReadingUnit = step.imageAnalysis.reading_unit;
            if (formResult[key]) {
              step.imageAnalysis.reading_unit = String(formResult[key]);
            } else if (formResult[key] === '') {
              step.imageAnalysis.reading_unit = null;
            } else {
              step.imageAnalysis.reading_unit = oldStepReadingUnit;
            }
            if (step.imageAnalysis?.previousReadingsUnits) {
              step.imageAnalysis.previousReadingsUnits.push(String(oldStepReadingUnit));
            } else {
              step.imageAnalysis.previousReadingsUnits = [String(oldStepReadingUnit)];
            }
          } else {
            const step = task.phase[3].steps.find((step) => step.title === key);
            if (step) {
              const oldStepReading = step.imageAnalysis.reading;
              if (formResult[key]) {
                step.imageAnalysis.reading = String(formResult[key]);
              } else if (formResult[key] === '') {
                step.imageAnalysis.reading = null;
              } else {
                step.imageAnalysis.reading = oldStepReading;
              }
              if (step.imageAnalysis?.previousReadings) {
                step.imageAnalysis.previousReadings.push(String(oldStepReading));
              } else {
                step.imageAnalysis.previousReadings = [String(oldStepReading)];
              }
            }
          }
        }
      }
    }

    if (task?.instrument?.name === 'Hipot') {
      for (const key of Object.keys(task?.phase[3]?.steps[0]?.imageAnalysis)) {
        if (!task?.phase[3]?.steps[0]?.previousImageAnalysis) {
          task.phase[3].steps[0].previousImageAnalysis = {};
        }

        if (task?.phase[3]?.steps[0]?.previousImageAnalysis[key]) {
          task?.phase[3]?.steps[0]?.previousImageAnalysis[key].push(
            task?.phase[3]?.steps[0]?.imageAnalysis[key],
          );
        } else {
          task.phase[3].steps[0].previousImageAnalysis[key] = [
            task.phase[3]?.steps[0].imageAnalysis[key],
          ];
        }

        const formValue = formResult[key];
        if (formValue) {
          formValue === 'true'
            ? (task.phase[3].steps[0].imageAnalysis[key] = true)
            : formValue === 'false'
              ? (task.phase[3].steps[0].imageAnalysis[key] = false)
              : (task.phase[3].steps[0].imageAnalysis[key] = formValue);
        }
      }
    }

    setLatestVersion(Number(task.report.versions.current));
    setVersion(Number(task.report.versions.current));
    sessionStorage.setItem(sessionVersion, String(task.report.versions.current));
    setEditor(task.report.versions.editions[task.report.versions.current - 1].editor);
    setEditedTime(
      transformDate(task.report.versions.editions[task.report.versions.current - 1].timestamp),
    );
    setEditHistory(formHistory(task.report.versions.editions));

    const updated_task = await updateItem(task._id, task);
    task.phase = updated_task.phase;

    onTaskUpdate(task);

    form.resetFields();
    setSaved(true);
    setEditMode(false);
  };

  useEffect(() => {
    if (task) {
      setSaved(false);
      setEditMode(false);
      setRejected(task?.report.status && task?.report.status === 'Rejected');
      setApproving(
        (task?.report.status && task?.report.status === 'Ongoing approval') ||
          task?.report.status === 'Approved',
      );

      if (task?.status !== 'Fail' && task?.status !== 'Validated') {
        setNonEditState(true);
      } else {
        setNonEditState(false);
      }

      const currentVersion = Number(task.report.versions?.current);
      if (isNaN(currentVersion)) {
        sessionStorage.removeItem(sessionVersion);
        setVersion(null);
        setLatestVersion(null);
        setEditor(null);
        setEditedTime(null);
      } else {
        sessionStorage.setItem(sessionVersion, String(currentVersion));
        setVersion(currentVersion);
        setLatestVersion(currentVersion);
        setEditor(task?.report.versions?.editions[currentVersion - 1].editor);
        setEditedTime(transformDate(task?.report.versions?.editions[currentVersion - 1].timestamp));
      }
      setUpdatedAt(transformDate(task?.report.updated_at));
      setEditHistory(formHistory(task?.report.versions?.editions));
    }
  }, [task]);

  const ReportButtons = () => {
    return (
      <div className='editor-panel'>
        <div className='editor-buttons'>
          <Button
            disabled={!isLatestAttempt || editMode || nonEditState || rejected || approving}
            onClick={handleEditClick}
          >
            <EditOutlined />
          </Button>
          {editMode && (
            <div>
              <Button
                disabled={editMode && updating}
                type={'primary'}
                onClick={handleSubmit}
                style={{ marginLeft: '1vh', marginRight: '1vh' }}
              >
                Salvar
              </Button>
              <Button disabled={editMode && updating} type='primary' danger onClick={handleCancel}>
                Cancelar
              </Button>
            </div>
          )}
          <div className='editor-buttons-state'>
            {updating && !saved && <SyncOutlined spin={true} />}
            {!editMode && saved && <p>Salvo</p>}
            {!editMode && saved && <CheckOutlined style={{ marginLeft: '5px', color: 'green' }} />}
          </div>
        </div>
        <div className='editor-history'>
          <div className='report-infos'>
            {editor && editedTime ? (
              <p>
                Editado por: {editor} ({editedTime})
              </p>
            ) : (
              <p>Primeira versão ({updatedAt})</p>
            )}
          </div>
          <Dropdown
            menu={{ items: editHistory, selectable: true, onClick: handleChangeVersion }}
            trigger={'click'}
            dropdownRender={(menuNode) => (
              <div style={{ maxHeight: '250px', overflowY: 'auto' }}>{menuNode}</div>
            )}
          >
            <Button disabled={editMode}>
              <HistoryOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    );
  };

  return (
    <Form form={form}>
      {!invisible && <ReportButtons />}
      <Card id={`${invisible ? 'invisible-' : ''}overview-content-${task._id}`}>
        <ReportHeader task={task} interconnection={interconnection} />
        <ReportContent
          task={task}
          editMode={editMode}
          version={version}
          latestVersion={latestVersion}
          interconnection={interconnection}
        />
      </Card>
    </Form>
  );
}
