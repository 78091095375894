import React, { useEffect, useState } from 'react';
import { Flex, Typography, Spin, message, Tooltip } from 'antd';
import CustomTable from '../../../../../../components/CustomTable';
import ReportButtons from '../../../../../../components/ReportButtons';
import StatusTag from '../../../../../../components/StatusTag';
import ArrowProgress from '../../../../../../components/CommissioningProgress/ArrowType';
import Overview from '../Overview';
import moment from 'moment';
import useCrudApi from '../../../../../../hooks/useCrudApi';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

import './style.css';
import DetailsButton from '../../../../../../components/DetailsButton';
import { useParams } from 'react-router-dom';

const { Title } = Typography;

const ListCommissioning = ({
  interconnection,
  work_order_token,
  title,
  compactMode,
  showStatus,
  showDetails,
  hideTimeline,
}) => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [expandedTask, setExpandedTask] = useState(null);
  const [expandedTaskEditable, setExpandedTaskEditable] = useState(true);
  const { getItems, deleteItem } = useCrudApi('equipment_history');
  const { getReports, uploadReport, uploading } = useCrudApi('reports');
  const [tasks, setTasks] = useState(null);
  const [reports, setReports] = useState(null);
  const [update, setUpdate] = useState(false);
  const [updateBlob, setUpdateBlob] = useState(false);

  const { plantId } = useParams();

  const filterWorkOrderTasks = (historyEquipments) => {
    if (!work_order_token) return historyEquipments;
    return historyEquipments.filter(
      (task) => task.commissioning_work_order_token === work_order_token,
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (update || (!tasks && !reports)) {
          setUpdate(false);
          const historyEquipments = await getItems(undefined, undefined, {
            interconnection: interconnection._id,
          });
          if (historyEquipments && historyEquipments.items.length > 0) {
            setTasks(filterWorkOrderTasks(historyEquipments.items));
          } else {
            setTasks([]);
          }
          if (plantId) {
            const updatedReports = await getReports(plantId, interconnection._id);
            setReports(updatedReports);
          }
          if (expandedTask) {
            const updatedTask = historyEquipments.items?.find(
              (task) => task._id === expandedTask._id,
            );
            const isLatestTask = historyEquipments.items?.find(
              (task) =>
                task.instrument?.name === expandedTask.instrument?.name &&
                task.report.updated_at > expandedTask.report.updated_at,
            );
            setExpandedTask(updatedTask || null);
            setExpandedTaskEditable(!isLatestTask);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [update]);

  useEffect(() => {
    if (updateBlob) {
      const fetchData = async () => {
        await uploadReport(plantId, getInterconnectionId(), selectedRows);
      };
      fetchData().then(() => {
        setUpdateBlob(false);
        setUpdate(true);
      });
    }
  }, [updateBlob, selectedRows]);

  const handleExpand = (task) => {
    setExpandedTask((prevTask) => (prevTask && prevTask._id === task._id ? null : task));
    const isLatestTask = tasks?.find(
      (prevTask) =>
        prevTask.instrument?.name === task.instrument?.name &&
        prevTask.report.updated_at > task.report.updated_at,
    );
    setExpandedTaskEditable(!isLatestTask);
  };

  const handleDeleteClick = async () => {
    if (selectedKeys.length === 0) return;
    try {
      const deletePromises = selectedKeys.map((id) => deleteItem(id));
      const success = await Promise.all(deletePromises);
      if (success) message.success('Itens excluídos com sucesso');
      setTasks((prevTasks) => prevTasks.filter((item) => !selectedKeys.includes(item._id)));
      setSelectedKeys([]);
    } catch (error) {
      message.error('Erro ao excluir os itens');
    }
  };

  const tableColumns = [
    {
      title: 'Nome da Interconexão',
      dataIndex: 'equipment.name',
      key: 'equipment.name',
      render: (text, record) => (
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '10px' }}>{record.interconnection?.name}</div>
          {selectedRows && selectedRows.find((item) => item._id === record._id) && uploading ? (
            <LoadingOutlined style={{ color: 'blue' }} />
          ) : reports && reports.find((report) => report.name.includes(record._id)) ? (
            <Tooltip
              size
              color='white'
              overlayInnerStyle={{ color: 'black' }}
              placement='right'
              title='Download disponível'
            >
              <DownloadOutlined style={{ color: 'green', marginRight: '10px' }} />
            </Tooltip>
          ) : (
            <Tooltip
              size
              color='white'
              overlayInnerStyle={{ color: 'black' }}
              placement='right'
              title='Download indisponível: requer aprovações'
            >
              <DownloadOutlined style={{ color: 'grey', marginRight: '10px' }} />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: 'Tipo de Relatório',
      dataIndex: 'instrument.name',
      key: 'instrument.name',
      render: (text, record) => record.instrument?.name,
    },
    {
      title: 'Ordem de Serviço',
      dataIndex: 'work_order_token',
      key: 'work_order_token',
      render: (text, record) => record.commissioning_work_order_token,
    },
    {
      title: 'Contratado',
      dataIndex: 'properties.destiny',
      key: 'properties.destiny',
      render: (text, record) => record.employee?.company_name,
    },
    {
      title: 'Executor',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => record.employee?.name,
    },
    {
      title: 'Data',
      dataIndex: 'details',
      key: 'details',
      render: (text, record) => moment(record?.report.updated_at).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Status',
      dataIndex: 'details',
      key: 'details',
      render: (text, record) => (
        <>
          {(record?.report.status && record?.report.status === 'Ongoing approval') ||
          record?.report.status === 'Approved' ||
          record?.report.status === 'Rejected' ? (
            <StatusTag status={record?.report.status} />
          ) : (
            <StatusTag status={record?.status} />
          )}
        </>
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'details',
      key: 'details',
      className: 'actions-column',
      render: (_, record) => (
        <ReportButtons
          task={record}
          onExpand={() => handleExpand(record)}
          expanded={expandedTask && expandedTask._id === record._id}
        />
      ),
    },
  ];

  const getInterconnectionId = () => {
    if (tasks) {
      return tasks[0].interconnection._id;
    }
  };

  const onTasksUpdate = (updatedTasks, situation) => {
    setSelectedRows([...updatedTasks]);
    if (situation === 'approve') setUpdateBlob(true);
    else setUpdate(true);
  };

  const onTaskUpdate = () => {
    setUpdate(true);
  };

  return (
    <Flex
      vertical
      style={{
        height: 'auto',
        background: '#fff',
        padding: compactMode ? '0 0 30px 30px' : '30px',
      }}
    >
      {!hideTimeline && (
        <Flex vertical style={{ background: '#fff', borderBottom: '1px solid #EFEFEF' }}>
          <Title style={{ margin: '0 0 20px' }} level={5}>
            Estágio do Comissionamento
          </Title>
          <Flex style={{ margin: '0 0 30px' }}>
            <ArrowProgress />
          </Flex>
        </Flex>
      )}
      <div style={{ display: 'flex' }}>
        <Title style={{ marginTop: '33px' }} level={5}>
          {title ?? 'Relatórios'}
        </Title>
        <Title style={{ marginTop: '30px' }} level={5}>
          {showStatus && <StatusTag status={interconnection.status} />}
        </Title>
        {showDetails && (
          <Title style={{ marginTop: '30px' }} level={5}>
            <DetailsButton
              column={interconnection}
              columnKey={interconnection._id}
              element={'interconnections'}
            />
          </Title>
        )}
      </div>
      <CustomTable
        isReport={true}
        items={tasks}
        reports={reports}
        columns={tableColumns}
        selectedRowKeys={selectedKeys}
        selectedRows={selectedRows}
        setSelectedRowKeys={setSelectedKeys}
        setSelectedRows={setSelectedRows}
        onDelete={handleDeleteClick}
        onTaskUpdate={onTaskUpdate}
        onTasksUpdate={onTasksUpdate}
        uploading={uploading}
        pagination={false}
        reviewer
        noDelete
      />
      {expandedTask && (
        <Overview
          task={expandedTask}
          isLatestAttempt={expandedTaskEditable}
          onTaskUpdate={onTaskUpdate}
          interconnection={interconnection}
        />
      )}
      <div className='overview-content-hidden'>
        {selectedRows.map(
          (task) =>
            task.status === 'Validated' &&
            (task.report.status === 'Pending Approval' ||
              task.report.status === 'Ongoing approval' ||
              task.report.status === 'Approved') && <Overview invisible task={task} />,
        )}
      </div>
    </Flex>
  );
};

export default ListCommissioning;
