import React, { useState } from 'react';
import { Modal } from 'antd';

import TokenModal from '../TokenModal';
import WorkOrderForm from './components/Form';
import PendingTasksChecklist from './components/PendingTasksChecklist';

import './style.css';
const WorkOrderModal = ({
  visible,
  handleCancel,
  handleFinish,
  preSelectedEquipments,
  initialComponent,
}) => {
  const [workOrder, setWorkOrder] = useState(null);
  const [pendingTasks, setPendingTasks] = useState(null);
  const [component, setComponent] = useState(initialComponent || 'form');

  const components = {
    form: (
      <WorkOrderForm
        handleCancel={handleCancel}
        preSelectedEquipments={preSelectedEquipments}
        setPendingTasks={setPendingTasks}
        workOrder={workOrder}
        setWorkOrder={setWorkOrder}
        setComponent={setComponent}
      />
    ),
    pendingTasks: (
      <PendingTasksChecklist
        pendingTasks={pendingTasks}
        workOrder={workOrder}
        setWorkOrder={setWorkOrder}
        setComponent={setComponent}
      />
    ),
    token: <TokenModal item={workOrder} onClose={handleFinish} />,
  };

  return (
    <Modal open={visible} onCancel={handleCancel} footer={null} width={'50vw'} centered>
      {visible && components[component]}
    </Modal>
  );
};

export default WorkOrderModal;
