import React from 'react';
import { Upload, Typography } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;
const { Text } = Typography;

const FileDragger = ({ fileList, setFileList, disabled }) => (
  <Dragger
    name={'custom-dragger'}
    multiple
    beforeUpload={() => false}
    accept='.xls,.xlsx'
    fileList={fileList}
    onChange={({ fileList }) => setFileList(fileList)}
    disabled={disabled}
  >
    <Text className='ant-upload-drag-icon'>
      <InboxOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
    </Text>
    <Text style={{ display: 'block', marginTop: '20px' }}>
      Clique ou arraste um documento para a área de upload
    </Text>
    <Text type='secondary' style={{ display: 'block' }}>
      Suporte para upload único ou em massa
    </Text>
  </Dragger>
);

export default FileDragger;
