import { atom, atomFamily, selector } from 'recoil';

export const commissioningTree = atom({
  key: 'commissioningTree',
  default: {
    site: {
      id: null,
      name: null,
    },
  },
});

export const downloading = atom({
  key: 'downloading',
  default: false,
});

export const detailsSelection = atom({
  key: 'detailsSelection',
  default: null,
});

export const navigationSelected = atom({
  key: 'navigationSelected',
  default: 'information',
});

export const navigationItems = atom({
  key: 'navigationItems',
  default: [],
});

export const breadcrumbItems = atom({
  key: 'breadcrumbItems',
  default: [],
});

export const collapsed = atom({
  key: 'collapsed',
  default: false,
});

export const openKeys = atom({
  key: 'openKeys',
  default: [],
});

export const currentElement = atom({
  key: 'currentElement',
  default: 'sites',
});

export const listItems = atomFamily({
  key: 'listItems',
  default: null,
});

export const selectedSiteIdState = atom({
  key: 'selectedSiteIdState',
  default: null,
});

export const modalTypeState = atom({
  key: 'modalTypeState',
  default: null,
});

export const uploadStatusState = atom({
  key: 'uploadStatusState',
  default: 'idle',
});

export const responseApiState = atom({
  key: 'responseApiState',
  default: null,
});

export const searchTermState = atom({
  key: 'searchTermState',
  default: '',
});

export const interconnectionViewMenuState = atom({
  key: 'interconnectionViewMenuState',
  default: 'information',
});

export const userState = atom({
  key: 'userState',
  default: null,
});

export const loadingState = atom({
  key: 'loadingState',
  default: false,
});

export const currentAssistantState = atom({
  key: 'currentAssistantState',
  default: null,
});

export const conversationHistoryState = atom({
  key: 'conversationHistoryState',
  default: null,
});

export const currentConversationIdState = atom({
  key: 'currentConversationIdState',
  default: null,
});

export const currentConversationMessagesState = atom({
  key: 'currentConversationMessagesState',
  default: [],
});

export const currentResponseState = atom({
  key: 'currentResponseState',
  default: '',
});

export const socketIdState = atom({
  key: 'socketIdState',
  default: null,
});

export const streamingState = atom({
  key: 'streamingState',
  default: false,
});
