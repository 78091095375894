import React from 'react';
import { Menu } from 'antd';
import './style.css';

const Navigation = ({ props }) => {
  return (
    <Menu
      id='navigation-menu'
      mode='horizontal'
      selectedKeys={[props.selected]}
      onSelect={({ key }) => props.onSelect(key)}
    >
      {props.content?.map((item) => (
        <Menu.Item id='item' key={item._id ? item._id : item.key}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default Navigation;
