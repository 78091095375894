import React from 'react';
import './style.css';

const steps = [
  { label: 'L1', completed: false },
  { label: 'L2', completed: true },
  { label: 'L3', completed: false },
  { label: 'L4', completed: false },
  { label: 'L5', completed: false },
  { label: 'Operating', completed: false },
];

const ArrowSteps = () => {
  return (
    <div className='steps'>
      {steps.map((step, index) => (
        <div key={index} className={`step-in-arrow ${step.completed ? 'completed' : ''}`}>
          <span>{step.label}</span>
        </div>
      ))}
    </div>
  );
};

export default ArrowSteps;
