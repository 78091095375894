import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import axios from 'axios';

import { msalInstance } from './auth/msal';
import { MsalProvider } from '@azure/msal-react';
import { RecoilRoot } from 'recoil';

import * as Sentry from '@sentry/react';

import './index.css';

Sentry.init({
  dsn: 'https://ef02d08d12571ddc31a57802fa4c20df@o4508020764966912.ingest.us.sentry.io/4508029736124416',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/.*\.opwell\.ai/
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

axios.defaults.withCredentials = true;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <RecoilRoot>
      <Routes />
    </RecoilRoot>
  </MsalProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
