import React from 'react';
import { Tag } from 'antd';

const permissionsColors = {
  admin: 'grey',
  planner: 'green',
  management: 'orange',
  quality: 'blue',
};

const PermissionTag = ({ permission }) => {
  const color = permissionsColors[permission] || 'default';
  return <Tag color={color}>{permission}</Tag>;
};

export default PermissionTag;
