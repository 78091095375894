import React, { useEffect, useState } from 'react';

import Informations from './Informations';
import ListCommissioning from './Commissioning/List';
import Files from './Files';

import './style.css';
import { useGlobalState } from '../../../../hooks/useGlobalState';
import PageHeader from '../../../../layoutComponents/PageHeader';
import PageContent from '../../../../layoutComponents/PageContent';
import useCrudApi from '../../../../hooks/useCrudApi';
import { useParams } from 'react-router-dom';

import { Spin } from 'antd';

const ViewInterconnectionDetails = () => {
  const { interconnectionId } = useParams();
  const { detailsSelection, setDetailsSelection } = useGlobalState();
  const [navigationSelected, setNavigationSelected] = useState('information');
  const getInterconnection = useCrudApi('interconnections').getItem;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!detailsSelection) {
          const interconnection = await getInterconnection(interconnectionId);
          if (interconnection) {
            setDetailsSelection(interconnection);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleNavigationSelection = (key) => {
    setNavigationSelected(key);
  };

  const navigation = {
    selected: navigationSelected,
    onSelect: handleNavigationSelection,
    content: [
      { key: 'information', name: 'Informação' },
      { key: 'commissioning', name: 'Comissionamento' },
      { key: 'file', name: 'Arquivos' },
    ],
  };

  const contentMap = {
    information: {
      component: <Informations interconnection={detailsSelection} />,
      title: 'Informação',
    },
    commissioning: {
      component: <ListCommissioning interconnection={detailsSelection} />,
      title: 'Comissionamento',
    },
    file: { component: <Files interconnection={detailsSelection} />, title: 'Arquivos' },
  };

  const Content = () => {
    return <>{contentMap[navigationSelected].component}</>;
  };

  return detailsSelection ? (
    <>
      <PageHeader navigation={navigation} />
      <PageContent Content={Content} />
    </>
  ) : (
    <Spin fullscreen spinning={true} />
  );
};

export default ViewInterconnectionDetails;
