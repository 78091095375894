const sessionKeys = {
  selectedKeys: 'sessionSelectedWorkOrderKeys',
  selectedRows: 'sessionSelectedWorkOrderRows',
};

export const saveSessionSelections = (selectedKeys, selectedRows) => {
  sessionStorage.setItem(sessionKeys.selectedKeys, JSON.stringify(selectedKeys));
  sessionStorage.setItem(sessionKeys.selectedRows, JSON.stringify(selectedRows));
};

export const restoreSessionSelections = () => {
  const sessionStoredKeys = sessionStorage.getItem(sessionKeys.selectedKeys);
  const sessionStoredRows = sessionStorage.getItem(sessionKeys.selectedRows);

  return {
    selectedKeys: sessionStoredKeys ? JSON.parse(sessionStoredKeys) : [],
    selectedRows: sessionStoredRows ? JSON.parse(sessionStoredRows) : [],
  };
};

export const clearSessionSelections = () => {
  sessionStorage.removeItem(sessionKeys.selectedKeys);
  sessionStorage.removeItem(sessionKeys.selectedRows);
};
