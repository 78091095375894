import Assets from './pages/plants/Assets';
import { MaintenanceHome } from './pages/Home/Home';
import Interconnections from './pages/plants/Interconnections';
import PlantsOutlet from './pages/plants/Outlet';
import PlantsGrid from './pages/plants/Plants';
import SystemsOutlet from './pages/plants/systems/Outlet.jsx';
import SystemsGrid from './pages/plants/systems/Systems/index.jsx';
import EditSystem from './pages/plants/systems/EditSystem'
import ViewSystem from './pages/plants/systems/ViewSystem'
import WorkOrders from './pages/plants/WorkOrders';
import Instruments from './pages/instruments/Instruments';
import InstrumentDetails from './pages/instruments/Details';
import AllAssets from './pages/AllAssets';
import Details from './pages/plants/Details';
import { Navigate } from 'react-router-dom';
import { ListExecutor } from './pages/plants/Executors';
import ViewInterconnectionDetails from './pages/plants/Interconnections/ViewDetails';
import ViewWorkOrderDetails from './pages/plants/WorkOrders/ViewDetails';
import InstrumentsOutlet from './pages/instruments/Outlet';


export default [
  {
    index: true,
    element: <Navigate to='/maintenance/plants' replace />,
  },
  // { path: '', element: <MaintenanceHome /> },
  {
    path: 'plants',
    element: <PlantsOutlet />,
    children: [
      { path: '', element: <PlantsGrid /> },
      { path: ':plantId?', element: <Assets /> },
      { path: ':plantId?/assets/:assetId?', element: <Details /> },
      {
        path: ':plantId?/interconnections',
        children: [
          {
            index: true,
            element: <Interconnections />,
          },
          {
            path: ':interconnectionId',
            element: <ViewInterconnectionDetails />,
          },
        ],
      },
      { path: ':plantId?/positions/:assetId?', element: <Details /> },
      { path: ':plantId?/interconnections', element: <Interconnections /> },
      {
        path: ':plantId?/systems', element: <SystemsOutlet />,
        children: [
          { path: '', element: <SystemsGrid /> },
          { path: ':systemId', element: <EditSystem /> },
          { path: ':systemId/view', element: <ViewSystem /> }
        ]
      },
      {
        path: ':plantId?/work-orders',
        children: [
          {
            index: true,
            element: <WorkOrders />,
          },
          {
            path: ':workOrderToken/details',
            element: <ViewWorkOrderDetails />,
          },
        ],
      },
    ],
  },
  {
    path: 'assets',
    element: <AllAssets />,
  },
  {
    path: 'instruments',
    element: <InstrumentsOutlet />,
    children: [
      { path: '', element: <Instruments /> },
      { path: ':instrumentId', element: <InstrumentDetails /> },
    ],
  },
  { path: 'executors', element: <ListExecutor /> },
];
