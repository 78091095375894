import React from 'react';
import { Row, Col } from 'antd';
import './style.css';
import Paragraph from 'antd/lib/typography/Paragraph';

const SignatureFromString = ({ name }) => {
  return <div className={'sign-font'}>{name}</div>;
};

const SignHere = ({ signerTitle, data }) => (
  <>
    <Paragraph
      style={{
        color: '#225968',
        margin: '0 0 10px',
        fontSize: '15px',
        fontWeight: 'bold',
      }}
    >
      {signerTitle}
    </Paragraph>
    {data && data.sign_name ? (
      <SignatureFromString name={data.sign_name} />
    ) : (
      <SignatureFromString name={' '} />
    )}
    <div
      style={{
        borderTop: '2px solid #225968',
        width: '80%',
        margin: '10px auto',
        paddingTop: '1rem',
        textAlign: 'center',
      }}
    >
      <Paragraph
        style={{
          color: '#225968',
          margin: '0',
          fontSize: '18px',
          fontWeight: 'bold',
        }}
      >
        {data ? `${data.reproved ? 'Reprovado por: ' : ''} ${data.name?.toUpperCase()}` : ' '}
      </Paragraph>
      <Paragraph
        style={{
          color: '#225968',
          margin: '0 0 10px',
          fontSize: '15px',
        }}
      >
        {data ? data.role?.toUpperCase() : ' '}
      </Paragraph>
      <Paragraph style={{ color: '#225968', margin: '0', fontSize: '12px' }}>
        {data ? data.email?.toUpperCase() : ' '}
      </Paragraph>
      <Paragraph style={{ color: '#225968', margin: '0', fontSize: '12px' }}>
        {data ? data.company?.toUpperCase() : ' '}
      </Paragraph>
    </div>
  </>
);

export default function Signature({ task }) {
  const formatName = (name) => {
    if (name !== null && name !== undefined) {
      if (name.length <= 14) {
        return name;
      }
      const split_name = name.split(' ');
      let abbrev_name = '';
      split_name.forEach((key, index) => {
        if (index !== 0) abbrev_name = `${abbrev_name} ${key.charAt(0)}. `;
        else abbrev_name = `${key} `;
      });
      return abbrev_name;
    }
    return null;
  };

  const mapRoles = {
    planner: {
      label: 'Aprovador',
    },
    management: {
      label: 'Instalações',
    },
    quality: {
      label: 'Qualidade',
    },
  };

  return (
    <div className={'report-table'}>
      <div className={'report-table-title'}>
        <p
          style={{
            margin: '0 0 0 10px',
            padding: '2px 0 2px 0',
            color: 'white',
            fontWeight: '500',
          }}
        >
          Assinaturas
        </p>
      </div>
      <div className={'report-table-content-sign'}>
        <Row justify='start'>
          <Col span={6} style={{ textAlign: 'center' }}>
            <SignHere
              signerTitle={'Executor'}
              data={{
                name: task?.employee?.name,
                sign_name: formatName(task?.employee?.name),
                email: task?.employee?.email,
                role: task?.employee?.role,
                company: task?.employee?.company_name,
              }}
            />
          </Col>
          {task.report.signatures?.map((signature) => (
            <Col span={6} style={{ textAlign: 'center' }}>
              {signature.role ? (
                <SignHere
                  signerTitle={mapRoles[signature.anchor].label}
                  data={{
                    name: signature?.role?.full_name,
                    sign_name: formatName(signature?.role?.full_name),
                    email: signature?.role?.email,
                    role: signature?.role?.job,
                    company: signature?.role?.company,
                    reproved: !signature?.approve,
                  }}
                />
              ) : (
                <SignHere signerTitle={mapRoles[signature.anchor].label} />
              )}
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}
