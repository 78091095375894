import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, message, Modal } from 'antd';

import CustomForm from '../../../../components/CustomForm';
import useCrudApi from '../../../../hooks/useCrudApi';
import { EditOutlined } from '@ant-design/icons';

import './style.css';

export default function EditModal({ record, handleEditFinish }) {
  const element = 'executors';
  const { updateItem, updating } = useCrudApi(element);
  const getLocations = useCrudApi('sites').getItems;
  const [listLocations, setListLocations] = useState(null);
  const [visible, setVisible] = useState(false);

  const fields = [
    {
      label: 'Nome completo',
      key: 'name',
      type: 'input',
      value: record?.name,
      disabled: true,
    },
    {
      label: 'Email',
      key: 'email',
      type: 'input',
      value: record?.email,
      disabled: true,
    },
    {
      label: 'Telefone',
      key: 'phone',
      type: 'input-number',
      value: record?.phone,
      disabled: true,
      maxLength: 11,
    },
    {
      label: 'Função/Cargo',
      key: 'role',
      type: 'input',
      value: record?.role,
      disabled: true,
    },
    {
      label: 'Compania',
      key: 'company_name',
      type: 'input',
      value: record?.company_name,
      disabled: true,
    },
    {
      label: 'Localização',
      key: 'location',
      type: 'select',
      selectItem: 'sites',
      selectKey: '_id',
      multiple: true,
      required: true,
      items: listLocations,
    },
  ];
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (visible) {
          const resultLocations = await getLocations(undefined, undefined, { parent: null }, null);
          if (resultLocations) {
            setListLocations(resultLocations.items);
          } else {
            setListLocations([]);
          }
        }
      } catch (error) {
        console.error(error);
        setListLocations([]);
      }
    };
    fetchData();
  }, [visible]);

  const handleSubmit = async (values) => {
    try {
      await updateItem(record._id, { location: values?.location });
      form.resetFields();
      setVisible(false);
      handleEditFinish();
    } catch (error) {
      message.error('Erro ao enviar o formulário');
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const handleClose = () => {
    form.resetFields();
    setVisible(false);
  };

  return (
    <>
      <EditOutlined onClick={() => setVisible(true)} />
      <Modal
        title={'Editar executor'}
        open={visible}
        footer={null}
        width={'68vw'}
        onClose={() => handleClose()}
        onCancel={() => handleCancel()}
        loading={updating}
      >
        <CustomForm
          form={form}
          fields={fields}
          creating={updating}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      </Modal>
    </>
  );
}
