export const countries = [
    { id: "Afghanistan", label: "Afghanistan" },
    { id: "Albania", label: "Albania" },
    { id: "Algeria", label: "Algeria" },
    { id: "Andorra", label: "Andorra" },
    { id: "Angola", label: "Angola" },
    { id: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { id: "Argentina", label: "Argentina" },
    { id: "Armenia", label: "Armenia" },
    { id: "Australia", label: "Australia" },
    { id: "Austria", label: "Austria" },
    { id: "Azerbaijan", label: "Azerbaijan" },
    { id: "Bahamas", label: "Bahamas" },
    { id: "Bahrain", label: "Bahrain" },
    { id: "Bangladesh", label: "Bangladesh" },
    { id: "Barbados", label: "Barbados" },
    { id: "Belarus", label: "Belarus" },
    { id: "Belgium", label: "Belgium" },
    { id: "Belize", label: "Belize" },
    { id: "Benin", label: "Benin" },
    { id: "Bhutan", label: "Bhutan" },
    { id: "Bolivia", label: "Bolivia" },
    { id: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { id: "Botswana", label: "Botswana" },
    { id: "Brazil", label: "Brazil" },
    { id: "Brunei", label: "Brunei" },
    { id: "Bulgaria", label: "Bulgaria" },
    { id: "Burkina Faso", label: "Burkina Faso" },
    { id: "Burundi", label: "Burundi" },
    { id: "Cabo Verde", label: "Cabo Verde" },
    { id: "Cambodia", label: "Cambodia" },
    { id: "Cameroon", label: "Cameroon" },
    { id: "Canada", label: "Canada" },
    { id: "Central African Republic", label: "Central African Republic" },
    { id: "Chad", label: "Chad" },
    { id: "Chile", label: "Chile" },
    { id: "China", label: "China" },
    { id: "Colombia", label: "Colombia" },
    { id: "Comoros", label: "Comoros" },
    { id: "Congo", label: "Congo" },
    { id: "Costa Rica", label: "Costa Rica" },
    { id: "Croatia", label: "Croatia" },
    { id: "Cuba", label: "Cuba" },
    { id: "Cyprus", label: "Cyprus" },
    { id: "Czech Republic", label: "Czech Republic" },
    { id: "Côte d'Ivoire", label: "Côte d'Ivoire" },
    { id: "Denmark", label: "Denmark" },
    { id: "Djibouti", label: "Djibouti" },
    { id: "Dominica", label: "Dominica" },
    { id: "Dominican Republic", label: "Dominican Republic" },
    { id: "DR Congo", label: "DR Congo" },
    { id: "Ecuador", label: "Ecuador" },
    { id: "Egypt", label: "Egypt" },
    { id: "El Salvador", label: "El Salvador" },
    { id: "Equatorial Guinea", label: "Equatorial Guinea" },
    { id: "Eritrea", label: "Eritrea" },
    { id: "Estonia", label: "Estonia" },
    { id: "Eswatini", label: "Eswatini" },
    { id: "Ethiopia", label: "Ethiopia" },
    { id: "Fiji", label: "Fiji" },
    { id: "Finland", label: "Finland" },
    { id: "France", label: "France" },
    { id: "Gabon", label: "Gabon" },
    { id: "Gambia", label: "Gambia" },
    { id: "Georgia", label: "Georgia" },
    { id: "Germany", label: "Germany" },
    { id: "Ghana", label: "Ghana" },
    { id: "Greece", label: "Greece" },
    { id: "Grenada", label: "Grenada" },
    { id: "Guatemala", label: "Guatemala" },
    { id: "Guinea", label: "Guinea" },
    { id: "Guinea-Bissau", label: "Guinea-Bissau" },
    { id: "Guyana", label: "Guyana" },
    { id: "Haiti", label: "Haiti" },
    { id: "Holy See", label: "Holy See" },
    { id: "Honduras", label: "Honduras" },
    { id: "Hungary", label: "Hungary" },
    { id: "Iceland", label: "Iceland" },
    { id: "India", label: "India" },
    { id: "Indonesia", label: "Indonesia" },
    { id: "Iran", label: "Iran" },
    { id: "Iraq", label: "Iraq" },
    { id: "Ireland", label: "Ireland" },
    { id: "Israel", label: "Israel" },
    { id: "Italy", label: "Italy" },
    { id: "Jamaica", label: "Jamaica" },
    { id: "Japan", label: "Japan" },
    { id: "Jordan", label: "Jordan" },
    { id: "Kazakhstan", label: "Kazakhstan" },
    { id: "Kenya", label: "Kenya" },
    { id: "Kiribati", label: "Kiribati" },
    { id: "Kuwait", label: "Kuwait" },
    { id: "Kyrgyzstan", label: "Kyrgyzstan" },
    { id: "Laos", label: "Laos" },
    { id: "Latvia", label: "Latvia" },
    { id: "Lebanon", label: "Lebanon" },
    { id: "Lesotho", label: "Lesotho" },
    { id: "Liberia", label: "Liberia" },
    { id: "Libya", label: "Libya" },
    { id: "Liechtenstein", label: "Liechtenstein" },
    { id: "Lithuania", label: "Lithuania" },
    { id: "Luxembourg", label: "Luxembourg" },
    { id: "Madagascar", label: "Madagascar" },
    { id: "Malawi", label: "Malawi" },
    { id: "Malaysia", label: "Malaysia" },
    { id: "Maldives", label: "Maldives" },
    { id: "Mali", label: "Mali" },
    { id: "Malta", label: "Malta" },
    { id: "Marshall Islands", label: "Marshall Islands" },
    { id: "Mauritania", label: "Mauritania" },
    { id: "Mauritius", label: "Mauritius" },
    { id: "Mexico", label: "Mexico" },
    { id: "Micronesia", label: "Micronesia" },
    { id: "Moldova", label: "Moldova" },
    { id: "Monaco", label: "Monaco" },
    { id: "Mongolia", label: "Mongolia" },
    { id: "Montenegro", label: "Montenegro" },
    { id: "Morocco", label: "Morocco" },
    { id: "Mozambique", label: "Mozambique" },
    { id: "Myanmar", label: "Myanmar" },
    { id: "Namibia", label: "Namibia" },
    { id: "Nauru", label: "Nauru" },
    { id: "Nepal", label: "Nepal" },
    { id: "Netherlands", label: "Netherlands" },
    { id: "New Zealand", label: "New Zealand" },
    { id: "Nicaragua", label: "Nicaragua" },
    { id: "Niger", label: "Niger" },
    { id: "Nigeria", label: "Nigeria" },
    { id: "North Korea", label: "North Korea" },
    { id: "North Macedonia", label: "North Macedonia" },
    { id: "Norway", label: "Norway" },
    { id: "Oman", label: "Oman" },
    { id: "Pakistan", label: "Pakistan" },
    { id: "Palau", label: "Palau" },
    { id: "Panama", label: "Panama" },
    { id: "Papua New Guinea", label: "Papua New Guinea" },
    { id: "Paraguay", label: "Paraguay" },
    { id: "Peru", label: "Peru" },
    { id: "Philippines", label: "Philippines" },
    { id: "Poland", label: "Poland" },
    { id: "Portugal", label: "Portugal" },
    { id: "Qatar", label: "Qatar" },
    { id: "Romania", label: "Romania" },
    { id: "Russia", label: "Russia" },
    { id: "Rwanda", label: "Rwanda" },
    { id: "Saint Kitts & Nevis", label: "Saint Kitts & Nevis" },
    { id: "Saint Lucia", label: "Saint Lucia" },
    { id: "Samoa", label: "Samoa" },
    { id: "San Marino", label: "San Marino" },
    { id: "Sao Tome & Principe", label: "Sao Tome & Principe" },
    { id: "Saudi Arabia", label: "Saudi Arabia" },
    { id: "Senegal", label: "Senegal" },
    { id: "Serbia", label: "Serbia" },
    { id: "Seychelles", label: "Seychelles" },
    { id: "Sierra Leone", label: "Sierra Leone" },
    { id: "Singapore", label: "Singapore" },
    { id: "Slovakia", label: "Slovakia" },
    { id: "Slovenia", label: "Slovenia" },
    { id: "Solomon Islands", label: "Solomon Islands" },
    { id: "Somalia", label: "Somalia" },
    { id: "South Africa", label: "South Africa" },
    { id: "South Korea", label: "South Korea" },
    { id: "South Sudan", label: "South Sudan" },
    { id: "Spain", label: "Spain" },
    { id: "Sri Lanka", label: "Sri Lanka" },
    { id: "St. Vincent & Grenadines", label: "St. Vincent & Grenadines" },
    { id: "State of Palestine", label: "State of Palestine" },
    { id: "Sudan", label: "Sudan" },
    { id: "Suriname", label: "Suriname" },
    { id: "Sweden", label: "Sweden" },
    { id: "Switzerland", label: "Switzerland" },
    { id: "Syria", label: "Syria" },
    { id: "Tajikistan", label: "Tajikistan" },
    { id: "Tanzania", label: "Tanzania" },
    { id: "Thailand", label: "Thailand" },
    { id: "Timor-Leste", label: "Timor-Leste" },
    { id: "Togo", label: "Togo" },
    { id: "Tonga", label: "Tonga" },
    { id: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { id: "Tunisia", label: "Tunisia" },
    { id: "Turkey", label: "Turkey" },
    { id: "Turkmenistan", label: "Turkmenistan" },
    { id: "Tuvalu", label: "Tuvalu" },
    { id: "Uganda", label: "Uganda" },
    { id: "Ukraine", label: "Ukraine" },
    { id: "United Arab Emirates", label: "United Arab Emirates" },
    { id: "United Kingdom", label: "United Kingdom" },
    { id: "United States", label: "United States" },
    { id: "Uruguay", label: "Uruguay" },
    { id: "Uzbekistan", label: "Uzbekistan" },
    { id: "Vanuatu", label: "Vanuatu" },
    { id: "Venezuela", label: "Venezuela" },
    { id: "Vietnam", label: "Vietnam" },
    { id: "Yemen", label: "Yemen" },
    { id: "Zambia", label: "Zambia" },
    { id: "Zimbabwe", label: "Zimbabwe" },
];

export const timezones = [
    { id: "UTC -12", label: "UTC -12" },
    { id: "UTC -11", label: "UTC -11" },
    { id: "UTC -10", label: "UTC -10" },
    { id: "UTC -9", label: "UTC -9" },
    { id: "UTC -8", label: "UTC -8" },
    { id: "UTC -7", label: "UTC -7" },
    { id: "UTC -6", label: "UT6-6" },
    { id: "UTC -5", label: "U5 -5" },
    { id: "UTC -4", label: "UTC -4" },
    { id: "UTC -3", label: "UTC -3" },
    { id: "UTC -2", label: "UTC -2" },
    { id: "UTC -1", label: "UTC -1" },
    { id: "UTC", label: "UTC" },
    { id: "UTC +1", label: "UTC +1" },
    { id: "UTC +2", label: "UTC +2" },
    { id: "UTC +3", label: "UTC +3" },
    { id: "UTC +4", label: "UTC +4" },
    { id: "UTC +5", label: "UTC +5" },
    { id: "UTC +6", label: "UTC +6" },
    { id: "UTC +7", label: "UTC +7" },
    { id: "UTC +8", label: "UTC +8" },
    { id: "UTC +9", label: "UTC +9" },
    { id: "UTC +10", label: "UTC +10" },
    { id: "UTC +11", label: "UTC +11" },
    { id: "UTC +12", label: "UTC +12" },
];

export const currencies = [
    { id: "USD", label: "US$" },
    { id: "EUR", label: "€" },
    { id: "BRL", label: "R$" },
    { id: "JPY", label: "¥" },
    { id: "GBP", label: "£" },
];

