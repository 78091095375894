const unflattenObjects = (flatObject) => {
  const nestedObject = {};

  for (const flatKey in flatObject) {
    if (flatObject.hasOwnProperty(flatKey)) {
      const value = flatObject[flatKey];
      const keys = flatKey.split('.');

      keys.reduce((currentLevel, keyPart, index) => {
        if (index === keys.length - 1) {
          currentLevel[keyPart] = value;
        } else {
          if (!currentLevel[keyPart]) {
            currentLevel[keyPart] = {};
          }

          return currentLevel[keyPart];
        }
      }, nestedObject);
    }
  }

  return nestedObject;
};

export default unflattenObjects;
