import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import arrowDown from '../../images/arrowDown.svg';

import './style.css';
import { isDivOutOfViewport } from '../../../helpers/misc';

const SingleSelect = ({ shouldOpenUp, options, value, style, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(value?.id);

  const [openAbove, setOpenAbove] = useState(false);

  const { t } = useTranslation();
  const handleSingleSelectBehaviour = (value) => {
    setSelected(value);
    onChange({ target: { value: value } });
    setIsOpen(false);
    setOpenAbove(false);
    document.activeElement.blur();
  };

  const dropdownRef = useRef(null);
  const handleBlur = (e) => {
    // Verifica se o foco está fora do dropdown e do input
    if (dropdownRef.current && !dropdownRef.current.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  };

  return (
    <div className='inputContainer' style={style}>
      <div
        className='select-container'
        tabIndex={0}
        onBlur={handleBlur}
        ref={dropdownRef}
        onFocus={() => {
          if (shouldOpenUp) {
            const elements = document.getElementsByClassName('pageSizeSelector');
            setOpenAbove(isDivOutOfViewport(elements[0]));
          }
          if (!disabled) setIsOpen(true);
        }}
      >
        <div className='inputIconContainer' style={{ right: 0 }}>
          {<img src={arrowDown} />}
        </div>
        <div className={`customInput ${disabled ? 'disabled' : ''}`}>
          <div style={{ height: '100%', width: '90%', overflow: 'hidden' }}>
            {options.find((op) => op.id === selected)?.label ?? t('choose')}
          </div>
        </div>
        {isOpen && (
          <ul className={`dropdown-list ${openAbove ? 'openAbove' : ''}`}>
            {options.map((option, index) => (
              <li
                key={index}
                className={selected === option.id ? 'selected' : ''}
                onClick={() => handleSingleSelectBehaviour(option.id)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SingleSelect;
