import React from 'react';
import { Button, Layout } from 'antd';
import './style.css';
import Navigation from '../../components/Navigation';
import { PlusOutlined } from '@ant-design/icons';

export default function PageHeader({ buttons, navigation }) {
  return (
    <Layout>
      {buttons && (
        <Layout.Header id='header-feature'>
          <div className='input-container'>
            {buttons &&
              buttons.map(
                (button, index) =>
                  button.visible && (
                    <Button
                      key={index}
                      style={{
                        marginRight: '10px',
                        backgroundColor: button.color ? button.color : '',
                      }}
                      type={button.design ? button.design : 'primary'}
                      icon={button.icon ? button.icon : <PlusOutlined />}
                      onClick={() => button.function(true)}
                      disabled={button?.disabled}
                    >
                      {button.name}
                    </Button>
                  ),
              )}
          </div>
        </Layout.Header>
      )}
      {navigation && <Navigation props={navigation} />}
    </Layout>
  );
}
