export default function apiErrorResponseValidation(error, fallbackMessage) {
  let errorData = error?.response?.data;

  if (error.code === 'ERR_NETWORK') {
    return 'Parece que você está sem Internet. Verifique sua conexão e tente novamente.';
  }

  if (errorData) {
    if (errorData?.message) {
      if (errorData.message.includes('E11000 duplicate key error')) {
        // Mensagem específica para erro de chave duplicada
        return 'Equipamentos já cadastrados';
      }
      // Retorna a mensagem de erro genérica se não for o caso de chave duplicada
      return errorData.message;
    } else if (
      errorData?.message === 'Method argument not valid' &&
      errorData?.parameters?.fieldErrors?.length > 0
    ) {
      let errorStrArr = [];
      errorData?.parameters?.fieldErrors?.forEach((field) =>
        errorStrArr.push(`${field?.field}: ${field?.message}.`),
      );
      return errorStrArr?.join('\n');
    } else if (error?.response?.data?.localizedMessage?.includes('Internal Server Error')) {
      return fallbackMessage;
    } else if (error?.response?.data?.localizedMessage) {
      return error.response.data.localizedMessage;
    }
  }

  return fallbackMessage;
}
