import { useTranslation } from 'react-i18next';
import Input from '../../../Template/layoutComponents/Input';

import Select from '../../../Template/layoutComponents/SingleSelect';

import deleteIcon from '../../images/deleteIcon.svg';
import addIcon from '../../images/addIcon.svg';
import Checkbox from '../../../Template/layoutComponents/Checkmark';

const FormsBody = ({ setEditingCategory, setChosenCategory, index, prop }) => {
  const { t } = useTranslation();
  const handlePropertyNameInput = (e) => {
    setEditingCategory(true);
    setChosenCategory((current) => {
      return {
        ...current,
        properties: [
          ...current.properties.filter((cat) => cat.main),
          ...current.properties
            .filter((cat) => !cat.main)
            .map((item, ind) => {
              if (ind === index) {
                return { ...item, label: e.target.value };
              } else return item;
            }),
        ],
      };
    });
  };
  const handlePropertyTypeInput = (e) => {
    setEditingCategory(true);
    setChosenCategory((current) => {
      return {
        ...current,
        properties: [
          ...current.properties.filter((cat) => cat.main),
          ...current.properties
            .filter((cat) => !cat.main)
            .map((item, ind) => {
              if (ind === index) {
                return {
                  ...item,
                  type: e.target.value,
                  options: e.target.value.includes('Select')
                    ? item?.options?.length > 0
                      ? item?.options
                      : [{ id: '', label: '' }]
                    : null,
                };
              } else return item;
            }),
        ],
      };
    });
  };

  return (
    <div className='formsBody'>
      <div className='formsInputContainer'>
        <div className='formsInputLabel'>{t('property_name')}:</div>
        <div className='formsInput'>
          <Input
            value={prop.label}
            placeholder={prop.label}
            onChange={(e) => {
              handlePropertyNameInput(e);
            }}
          />
        </div>
      </div>
      <div className='formsInputContainer'>
        <div className='formsInputLabel'>{t('property_type')}:</div>
        <div className='formsInput'>
          <Select
            value={{ id: prop.type }}
            options={[
              { id: 'bool', label: 'Checkbox' },
              { id: 'text', label: t('text') },
              { id: 'number', label: t('number') },
              { id: 'singleSelect', label: t('single_choice') },
              { id: 'multiSelect', label: t('multiple_choice') },
            ]}
            onChange={(e) => {
              handlePropertyTypeInput(e);
            }}
          />
        </div>
      </div>
      {prop.type?.includes('Select') &&
        prop.options?.map((option, optionIndex) => {
          return (
            <div className='formsInputContainer'>
              <div
                className='formsInputLabel'
                style={{ fontWeight: 600, textAlign: 'left' }}
              >{`${t('option')} ${optionIndex + 1}:`}</div>
              <div className='formsInput'>
                <Input
                  onChange={(e) => {
                    setEditingCategory(true);
                    setChosenCategory((current) => {
                      return {
                        ...current,
                        properties: [
                          ...current.properties.filter((cat) => cat.main),
                          ...current.properties
                            .filter((cat) => !cat.main)
                            .map((item, ind) => {
                              if (ind === index) {
                                return {
                                  ...item,
                                  options: item.options.map((optionItem, ind) => {
                                    if (ind === optionIndex) {
                                      return {
                                        id: e.target.value?.replace(/ /g, '')?.toLowerCase(),
                                        label: e.target.value,
                                      };
                                    } else return optionItem;
                                  }),
                                };
                              } else return item;
                            }),
                        ],
                      };
                    });
                  }}
                  value={option.label}
                  placeholder={t('option_name')}
                />
                <div
                  className='deletePropertyIcon'
                  onClick={() => {
                    setEditingCategory(true);
                    setChosenCategory((current) => {
                      return {
                        ...current,
                        properties: [
                          ...current.properties.filter((cat) => cat.main),
                          ...current.properties
                            .filter((cat) => !cat.main)
                            .map((item, ind) => {
                              if (ind === index) {
                                return {
                                  ...item,
                                  options: item.options.filter((op, ind) => ind != optionIndex),
                                };
                              } else return item;
                            }),
                        ],
                      };
                    });
                  }}
                >
                  <img src={deleteIcon} />
                </div>
              </div>
            </div>
          );
        })}

      {prop.type?.includes('Select') && (
        <div className='addPropertyOptionContainer'>
          <div
            className='addPropertyOption'
            onClick={() => {
              setEditingCategory(true);
              setChosenCategory((current) => {
                return {
                  ...current,
                  properties: [
                    ...current.properties.filter((cat) => cat.main),
                    ...current.properties
                      .filter((cat) => !cat.main)
                      .map((item, ind) => {
                        if (ind === index) {
                          return {
                            ...item,
                            options: [...item.options, { id: '', label: '' }],
                          };
                        } else return item;
                      }),
                  ],
                };
              });
            }}
          >
            <div className='addPropertyIcon'>
              <img src={addIcon} />
            </div>
            <div className='addPropertyText'>{t('new_option')}</div>
          </div>
        </div>
      )}
      <div className='formsInputContainer'>
        <div className='formsInput'>
          <Checkbox
            label={t('mandatory_field')}
            checked={prop.required}
            onChange={(e) => {
              setEditingCategory(true);
              setChosenCategory((current) => {
                return {
                  ...current,
                  properties: [
                    ...current.properties.filter((cat) => cat.main),
                    ...current.properties
                      .filter((cat) => !cat.main)
                      .map((item, ind) => {
                        if (ind === index) {
                          return {
                            ...item,
                            required: e.target.value,
                          };
                        } else return item;
                      }),
                  ],
                };
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FormsBody;
