import React from 'react';
import { Flex } from 'antd';
import WorkOrderTitles from '../WorkOrderTitles';
import './style.css';
import CustomForm from '../../../../../components/CustomForm';
import WorkOrderButtons from '../WorkOrderButtons';

export default function HeaderAndFormSection({
  editing,
  workOrder,
  loading,
  form,
  fields,
  items,
  handleCancel,
  handleEditClick,
  handleDeleteClick,
  handleSubmit,
}) {
  return (
    <Flex vertical>
      <Flex style={{ justifyContent: 'space-between' }}>
        <WorkOrderTitles workOrder={workOrder} titleForms={'Ordem de Serviço'} />
        <WorkOrderButtons
          workOrder={workOrder}
          handleCancel={handleCancel}
          handleDeleteClick={handleDeleteClick}
          handleEditClick={handleEditClick}
          handleSubmit={handleSubmit}
          editing={editing}
          loading={loading}
        />
      </Flex>
      <CustomForm
        form={form}
        fields={fields}
        creating={loading}
        editing={editing}
        readOnly={!editing}
        hideButtons
        isTwoColumns
      />
    </Flex>
  );
}
