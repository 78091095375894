export const phoneNumberValidator = (_, value) => {
  if (!value || String(value).length === 11) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Número de telefone precisa ter 11 dígitos!'));
};

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const formatEmail = (_, value) => {
  if (value === '' || emailPattern.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject('Email precisa estar no formato exemplo@domain.com!');
};

export const formatEmailArray = (_, value) => {
  if (!value) {
    return Promise.resolve();
  }

  const emails = value.split(',').map((email) => email.trim());
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  for (let email of emails) {
    if (!emailPattern.test(email)) {
      return Promise.reject(
        new Error('Todos os emails precisam estar no formato exemplo@domain.com!'),
      );
    }
  }

  return Promise.resolve();
};

export const cepValidator = (_, value) => {
  if (!value || String(value).length === 8) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('CEP precisa ter 8 dígitos!'));
};
