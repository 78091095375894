import { acquireToken } from '../../auth/msal.js';
import { protectedResourcesScopes } from '../../auth/config.js';
import axios from 'axios';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export const getFiles = async (instance, elementType, elementId, isCommissioning = false) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const queryParams = `?isCommissioning=${isCommissioning}`;
    const { data } = await axios.get(
      `${apiUrl}/file/${elementType}/${elementId}${queryParams}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    return data;
  } catch (e) {
    throw e;
  }
};



export const saveFile = async (
  instance,
  elementType,
  elementId,
  file,
  onUploadProgress,
  isCommissioning = false,
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("isCommissioning", isCommissioning);

    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(
      `${apiUrl}/file/${elementType}/${elementId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      }
    );
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};


export const deleteFile = async (instance, elementType, elementId, fileName) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.delete(`${apiUrl}/file/${elementType}/${elementId}`, {
      params: {
        fileName,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (err) {
    throw err;
  }
};
