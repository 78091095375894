import React from 'react';

import './style.css';
import { Col, Image, Row, Typography } from 'antd';

export default function ListEvidences({ task }) {
  const cameraPhase = task?.phase?.find((ph) => ph.type === 'camera');
  const evidencyPhase = task?.phase?.find((ph) => ph.type === 'evidency');

  return (
    <div className={'report-table'}>
      <div className={'report-table-title'}>
        <p
          style={{
            margin: '0 0 0 10px',
            padding: '2px 0 2px 0',
            color: 'white',
            fontWeight: '500',
          }}
        >
          Evidências
        </p>
      </div>
      <div className={'report-table-content-evidence'}>
        <Row>
          {cameraPhase?.steps?.map((step) =>
            step.images?.map((image) => (
              <Col span={6}>
                <div className={'report-table-evidence'}>
                  <Image
                    width={'95%'}
                    height={300}
                    src={image?.url}
                    alt={`Evidência ${step.title}`}
                    style={{ borderRadius: '5px' }}
                    className='evidence'
                  />
                  <p
                    style={{
                      margin: '1vh',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: '#225968',
                    }}
                  >
                    {step?.title?.toUpperCase()}
                  </p>
                </div>
              </Col>
            )),
          )}
          {evidencyPhase?.steps?.map((step) =>
            step.images?.map((image) => (
              <Col span={6}>
                <div className={'report-table-evidence'}>
                  <Image
                    width={'95%'}
                    height={300}
                    src={image?.url}
                    alt={`Evidência ${step.title}`}
                    style={{ borderRadius: '5px' }}
                    className='evidence'
                  />
                  <p
                    style={{
                      margin: '1vh',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: '#225968',
                    }}
                  >
                    {step?.title?.toUpperCase()}
                  </p>
                </div>
              </Col>
            )),
          )}
        </Row>
      </div>
    </div>
  );
}
