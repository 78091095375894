import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Checkbox, notification, message } from 'antd';
import SearchBar from '../SearchInput';
import SuccessModal from '../SuccessModal';
import useVirtualEngineerState from '../../../../../atoms/variables';
import { mapEquipmentData } from '../../helpers/helpers';
import { getEquipments } from '../../../../../helpers/equipment';
import createColumns from './helpers/columnsHelpers';
import { useMsal } from '@azure/msal-react';
import { confirmKnowledgeAddition } from '../../../../../helpers/conversation';
import { useTranslation, Trans } from 'react-i18next';

const AssetSelectionModal = ({
  closeEditModalVisible,
  visible,
  onClose,
  editableContent,
  generatorId,
}) => {
  const { locations, equipmentCategories, currentEquipment } = useVirtualEngineerState();
  const [, setEquipments] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState(['firstRender']);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [hasInitialSelectionBeenSet, setHasInitialSelectionBeenSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [order, setOrder] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchIsTrue, setSearchIsTrue] = useState(false);
  const [searchReturn, setSearchReturn] = useState([]);
  const { instance } = useMsal();
  const { t } = useTranslation();
  const fullData = true;

  const triggerApiCall = async (
    pageNumber = page,
    limit = pageSize,
    sortField = 'name',
    sortOrder = 'ascend',
  ) => {
    try {
      setLoading(true);
      const response = await getEquipments(
        instance,
        null,
        null,
        pageNumber,
        limit,
        sortField,
        sortOrder,
        searchIsTrue ? null : searchValue,
        fullData,
      );
      const mappedData = mapEquipmentData(response.items, locations, equipmentCategories);
      const mappedDataWithKeys = mappedData.map((item) => ({
        ...item,
        key: item._id,
      }));

      setEquipments(mappedDataWithKeys);
      setFilteredData(mappedDataWithKeys);
      setTotalItems(response.totalItems);

      return {
        equipments: mappedDataWithKeys,
        totalPages: response.totalPages,
      };
    } catch (err) {
      notification.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const selectCurrentEquipment = (dataArray) => {
    if (currentEquipment && !hasInitialSelectionBeenSet) {
      const selectedKey = dataArray.find((item) => item._id === currentEquipment._id)?.key;
      setSelectedRowKeys((prevSelectedKeys) => {
        if (selectedKey) {
          const updatedKeys = prevSelectedKeys.filter((key) => key !== 'firstRender');
          return [selectedKey, ...updatedKeys];
        }
        return prevSelectedKeys.includes('firstRender')
          ? prevSelectedKeys
          : ['firstRender', ...prevSelectedKeys];
      });
      if (selectedKey) {
        setHasInitialSelectionBeenSet(true);
      }
    }
  };

  const handleSearch = (filteredArray) => {
    setSearchReturn(filteredArray);
    setPage(1);
    selectCurrentEquipment(filteredArray);
  };

  useEffect(() => {
    if (visible) {
      const fetchData = async () => {
        const response = await triggerApiCall(
          page,
          pageSize,
          order.field ? order.field : 'name',
          order.order ? order.order : 'ascend',
        );
        if (response?.equipments) selectCurrentEquipment(response.equipments);
      };
      fetchData();
    }
  }, [visible, currentEquipment]);

  useEffect(() => {
    sessionStorage.setItem('selectedRowKeys', JSON.stringify(selectedRowKeys));
    selectCurrentEquipment(filteredData);
  }, [selectedRowKeys]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
    setOrder(sorter);
    const response = triggerApiCall(
      pagination.current,
      pagination.pageSize,
      sorter.field,
      sorter.order,
    );
    response.then((res) => {
      if (res?.equipments) selectCurrentEquipment(res.equipments);
    });
  };

  const resetModalState = () => {
    sessionStorage.removeItem('selectedRowKeys');
    setEquipments([]);
    setFilteredData([]);
    setSelectedRowKeys(['firstRender']);
    setIsSuccessModalVisible(false);
    setHasInitialSelectionBeenSet(false);
    setLoading(false);
    setPage(1);
    setPageSize(10);
    setTotalItems(0);
    setSearchReturn([]);
    setSearchValue('');
  };

  const handleConfirm = async () => {
    setLoading(true);
    updateSelectedRowKeys(selectedRowKeys, currentEquipment, setSelectedRowKeys);
    try {
      await confirmKnowledgeAddition(instance, generatorId, editableContent, selectedRowKeys);
      setIsSuccessModalVisible(true);
      setTimeout(() => {
        onClose();
        closeEditModalVisible();
        resetModalState();
      }, 3000);
    } catch (error) {
      console.error('Error confirming knowledge addition:', error);
      message.error(
        `${t('notification_error_generate')}: ${error.response.data.message ?? error.message}`,
        5,
      );
    } finally {
      setLoading(false);
    }
  };

  const updateSelectedRowKeys = (selectedRowKeys, currentEquipment, setSelectedRowKeys) => {
    if (selectedRowKeys.includes('firstValue')) {
      const updatedRowKeys = selectedRowKeys
        .filter((key) => key !== 'firstValue')
        .concat(currentEquipment._id);
      setSelectedRowKeys(updatedRowKeys);
    }
  };

  const handleModalClose = () => {
    onClose();
    resetModalState();
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalVisible(false);
  };

  const { columns } = createColumns(selectedRowKeys);

  return (
    <>
      <Modal
        visible={visible}
        title={t('asset_selection_title')}
        centered
        onCancel={handleModalClose}
        maskClosable={false}
        footer={[
          <Button
            key='done'
            type='primary'
            onClick={handleConfirm}
            disabled={loading || selectedRowKeys.length === 0}
          >
            {t('confirm-btn')}
          </Button>,
        ]}
        width='60%'
        bodyStyle={{
          minHeight: '65vh',
        }}
      >
        <p style={{ fontSize: '1rem' }}>{t('asset_selection_description')}</p>

        {/* Barra de pesquisa */}
        <SearchBar
          onSearch={handleSearch}
          beforeSearch={triggerApiCall}
          setSearchValue={setSearchValue}
          setSearchIsTrue={setSearchIsTrue}
          placeholder={t('search_bar_placeholder')}
        />

        {/* Tabela de ativos */}
        <p>
          <Trans
            i18nKey='asset_preselected'
            values={{ assetName: currentEquipment?.name }}
            components={{ strong: <strong /> }}
          />
        </p>
        <Table
          dataSource={searchReturn?.length > 0 ? searchReturn : filteredData}
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys) => {
              setSelectedRowKeys(newSelectedRowKeys);
            },
          }}
          columns={columns}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: searchReturn?.length > 0 ? searchReturn.length : totalItems,
            showSizeChanger: true,
          }}
          scroll={{ y: 300 }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Modal>

      {/* Modal de Sucesso */}
      <SuccessModal
        visible={isSuccessModalVisible}
        onClose={handleCloseSuccessModal}
        title={t('success_modal_title')}
      />
    </>
  );
};

export default AssetSelectionModal;
