import { Modal, Button, Input, Spin } from 'antd';
import { EditOutlined, SyncOutlined, LoadingOutlined } from '@ant-design/icons';
import AssetSelectionModal from '../AssetModal';
import Markdown from 'react-markdown';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';

const KnowledgeResultModal = ({
  visible,
  onClose,
  onRegenerate,
  result,
  setResult,
  initialInput,
  pageIndex,
  onPageChange,
  loadingGenerate,
  generatorId,
  totalPages,
  inputsUsed,
}) => {
  const [inputValue, setInputValue] = useState(initialInput || '');
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editableContent, setEditableContent] = useState(result?.content);
  const [isAssetModalVisible, setIsAssetModalVisible] = useState(false);
  const [selectedPromptIndex, setSelectedPromptIndex] = useState(null);
  const [isInputEditable, setIsInputEditable] = useState(pageIndex - 1 === inputsUsed.length - 1);
  const { t } = useTranslation();

  const handlePromptClick = (index) => {
    if (result && result[index]) {
      setEditableContent(result[index].content);
      setInputValue(inputsUsed[index]);
      setSelectedPromptIndex(index);
      setIsInputEditable(index === inputsUsed.length - 1);
      onPageChange(index + 1);
    }
  };

  useEffect(() => {
    if (result && result.length > 0 && result[pageIndex - 1] && result[pageIndex - 1].content) {
      setEditableContent(result[pageIndex - 1].content);
      setInputValue(inputsUsed[pageIndex - 1] || '');
      setSelectedPromptIndex(pageIndex - 1);
      setIsInputEditable(pageIndex - 1 === inputsUsed.length - 1);
    }
  }, [pageIndex, result, inputsUsed]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    if (result && result.length > 0 && result[pageIndex - 1] && result[pageIndex - 1].content) {
      setEditableContent(result[pageIndex - 1]?.content);
    }
  };

  const handleSaveEdit = () => {
    setResult((current) => {
      current[pageIndex - 1].content = editableContent;
      return current;
    });
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setIsEditing(false);
    }, 1000);
  };

  const handleOpenAssetModal = () => {
    setIsAssetModalVisible(true);
  };

  const handleCloseAssetModal = () => {
    onClose();
    setIsAssetModalVisible(false);
  };

  useEffect(() => {
    if (inputsUsed && inputsUsed.length > 0 && inputsUsed[pageIndex - 1]) {
      setInputValue(inputsUsed[pageIndex - 1]);
    } else {
      setInputValue(initialInput);
    }
  }, [inputsUsed, pageIndex, initialInput]);

  return (
    <>
      <Modal
        visible={visible}
        centered
        maskClosable={false}
        width='60%'
        onCancel={onClose}
        footer={false}
        className='edit-modal'
        bodyStyle={{
          maxHeight: window.innerHeight > 750 ? '850px' : '613px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              minHeight: '560px',
              maxHeight: window.innerHeight > 750 ? '800px' : '588px',
              overflow: 'auto',
              opacity: isEditing ? 0.5 : 1,
              pointerEvents: isEditing ? 'none' : 'auto',
              backgroundColor: '#f3f3f3',
              padding: '25px 25px 0',
              borderRadius: '30px 0 0 30px',
              borderRight: '1px solid #bababa',
            }}
          >
            <h3>{t('new_request_input')}</h3>
            <Input.TextArea
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              placeholder={t('request_placeholder')}
              style={{ margin: '20px 0', fontSize: '1rem', height: '15vh', maxHeight: '20vh' }}
              disabled={
                (inputsUsed.length > 0 && pageIndex - 1 !== inputsUsed.length - 1) ||
                !isInputEditable
              }
            />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                icon={<SyncOutlined />}
                onClick={() => onRegenerate(inputValue)}
                disabled={!inputValue}
                className='primary-button-hover'
                loading={loadingGenerate}
              >
                {t('generate_again')}
              </Button>
            </div>
            <div>
              <h4>Prompts</h4>
              <div style={{ overflow: 'auto', height: '30vh' }}>
                {inputsUsed.map((input, index) => (
                  <div
                    key={index}
                    style={{
                      padding: '10px 0',
                      borderBottom: '1px solid #ccc',
                      cursor: 'pointer',
                      backgroundColor: selectedPromptIndex === index ? '#d9d9d9' : 'transparent',
                    }}
                    onClick={() => handlePromptClick(index)}
                  >
                    {input.length > 30 ? `${input.substring(0, 30)}...` : input}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            style={{
              width: '60%',
              maxHeight: '600px',
              padding: '25px',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px 0' }}>
              <h3 style={{ fontWeight: 'bold', margin: 0 }}>{t('virtual_engineer_search')}</h3>
              <div
                style={{
                  fontSize: '1.1rem',
                  opacity: isEditing ? 0.5 : 1,
                  pointerEvents: isEditing ? 'none' : 'auto',
                }}
              >
                <span
                  onClick={() => pageIndex > 1 && onPageChange(pageIndex - 1)}
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                >
                  {'<'}
                </span>
                {pageIndex} / {totalPages}
                <span
                  onClick={() => pageIndex < totalPages && onPageChange(pageIndex + 1)}
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                >
                  {'>'}
                </span>
              </div>
            </div>

            <div
              style={{
                position: 'relative',
                padding: !isEditing && '0 10px',
                border: '1px solid #bababa',
                borderRadius: '8px',
                backgroundColor: !isEditing ? '#f3f3f3' : '#FFFFFF',
              }}
            >
              {loading && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                >
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
                </div>
              )}
              <div style={{ opacity: loading ? 0.5 : 1 }}>
                {isEditing ? (
                  <Input.TextArea
                    value={editableContent}
                    onChange={(e) => {
                      setEditableContent(e.target.value);
                    }}
                    rows={22}
                    style={{
                      width: '100%',
                      fontSize: '1rem',
                      borderRadius: '8px',
                      padding: '10px',
                      boxSizing: 'border-box',
                      maxHeight: window.innerHeight > 790 ? '490px' : '445px',
                      overflowY: 'auto',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      overflowY: 'auto',
                      height: window.innerHeight > 790 ? '490px' : '445px',
                      maxHeight: window.innerHeight > 790 ? '490px' : '445px',
                      fontSize: '1rem',
                    }}
                  >
                    <Markdown>{editableContent}</Markdown>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '20px 0 10px',
              }}
            >
              {isEditing ? (
                <div>
                  <Button onClick={handleCancelEdit} className='button-hover'>
                    {t('cancel-btn')}
                  </Button>
                  <Button
                    type='primary'
                    onClick={handleSaveEdit}
                    style={{
                      backgroundColor: '#1890ff',
                      color: 'white',
                      marginLeft: '8px',
                    }}
                  >
                    {t('save_btn')}
                  </Button>
                </div>
              ) : (
                <Button icon={<EditOutlined />} onClick={handleEditClick} className='button-hover'>
                  {t('edit_btn')}
                </Button>
              )}
              <Button
                key='add'
                type='primary'
                onClick={handleOpenAssetModal}
                style={{
                  height: '40px',
                  fontSize: '1.1rem',
                  opacity: isEditing ? 0.5 : 1,
                  pointerEvents: isEditing ? 'none' : 'auto',
                }}
              >
                {t('add_knowledge_button')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <AssetSelectionModal
        closeEditModalVisible={onClose}
        visible={isAssetModalVisible}
        onClose={handleCloseAssetModal}
        editableContent={editableContent}
        generatorId={generatorId}
      />
    </>
  );
};

export default KnowledgeResultModal;
