import moment from 'moment';
import './style.css';
import { useEffect, useState } from 'react';
import { CalendarOutlined } from '@ant-design/icons';

const Date = ({ value, style, onChange, disabled }) => {
  const [date, setDate] = useState('');

  useEffect(() => {
    setDate(value);
  }, [value]);

  const formatDate = (rawDate) => {
    const [year, month, day] = rawDate.split('-');
    return `${day}/${month}/${year}`;
  };

  return (
    <div className='inputContainer'>
      <div className='inputIconContainer'>
        <CalendarOutlined style={{ fontSize: '16px' }} />
      </div>
      <input
        id={`dateInput-${value}`}
        type='date'
        disabled={disabled}
        value={value}
        style={{ ...style, paddingLeft: '3rem' }}
        className={`customInput ${disabled ? 'disabled' : ''}`}
        onChange={(e) => {
          {
            setDate(e.target.value);
            onChange(e);
          }
        }}
      />

      <div key={`formatedDateContainer-${value}`} className='formatedDateContainer'>
        <div className={`formatedDate ${disabled ? 'disabled' : ''}`}>
          {date ? formatDate(date) : ''}
        </div>
      </div>
    </div>
  );
};

export default Date;
