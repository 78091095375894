import useVirtualEngineerState from '../../atoms/variables';

const HeaderEquipmentInfo = () => {
  const { currentEquipment } = useVirtualEngineerState();

  return (
    <div
      key={`title-${currentEquipment?._id}`}
      style={{
        flexGrow: 1,
        height: '100%',
        display: 'flex',
        paddingLeft: '1rem',
        alignItems: 'center',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        gap: '.5rem',
      }}
    >
      {currentEquipment?.name}
    </div>
  );
};

export default HeaderEquipmentInfo;
