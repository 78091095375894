import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useTemplateState, { apiCalls } from '../../../../Template/atoms/variables';
import { useTranslation } from 'react-i18next';
import Tabs from '../../../../Template/layoutComponents/Tabs';

import useSettingsState from '../../../atoms/variables';

import './style.css';

import GeneralTab from './components/GeneralTab';
import CommissioningTab from './components/CommissioningTab';
import HistoryTab from '../../../../Template/layoutComponents/HistoryTab';
import { updateCategory, updateInstrumentCategory } from '../../../helpers/apiCalls';
import UploadDocumentPage from '../../../../Template/layoutComponents/UploadDocumentPage';
import { deleteFile, getFiles, saveFile } from '../../../../Maintenance/helpers/files';

const Details = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { categoryId, chosenType } = useParams();

  const location = useLocation();

  const { companyInfo } = useTemplateState();

  const { viewDetailsCategory, viewDetailsCategories } = useSettingsState();

  useEffect(() => {
    if (!categoryId || !viewDetailsCategories) {
      navigate(`/settings/asset-tree/manage/${chosenType}`);
    }
  }, [location]);

  return (
    <div id='assetDetails'>
      <Tabs
        tabs={[
          {
            label: t('general_tab'),
            content: <GeneralTab />,
            disabled: false,
          },
          (chosenType == 'assets' || chosenType === 'interconnections') && {
            label: t('commissioning_criteria_tab'),
            content: <CommissioningTab />,
            disabled: !companyInfo?.commissioning || !viewDetailsCategory?.commissionable,
          },
          {
            label: t('documents_tab'),
            content: (
              <UploadDocumentPage
                id={categoryId}
                type={`${chosenType.slice(0, chosenType.length - 1)}Category`}
                getCall={getFiles}
                uploadCall={saveFile}
                deleteCall={deleteFile}
              />
            ),

            disabled: false,
          },
          {
            label: t('history_tab'),
            content: <HistoryTab id={categoryId} />,
            disabled: false,
          },
        ].filter((tab) => tab)}
      />
    </div>
  );
};

export default Details;
