import { Input, Button, Radio } from 'antd';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';

export const mapCategoryColumn = (column, categories, sorterState, filterState) => {
  const categoryFilters = categories?.map((sub) => ({
    text: sub.name,
    value: sub._id,
  }));

  const isFilteredAndSortered =
    (filterState.category && filterState.category.length > 0) ||
    (sorterState.field === 'category' && sorterState.order);

  return {
    ...column,
    filtered: filterState.category && filterState.category.length > 0,
    title: (
      <span style={{ color: isFilteredAndSortered ? '#1890ff' : 'inherit' }}>{column.title}</span>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Radio.Group
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm();
          }}
          value={selectedKeys[0]}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {categoryFilters.map((filter) => (
              <div
                key={filter.value}
                style={{
                  backgroundColor: '#faf3e0',
                  padding: '5px',
                  borderRadius: '4px',
                  marginBottom: '2px',
                  border: '1px solid #d9d9d9',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f5e6cc')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#faf3e0')}
              >
                <Radio value={filter.value}>{filter.text}</Radio>
              </div>
            ))}
          </div>
        </Radio.Group>
      </div>
    ),
    onFilter: (value, record) => record.category._id === value,
    sorter: true,
    sortOrder: sorterState.field === 'category' ? sorterState.order : null,
    sortDirections: ['ascend', 'descend'],
  };
};

const statusStyles = {
  Approved: {
    backgroundColor: '#d4f7d4',
    padding: '5px',
    marginBottom: '2px',
    borderRadius: '4px',
  },
  Pending: { backgroundColor: '#fbe8a6', padding: '5px', marginBottom: '2px', borderRadius: '4px' },
  Scheduled: {
    backgroundColor: '#e0e0e0',
    padding: '5px',
    marginBottom: '2px',
    borderRadius: '4px',
  },
  Success: { backgroundColor: '#d4f7d4', padding: '5px', marginBottom: '2px', borderRadius: '4px' },
  Fail: { backgroundColor: '#f8d7da', padding: '5px', marginBottom: '2px', borderRadius: '4px' },
};

const hoverStyles = {
  Approved: '#bde8bd',
  Pending: '#f7d08a',
  Scheduled: '#cfcfcf',
  Success: '#bde8bd',
  Fail: '#f5b7b7',
};

const statuses = ['Approved', 'Pending', 'Scheduled', 'Success', 'Fail'];

export const mapStatusColumn = (column, sorterState, filterState) => {
  const isFilteredAndSortered =
    (filterState.status && filterState.status.length > 0) ||
    (sorterState.field === 'status' && sorterState.order);

  return {
    ...column,
    filtered: filterState.status && filterState.status.length > 0,
    title: (
      <span style={{ color: isFilteredAndSortered ? '#1890ff' : 'inherit' }}>{column.title}</span>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Radio.Group
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm();
          }}
          value={selectedKeys[0]}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {statuses.map((status) => (
              <div
                key={status}
                style={statusStyles[status]}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = hoverStyles[status])}
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = statusStyles[status].backgroundColor)
                }
              >
                <Radio value={status}>{status}</Radio>
              </div>
            ))}
          </div>
        </Radio.Group>
      </div>
    ),
    onFilter: (value, record) => record.status === value,
    sorter: true,
    sortOrder: sorterState.field === 'status' ? sorterState.order : null,
    sortDirections: ['ascend', 'descend'],
  };
};

export const mapOriginColumn = (column, allOrigins, filterState) => {
  const originFilters = allOrigins.map((origin) => ({
    text: typeof origin === 'string' ? origin : JSON.stringify(origin),
    value: typeof origin === 'string' ? origin : JSON.stringify(origin),
  }));

  const isFilteredAndSortered =
    filterState['properties.origin'] && filterState['properties.origin'].length > 0;

  const itemsPerPage = 10;
  const totalPages = Math.ceil(originFilters.length / itemsPerPage);

  const buttonStyle = (isDisabled) => ({
    padding: '5px 10px',
    borderRadius: '4px',
    border: '1px solid #d9d9d9',
    backgroundColor: isDisabled ? '#d3d3d3' : '#e8f0fe',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    transition: 'background-color 0.3s ease',
  });

  return {
    ...column,
    filtered: isFilteredAndSortered,
    title: (
      <span style={{ color: isFilteredAndSortered ? '#1890ff' : 'inherit' }}>{column.title}</span>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => {
      const currentPage = selectedKeys[1] || 1;
      const startIndex = (currentPage - 1) * itemsPerPage;
      const currentItems = originFilters.slice(startIndex, startIndex + itemsPerPage);

      const handlePrevious = () => {
        if (currentPage > 1) {
          setSelectedKeys([selectedKeys[0], currentPage - 1]);
        }
      };

      const handleNext = () => {
        if (currentPage < totalPages) {
          setSelectedKeys([selectedKeys[0], currentPage + 1]);
        }
      };

      return (
        <div style={{ padding: 8 }}>
          <Radio.Group
            onChange={(e) => {
              setSelectedKeys([e.target.value, currentPage]);
              confirm();
            }}
            value={selectedKeys[0]}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {currentItems.map((filter) => (
                <div
                  key={filter.value}
                  style={{
                    backgroundColor: '#faf3e0',
                    padding: '5px',
                    borderRadius: '4px',
                    marginBottom: '2px',
                    border: '1px solid #d9d9d9',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    width: '15vw',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f5e6cc')}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#faf3e0')}
                >
                  <Radio value={filter.value} style={{ width: '100%' }}>
                    {filter.text}
                  </Radio>
                </div>
              ))}
            </div>
          </Radio.Group>
          {/* Controles de paginação */}
          <div style={{ marginTop: 8 }}>
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              style={buttonStyle(currentPage === 1)}
            >
              Anterior
            </button>
            <span style={{ margin: '0 4px' }}>
              Página {currentPage} de {totalPages}
            </span>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              style={buttonStyle(currentPage === totalPages)}
            >
              Próximo
            </button>
          </div>
        </div>
      );
    },
    onFilter: (value, record) => record?.properties?.origin === value,
    sorter: false,
  };
};

export const mapOriginColumnByInput = (column, sorterState, filterState) => {
  const isFilteredAndSortered =
    (filterState['properties.origin'] && filterState['properties.origin'].length > 0) ||
    (sorterState.field === 'properties.origin' && sorterState.order);

  return {
    ...column,
    filtered: filterState['properties.origin'] && filterState['properties.origin'].length > 0,
    title: (
      <span style={{ color: isFilteredAndSortered ? '#1890ff' : 'inherit' }}>{column.title}</span>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder='Buscar por nome'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={confirm}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 4 }}
        >
          Buscar
        </Button>
        <Button onClick={clearFilters} size='small' style={{ width: 90, marginTop: 8 }}>
          Limpar
        </Button>
      </div>
    ),
    onFilter: (value, record) => {
      return record.properties.origin.toLowerCase().includes(value.toLowerCase());
    },
  };
};

export const mapDestinyColumn = (column, allDestinies, filterState) => {
  const destinyFilters = allDestinies.filter(Boolean).map((destiny) => ({
    text: typeof destiny === 'string' ? destiny : JSON.stringify(destiny),
    value: typeof destiny === 'string' ? destiny : JSON.stringify(destiny),
  }));

  const isFilteredAndSortered =
    filterState['properties.destiny'] && filterState['properties.destiny'].length > 0;

  const itemsPerPage = 10;
  const totalPages = Math.ceil(destinyFilters.length / itemsPerPage);

  const buttonStyle = (isDisabled) => ({
    padding: '5px 10px',
    borderRadius: '4px',
    border: '1px solid #d9d9d9',
    backgroundColor: isDisabled ? '#d3d3d3' : '#e8f0fe',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    transition: 'background-color 0.3s ease',
  });

  return {
    ...column,
    filtered: isFilteredAndSortered,
    title: (
      <span style={{ color: isFilteredAndSortered ? '#1890ff' : 'inherit' }}>{column.title}</span>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => {
      const currentPage = selectedKeys[1] || 1;
      const startIndex = (currentPage - 1) * itemsPerPage;
      const currentItems = destinyFilters.slice(startIndex, startIndex + itemsPerPage);

      const handlePrevious = () => {
        if (currentPage > 1) {
          setSelectedKeys([selectedKeys[0], currentPage - 1]);
        }
      };

      const handleNext = () => {
        if (currentPage < totalPages) {
          setSelectedKeys([selectedKeys[0], currentPage + 1]);
        }
      };

      return (
        <div style={{ padding: 8 }}>
          <Radio.Group
            onChange={(e) => {
              setSelectedKeys([e.target.value, currentPage]);
              confirm();
            }}
            value={selectedKeys[0]}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {currentItems.map((filter) => (
                <div
                  key={filter.value}
                  style={{
                    backgroundColor: '#faf3e0',
                    padding: '5px',
                    borderRadius: '4px',
                    marginBottom: '2px',
                    border: '1px solid #d9d9d9',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    width: '15vw',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f5e6cc')}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#faf3e0')}
                >
                  <Radio value={filter.value} style={{ width: '100%' }}>
                    {filter.text}
                  </Radio>
                </div>
              ))}
            </div>
          </Radio.Group>
          {/* Controles de paginação */}
          <div style={{ marginTop: 8, display: 'flex', justifyContent: 'space-between' }}>
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              style={buttonStyle(currentPage === 1)}
            >
              Anterior
            </button>
            <span style={{ margin: '0 4px' }}>
              Página {currentPage} de {totalPages}
            </span>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              style={buttonStyle(currentPage === totalPages)}
            >
              Próximo
            </button>
          </div>
        </div>
      );
    },
    onFilter: (value, record) => record?.properties?.destiny === value,
    sorter: false,
  };
};

export const mapDestinyColumnByInput = (column, sorterState, filterState) => {
  const isFilteredAndSortered =
    (filterState['properties.destiny'] && filterState['properties.destiny'].length > 0) ||
    (sorterState.field === 'properties.destiny' && sorterState.order);

  return {
    ...column,
    filtered: filterState['properties.destiny'] && filterState['properties.destiny'].length > 0,
    title: (
      <span style={{ color: isFilteredAndSortered ? '#1890ff' : 'inherit' }}>{column.title}</span>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder='Buscar por nome'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={confirm}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 4 }}
        >
          Buscar
        </Button>
        <Button onClick={clearFilters} size='small' style={{ width: 90, marginTop: 8 }}>
          Limpar
        </Button>
      </div>
    ),
    onFilter: (value, record) => {
      return record.properties.destiny.toLowerCase().includes(value.toLowerCase());
    },
  };
};

export const mapNameColumn = (column, sorterState, filterState) => {
  const isFilteredAndSortered =
    (filterState.name && filterState.name.length > 0) ||
    (sorterState.field === 'name' && sorterState.order);

  return {
    ...column,
    filtered: filterState.name && filterState.name.length > 0,
    title: (
      <span style={{ color: isFilteredAndSortered ? '#1890ff' : 'inherit' }}>{column.title}</span>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder='Buscar por nome'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={confirm}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 4 }}
        >
          Buscar
        </Button>
        <Button onClick={clearFilters} size='small' style={{ width: 90, marginTop: 8 }}>
          Limpar
        </Button>
      </div>
    ),
    onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => a.name.localeCompare(b.name, 'pt', { ignorePunctuation: true }),

    sortOrder: sorterState.field === 'name' ? sorterState.order : null,
    sortDirections: ['ascend', 'descend'],
  };
};

const requiredTasksStyles = {
  'Teste de Megger': {
    backgroundColor: '#e0e0e0',
    padding: '5px',
    marginBottom: '2px',
    borderRadius: '4px',
  },
  'Teste de Hipot': {
    backgroundColor: '#e0e0e0',
    padding: '5px',
    marginBottom: '2px',
    borderRadius: '4px',
  },
  Checklist: {
    backgroundColor: '#e0e0e0',
    padding: '5px',
    marginBottom: '2px',
    borderRadius: '4px',
  },
};

const hoverTasksStyles = {
  'Teste de Megger': '#cfcfcf',
  'Teste de Hipot': '#cfcfcf',
  Checklist: '#cfcfcf',
};

const tasks_type = ['Teste de Megger', 'Teste de Hipot', 'Checklist'];

export const mapRequiredTasksColumn = (column, sorterState, filterState) => {
  const isFiltered =
    filterState['commissioning_tasks'] && filterState['commissioning_tasks'].length > 0;

  return {
    ...column,
    filtered: filterState['commissioning_tasks'] && filterState['commissioning_tasks'].length > 0,
    title: <span style={{ color: isFiltered ? '#1890ff' : 'inherit' }}>{column.title}</span>,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Radio.Group
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm();
          }}
          value={selectedKeys[0]}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {tasks_type.map((tasks) => (
              <div
                key={tasks}
                style={requiredTasksStyles[tasks]}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = hoverTasksStyles[tasks])
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    requiredTasksStyles[tasks].backgroundColor)
                }
              >
                <Radio value={tasks}>{tasks}</Radio>
              </div>
            ))}
          </div>
        </Radio.Group>
      </div>
    ),
    onFilter: (value, record) => record.commissioning_tasks === value,
  };
};
