import React from 'react';
import { Table, Typography, Dropdown, Menu, Button } from 'antd';
import {
  EllipsisOutlined,
  PaperClipOutlined,
  EditOutlined,
  DeleteOutlined,
  FileAddOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

const columns = [
  {
    title: 'Nome',
    dataIndex: 'title',
    key: 'title',
    render: (text) => <span style={{ fontSize: '14px', fontWeight: 'normal' }}>{text}</span>,
  },
  {
    title: 'Descrição',
    dataIndex: 'title',
    key: 'title',
    render: (text) => <span style={{ fontSize: '14px', fontWeight: 'normal' }}>{text}</span>,
  },
  {
    key: 'action',
    render: () => (
      <div style={{ textAlign: 'right' }}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key='1' icon={<EditOutlined />}>
                Editar descrição
              </Menu.Item>
              <Menu.Item key='2' icon={<DeleteOutlined />}>
                Apagar anexo
              </Menu.Item>
              <Menu.Item key='3' icon={<FileAddOutlined />}>
                Adicionar ao relatório
              </Menu.Item>
              <Menu.Item disabled key='4' icon={<FileExcelOutlined />}>
                Remover do Relatório
              </Menu.Item>
            </Menu>
          }
        >
          <Button type='text' icon={<EllipsisOutlined />} />
        </Dropdown>
      </div>
    ),
  },
];

const AttachmentsTable = ({ task }) => {
  const steps = task.phase.find((ph) => ph.type === 'camera').steps;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '16px 0 0',
          padding: '0 2rem',
        }}
      >
        <Text style={{ fontSize: '14px' }} strong>
          Anexos:
        </Text>
      </div>
      <Table
        rowClassName={() => 'custom-row'}
        style={{ padding: '30px 30px 10px' }}
        dataSource={steps}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default AttachmentsTable;
