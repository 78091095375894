import { useState } from 'react';

import './style.css';

const Tabs = ({
  tabs = [
    { label: 'Tab 1', content: <div>Tab 1</div>, disabled: false },
    { label: 'Tab 2', content: <div>Tab 2</div>, disabled: false },
  ],
  defaultOpen = 0,
  chosenTabStyleOverride,
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultOpen);

  return (
    <div className='tabs'>
      <div className='tabsContainer'>
        {tabs.map((tab, index) => {
          return (
            <div
              className={`tab ${index === selectedTab ? 'selected' : ''} ${tab.disabled ? 'disabled' : ''}`}
              onClick={() => {
                if (!tab.disabled) setSelectedTab(index);
                if (tab?.onClick && !tab?.disabled) tab?.onClick();
              }}
            >
              {tab.label}
            </div>
          );
        })}
      </div>
      <div className='chosenTabContent' style={chosenTabStyleOverride}>
        {tabs[selectedTab]?.content}
      </div>
    </div>
  );
};

export default Tabs;
