import React, { useEffect, useState } from 'react';
import { Flex, Form, message, Spin, Tabs } from 'antd';

import './style.css';
import PageContent from '../../../../layoutComponents/PageContent';
import PageHeader from '../../../../layoutComponents/PageHeader';
import { useGlobalState } from '../../../../hooks/useGlobalState';
import { useNavigate, useParams } from 'react-router-dom';
import useCrudApi from '../../../../hooks/useCrudApi';
import HeaderAndFormSection from './HeaderSection';
import WorkOrderTitles from './WorkOrderTitles';
import ListCommissioning from '../../Interconnections/ViewDetails/Commissioning/List';

export default function ViewWorkOrderDetails() {
  const element = 'work-order-details';
  const navigate = useNavigate();
  const [editing, setEditing] = useState(false);
  const { siteName, workOrderToken } = useParams();
  const { detailsSelection, setDetailsSelection } = useGlobalState();

  const { updateItem, updating, deleteItem, deleting } = useCrudApi('work-order');

  const fields = [
    {
      label: 'Contratado',
      key: 'employee.company_name',
      type: 'input',
      selectItem: 'executors',
      multiple: false,
      required: false,
      disabled: true,
      value:
        detailsSelection && detailsSelection.employee
          ? detailsSelection.employee.company_name
          : null,
    },
    {
      label: 'Executor',
      key: 'employee.name',
      type: 'input',
      selectItem: 'executors',
      disabled: true,
      multiple: false,
      required: false,
      value: detailsSelection && detailsSelection.employee ? detailsSelection.employee.name : null,
    },
    {
      label: 'Data Prevista',
      key: 'scheduled_date',
      type: 'date',
      required: false,
      value: detailsSelection ? detailsSelection.scheduled_date : null,
    },
    {
      label: 'Status',
      key: 'status',
      type: 'tag',
      required: false,
      disabled: true,
      value: detailsSelection ? detailsSelection.status : null,
    },
  ];
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!detailsSelection || !detailsSelection.interconnection_list) {
          // let mappedCommissioningTree = { ...commissioningTree };
          // if (!commissioningTree.site.id) {
          //   const siteFilter = {
          //     name: {
          //       $eq: siteName,
          //     },
          //   };
          //   const site = await getSiteByName(JSON.stringify(siteFilter));
          //   if (site) {
          //     mappedCommissioningTree.site = { id: site._id, name: site.name };
          //     setCommissioningTree(mappedCommissioningTree);
          //   }
          // }
          // const workOrderFilter = {
          //   location: { $eq: mappedCommissioningTree.site.id },
          //   token: { $eq: workOrderToken },
          // };
          // const workOrder = await getWorkOrderByName(JSON.stringify(workOrderFilter));
          // if (workOrder) {
          //   setDetailsSelection(workOrder);
          // }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async () => {
    try {
      const newScheduledDate = await form.getFieldValue('scheduled_date');
      const success = await updateItem(detailsSelection._id, { scheduled_date: newScheduledDate });
      if (success) {
        setEditing(false);
        form.resetFields();
        navigate(-1);
      }
    } catch (error) {
      message.error('Erro ao enviar o formulário');
    }
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleDeleteClick = async () => {
    try {
      const success = await deleteItem(detailsSelection._id);
      if (success) {
        navigate(-1);
      }
    } catch (error) {
      message.error('Erro ao excluir o item');
    }
  };

  const Content = () => {
    return (
      <Flex vertical id='view-datails-list'>
        <>
          <HeaderAndFormSection
            editing={editing}
            workOrder={detailsSelection}
            loading={deleting || updating}
            form={form}
            fields={fields}
            items={detailsSelection}
            handleCancel={handleCancel}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            handleSubmit={handleSubmit}
          />
          <WorkOrderTitles
            workOrder={detailsSelection}
            titleTable={'Tarefas desta Ordem de Serviço'}
          />
          {detailsSelection &&
            detailsSelection.interconnection_list?.map((item) => (
              <ListCommissioning
                interconnection={item.interconnection}
                work_order_token={workOrderToken}
                title={item.interconnection.name}
                compactMode
                showDetails
                hideTimeline
              />
            ))}
        </>
      </Flex>
    );
  };

  return (
    <>
      {!detailsSelection ? (
        <Spin spinning fullscreen />
      ) : (
        <>
          <PageHeader keyElement={detailsSelection.name} />
          <PageContent Content={Content} />
        </>
      )}
    </>
  );
}
