import { Input, Button, Radio, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

export const mapExecutorColumn = (column, sorterState, filterState) => {
  const isFilteredAndSortered =
    (filterState.employee && filterState.employee.length > 0) ||
    (sorterState.field === 'employee' && sorterState.order);

  return {
    ...column,
    filtered: filterState.employee && filterState.employee.length > 0,
    title: (
      <span style={{ color: isFilteredAndSortered ? '#1890ff' : 'inherit' }}>{column.title}</span>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder='Buscar por executor'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={confirm}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 4 }}
        >
          Buscar
        </Button>
        <Button onClick={clearFilters} size='small' style={{ width: 90, marginTop: 8 }}>
          Limpar
        </Button>
      </div>
    ),
    onFilter: (value, record) => record.executor?.toLowerCase().includes(value?.toLowerCase()),
    sorter: true,
    sortOrder: sorterState.field === 'employee' ? sorterState.order : null,
    sortDirections: ['ascend', 'descend'],
  };
};

export const mapDataPrevistaColumn = (column, sorterState, filterState) => {
  const isFilteredAndSortered =
    (filterState.scheduled_date && filterState.scheduled_date.length > 0) ||
    (sorterState.field === 'scheduled_date' && sorterState.order);

  return {
    ...column,
    filtered: filterState.scheduled_date && filterState.scheduled_date.length > 0,
    title: (
      <span style={{ color: isFilteredAndSortered ? '#1890ff' : 'inherit' }}>{column.title}</span>
    ),
    sorter: true,
    sortOrder: sorterState.field === 'scheduled_date' ? sorterState.order : null,
    sortDirections: ['ascend', 'descend'],
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          placeholder='Selecione..'
          value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
          onChange={(date) => {
            setSelectedKeys(date ? [date.format('YYYY-MM-DD')] : []);
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button type='primary' onClick={confirm} style={{ width: '100%' }}>
          Buscar
        </Button>
        <Button type='primary' ghost onClick={clearFilters} style={{ width: '100%', marginTop: 8 }}>
          Limpar
        </Button>
      </div>
    ),
    onFilter: (value, record) => {
      const recordDate = moment(record.scheduled_date).format('YYYY-MM-DD');
      return recordDate === value;
    },
  };
};

export const mapTokenColumn = (column, sorterState, filterState) => {
  const isFilteredAndSortered =
    (filterState.token && filterState.token.length > 0) ||
    (sorterState.field === 'token' && sorterState.order);

  return {
    ...column,
    filtered: filterState.token && filterState.token.length > 0,
    title: (
      <span style={{ color: isFilteredAndSortered ? '#1890ff' : 'inherit' }}>{column.title}</span>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder='Buscar por token'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={confirm}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 4 }}
        >
          Buscar
        </Button>
        <Button onClick={clearFilters} size='small' style={{ width: 90, marginTop: 8 }}>
          Limpar
        </Button>
      </div>
    ),
    onFilter: (value, record) => record.token.toLowerCase().includes(value.toLowerCase()),
  };
};

const statusStyles = {
  Scheduled: {
    backgroundColor: '#e0e0e0',
    padding: '5px',
    marginBottom: '2px',
    borderRadius: '4px',
  },
  Ongoing: { backgroundColor: '#cce5ff', padding: '5px', marginBottom: '2px', borderRadius: '4px' },
  Completed: {
    backgroundColor: '#d4f7d4',
    padding: '5px',
    marginBottom: '2px',
    borderRadius: '4px',
  },
  Incomplete: {
    backgroundColor: '#ffd7b5',
    padding: '5px',
    marginBottom: '2px',
    borderRadius: '4px',
  },
  Expired: { backgroundColor: '#f8d7da', padding: '5px', marginBottom: '2px', borderRadius: '4px' },
};

const hoverStyles = {
  Scheduled: '#cfcfcf',
  Ongoing: '#99ccff',
  Completed: '#bde8bd',
  Incomplete: '#ffbb91',
  Expired: '#f5b7b7',
};

const statuses = ['Scheduled', 'Ongoing', 'Completed', 'Incomplete', 'Expired'];

export const mapStatusColumn = (column, sorterState, filterState) => {
  const isFilteredAndSortered =
    (filterState.status && filterState.status.length > 0) ||
    (sorterState.field === 'status' && sorterState.order);

  return {
    ...column,
    filtered: filterState.status && filterState.status.length > 0,
    title: (
      <span style={{ color: isFilteredAndSortered ? '#1890ff' : 'inherit' }}>{column.title}</span>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Radio.Group
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm();
          }}
          value={selectedKeys[0]}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {statuses.map((status) => (
              <div
                key={status}
                style={statusStyles[status]}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = hoverStyles[status])}
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = statusStyles[status].backgroundColor)
                }
              >
                <Radio value={status}>{status}</Radio>
              </div>
            ))}
          </div>
        </Radio.Group>
      </div>
    ),
    onFilter: (value, record) => record.status === value,
    sorter: true,
    sortOrder: sorterState.field === 'status' ? sorterState.order : null,
    sortDirections: ['ascend', 'descend'],
  };
};
