import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Tabs from '../../../../Template/layoutComponents/Tabs';

import HistoryTab from '../../../../Template/layoutComponents/HistoryTab';
import GeneralTab from '../../../../Template/layoutComponents/GeneralTab';

import {
  getAllAssetsByManufacturer,
  updateManufacturer,
} from '../../../../Maintenance/helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import { notification } from 'antd';

import './style.css';
import AllAssetsTab from './components/allAssetsTab';

const Details = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const location = useLocation();
  useEffect(() => {
    if (!categoryId) {
      navigate(`/settings/inventory`);
    }
  }, [location]);

  return (
    <div id='assetDetails'>
      <Tabs
        tabs={[
          {
            label: t('general_tab'),
            content: (
              <GeneralTab detailsLink={'/settings/inventory'} updateItem={updateManufacturer} />
            ),
            disabled: false,
          },
          {
            label: t('all_assets'),
            content: <AllAssetsTab />,
          },
          {
            label: t('history_tab'),
            content: <HistoryTab id={categoryId} />,
            disabled: false,
          },
        ].filter((tab) => tab)}
      />
    </div>
  );
};

export default Details;
