import { useNavigate, useParams } from 'react-router-dom';
import Tabs from '../../../../Template/layoutComponents/Tabs';
import useMaintenanceState from '../../../atoms/variables';
import DoubleColumnTemplate from '../../../../Template/layoutComponents/DoubleColumn';
import Forms from '../../../../Template/layoutComponents/Forms';
import { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import useTemplateState from '../../../../Template/atoms/variables';
import { getInstrumentCategories } from '../../../../Settings/helpers/apiCalls';
import { useMsal } from '@azure/msal-react';

import { AppstoreAddOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { newInstrument, updateInstrument } from '../../../helpers/apiCalls';
import { useTranslation } from 'react-i18next';

import UploadDocumentPage from '../../../../Template/layoutComponents/UploadDocumentPage/index';

import './style.css';
import { deleteFile, getFiles, saveFile } from '../../../helpers/files';

const InstrumentDetails = () => {
  const { t } = useTranslation();
  const { instrumentId } = useParams();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();

  const {
    instruments,
    chosenInstrument,
    setChosenInstrument,
    departments,
    orgs,
    systems,
    manufacturers,
  } = useMaintenanceState();

  const { setPendingApiCalls } = useTemplateState();

  const [editing, setEditing] = useState(false);
  const [allCategories, setAllCategories] = useState([]);

  const [halfMain, setHalfMain] = useState(0);
  const [halfCat, setHalfCat] = useState(0);

  useEffect(() => {
    if (instrumentId && chosenInstrument) triggerApiCalls();
    else navigate('/maintenance/instruments');
  }, []);

  useEffect(() => {
    setHalfMain(Math.ceil(chosenInstrument?.properties.filter((prop) => prop.main).length / 2));
    setHalfCat(Math.ceil(chosenInstrument?.properties.filter((prop) => !prop.main).length / 2));
  }, [chosenInstrument]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getInstrumentCategories']));

      const responses = await Promise.all([getInstrumentCategories(instance)]);

      setAllCategories(responses[0]);
    } catch (err) {
      // API fail notification
      notificationApi.error({
        message: 'Não foi possível recuperar todas as informações necessárias',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getInstrumentCategories'));
    }
  };

  const updateCurrentEditingItem = (current, inputIndex, event) => {
    let chosenCategory;
    // Search by current selected ID
    chosenCategory = allCategories?.find((cat) => {
      return cat.id === event.target.value;
    });

    // If not found, search by selected Categoy in 'Categoria' property
    if (!chosenCategory)
      chosenCategory = allCategories?.find((cat) => {
        const catProp = current.properties.find((prop) => prop?.key === 'category' && prop?.main);

        return cat.id === catProp?.value?.id;
      });

    console.log('CHOSEN CAT', chosenCategory?.certifiable);

    const chosenCategoryProperties = chosenCategory ? [...chosenCategory?.properties] : [];
    const certifiable = chosenCategory?.certifiable;

    let updateName = false;

    return {
      ...current,
      properties: [
        ...current.properties.map((item, index) => {
          if (item.key === 'certification_date') {
            item = {
              ...item,
              value: certifiable ? item.value : null,
              disabled: !certifiable,
              required: certifiable,
            };
          }

          if (index === inputIndex) {
            if (item.label.toLowerCase() === 'nome') {
              updateName = event.target.value;
            }

            return {
              ...item,
              value: item.type.toLowerCase().includes('select')
                ? Array.isArray(event.target.value)
                  ? event.target.value.map((value) => ({
                      id: value,
                      label: '',
                    }))
                  : { id: event.target.value, label: '' }
                : item.type.toLowerCase().includes('bool')
                  ? event.target.value
                  : event.target.value,
              // event.target.value?.replace(/ /g, ''),
            };
          }
          return item;
        }),
      ]
        .filter(
          (prop) =>
            prop.main ||
            chosenCategoryProperties.some((currentCatProp) => currentCatProp.label == prop.label),
        )
        .concat(
          chosenCategory?.properties
            ?.filter((prop) => !prop.main)
            .filter(
              (prop) =>
                !current.properties.some(
                  (currentProp) => !currentProp.main && currentProp.label == prop.label,
                ),
            ) ?? [],
        ),
      name: updateName ? updateName : current.name,
    };
  };

  const disableSave = () => {
    if (editing || instrumentId === 'newInstrument') {
      return chosenInstrument?.properties.some(
        (prop) => prop.required === true && (prop.value === '' || prop.value === null),
      );
    }

    return true;
  };

  return (
    <div id='assetDetails'>
      {contextHolder}
      <Tabs
        tabs={[
          {
            label: t('details_tab'),
            content: (
              <div className='detailsTab'>
                <div className='propsForm mainProps'>
                  <div className='columnTitle sticky'>{t('main_properties')}</div>

                  <div className='propsFormContent'>
                    <div className='propsColumn firstColumn'>
                      <Forms
                        id={`${chosenInstrument?.id}-main-props`}
                        inputs={chosenInstrument?.properties
                          .filter((prop) => prop.main)
                          .slice(0, halfMain)}
                        onInputChange={(event, inputIndex) => {
                          setEditing(true);

                          setChosenInstrument((current) => {
                            if (current) {
                              return updateCurrentEditingItem(current, inputIndex, event);
                            }
                            return current;
                          });
                        }}
                        categories={allCategories}
                        departments={departments}
                        orgs={orgs}
                        systems={systems}
                        manufacturers={manufacturers}
                      />
                    </div>
                    {chosenInstrument?.properties.filter((prop) => prop.main).slice(halfMain)
                      .length > 0 && (
                      <div className='propsColumn secondColumn'>
                        <Forms
                          id={`${chosenInstrument?.id}-cat-props`}
                          inputs={chosenInstrument?.properties
                            .filter((prop) => prop.main)
                            .slice(halfMain)}
                          onInputChange={(event, inputIndex) => {
                            setEditing(true);

                            setChosenInstrument((current) => {
                              if (current) {
                                return updateCurrentEditingItem(
                                  current,
                                  +halfMain + inputIndex,
                                  event,
                                );
                              }
                              return current;
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {chosenInstrument?.properties.filter((prop) => !prop.main).length > 0 && (
                  <div className='propsForm catProps'>
                    <div className='columnTitle sticky'>{t('cat_properties')}</div>
                    <div className='propsFormContent'>
                      <div className='propsColumn firstColumn'>
                        <Forms
                          id={`${chosenInstrument?.id}-cat-props`}
                          inputs={chosenInstrument?.properties
                            .filter((prop) => !prop.main)
                            .slice(0, halfCat)}
                          onInputChange={(event, inputIndex) => {
                            setEditing(true);

                            setChosenInstrument((current) => {
                              if (current) {
                                return updateCurrentEditingItem(
                                  current,
                                  chosenInstrument?.properties.filter((prop) => prop.main).length +
                                    inputIndex,
                                  event,
                                );
                              }
                              return current;
                            });
                          }}
                        />
                      </div>
                      {chosenInstrument?.properties.filter((prop) => !prop.main).slice(halfCat)
                        .length > 0 && (
                        <div className='propsColumn secondColumn'>
                          <Forms
                            id={`${chosenInstrument?.id}-cat-props`}
                            inputs={chosenInstrument?.properties
                              .filter((prop) => !prop.main)
                              .slice(halfCat)}
                            onInputChange={(event, inputIndex) => {
                              setEditing(true);

                              setChosenInstrument((current) => {
                                if (current) {
                                  return updateCurrentEditingItem(
                                    current,
                                    chosenInstrument?.properties.filter((prop) => prop.main)
                                      .length +
                                      halfCat +
                                      inputIndex,
                                    event,
                                  );
                                }
                                return current;
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
            disabled: false,
          },
          {
            label: t('documents_tab'),
            content: (
              <UploadDocumentPage
                id={instrumentId}
                type={'instrument'}
                getCall={getFiles}
                uploadCall={saveFile}
                deleteCall={deleteFile}
              />
            ),

            disabled: instrumentId === 'newInstrument',
          },
        ].filter((tab) => tab)}
        defaultOpen={0}
      />
      <div className='saveButton'>
        <Button
          id='cancel'
          icon={<CloseCircleOutlined />}
          size='large'
          style={{
            borderColor: 'black',
            background: 'white',
            color: 'black',
          }}
          onClick={async () => {
            navigate('/maintenance/instruments');
          }}
        >
          {t('cancel-btn')}
        </Button>
        <Button
          icon={<AppstoreAddOutlined />}
          size='large'
          style={{
            borderColor: '#1777FF',
            background: '#1777FF',
            color: 'white',
            opacity: disableSave() ? 0.5 : 1,
          }}
          disabled={disableSave()}
          onClick={async () => {
            try {
              setPendingApiCalls((current) => current.concat(['newInstrument']));
              if (instrumentId === 'newInstrument') {
                const mongoId = await newInstrument(instance, chosenInstrument);
                setChosenInstrument((current) => ({ ...current, id: mongoId }));
                navigate(`/maintenance/instruments/${mongoId}`);
              } else {
                const mongoId = await updateInstrument(instance, chosenInstrument);
              }
            } catch (err) {
              notificationApi.error({
                message: 'Falha ao salvar item',
                description: err?.response?.data?.message
                  ? `${err?.response?.data?.message} (${err?.response?.status})`
                  : err?.message,
                duration: 5,
              });
              navigate('/maintenance/instruments');
            } finally {
              setPendingApiCalls((current) => current.filter((item) => item !== 'newInstrument'));
            }
          }}
        >
          Salvar
        </Button>
      </div>
    </div>
  );
};

export default InstrumentDetails;
