import React, { useState } from 'react';

import SingleSelect from '../SingleSelect';

import sortDefault from '../../images/Sort.svg';
import sortAsc from '../../images/SortAsc.svg';
import sortDsc from '../../images/SortDsc.svg';
import filterImg from '../../images/filter.svg';
import filterSelected from '../../images/filterSelected.svg';
import paginationLeft from '../../images/paginationLeft.svg';
import paginationRight from '../../images/paginationRight.svg';

import { CloseCircleOutlined } from '@ant-design/icons';

import './style.css';
import { useEffect } from 'react';
import Checkbox from '../Checkmark';
import { Button, notification, Skeleton } from 'antd';
import useTemplateState from '../../atoms/variables';
import { useMsal } from '@azure/msal-react';
import { isDivOutOfViewport } from '../../../helpers/misc';
import Input from '../Input';
import { useTranslation } from 'react-i18next';

const Table = ({
  items,
  tableSettings,
  handleSelect = () => {},
  handleRowClick = () => {},
  apiCall,
  loadingSkeleton,
}) => {
  const { t } = useTranslation();

  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [columnKeys, setColumnKeys] = useState([]);
  const [columns, setColumns] = useState([]);

  const [sort, setSort] = useState({ column: null, asc: true });
  const [showFilter, setShowFilter] = useState(null);
  const [filter, setFilter] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const [selectedItems, setSelectedItems] = useState([]);

  const [tableLoading, setTableLoading] = useState(true);
  const [additionalData, setAdditionalData] = useState({ items: [] });

  useEffect(() => {
    console.log(items);
    const tableData = items?.map((item) => {
      return { ...item, id: item._id ?? item.id };
    });
    setData(tableData);

    if (tableSettings?.columnsConfig) {
      setColumnKeys(Object.keys(tableSettings?.columnsConfig));
    } else {
      const keys = tableData?.reduce((acc, entry) => {
        const keys = Object.keys(entry);
        const newKeys = keys.filter((key) => !acc.includes(key));
        acc = acc.concat(newKeys);
        return acc;
      }, []);
      setColumnKeys(keys);
    }
    setTotalPages(Math.ceil(tableSettings?.totalItems / pageSize));
    setPageData(tableData?.slice(0, pageSize));
    setTotalItems(tableSettings?.totalItems);
  }, [items, t]);

  useEffect(() => {
    setColumns(
      columnKeys?.map((key) => {
        return {
          id: key,
          label: tableSettings?.columnsConfig?.[key]?.label ?? key,
          filter: {
            type: tableSettings?.listFilter?.includes(key)
              ? 'list'
              : tableSettings?.searchFilter?.includes(key)
                ? 'search'
                : null,
            performsApiCall: tableSettings?.filterPerformsApiCall?.includes(key),
          },
          sortable: tableSettings?.sortable?.includes(key),
        };
      }),
    );
  }, [columnKeys]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / pageSize));

    if (Math.ceil(totalItems / pageSize) < currentPage) {
      setPageData(data?.slice(0, pageSize));
      setCurrentPage(1);
    } else {
      setPageData(data.slice(pageSize * (currentPage - 1), pageSize * currentPage));
    }
    setTableLoading(false);
  }, [pageSize]);

  useEffect(() => {
    if (handleSelect) handleSelect(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (!tableLoading) triggerApiCall();
  }, [pageSize, currentPage, sort]);

  useEffect(() => {
    if (data?.length > 0) setTotalPages(Math.ceil(additionalData.totalItems / pageSize));
    setTotalItems(additionalData.totalItems);

    if (Math.ceil(additionalData.totalItems / pageSize) < currentPage) {
      setCurrentPage(1);
    }
    setPageData(
      additionalData.items.map((item) => {
        return { ...item, id: item._id ?? item.id };
      }),
    );
  }, [additionalData]);

  const triggerApiCall = async (tmpFilter = null) => {
    try {
      setTableLoading(true);
      const response = await apiCall(instance, {
        page: Math.ceil(additionalData.totalItems / pageSize) < currentPage ? 1 : currentPage,
        limit: pageSize,
        sort: sort?.column ? `${sort?.asc ? '' : '-'}${sort?.column}` : '',
        ...(tmpFilter || filter),
      });
      setAdditionalData(response);
      return response;
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    setSelectedItems((current) =>
      current?.filter((item) => items?.some((tableItem) => tableItem?.id === item?.id)),
    );
  }, [items]);

  return (
    <div className='opwellTableContainer'>
      {contextHolder}
      <div className='opwellTableButtons'>
        {Object.values(filter)?.filter((item) => item)?.length > 0 && (
          <Button
            className='cleanFilterButton'
            size='large'
            icon={<CloseCircleOutlined />}
            style={{
              borderColor: 'black',
              background: 'white',
              color: 'black',
            }}
            onClick={() => {
              setFilter({});
              triggerApiCall({});
            }}
          >
            {t('clean_filter')}
          </Button>
        )}
      </div>
      <div className='stickyWorkaround'>
        <div className='stickySpacer'></div>
        <table className='opwellTable'>
          <tr key={`row-title`} className='opwellTableRow title'>
            {handleSelect && (
              <td key={`cell-column-select-row-title`} className='opwellTableCell'>
                <Checkbox
                  checked={pageData?.some((pageItem) => {
                    return selectedItems.find((selectedItem) => selectedItem?.id === pageItem?.id);
                  })}
                  style={{ justifyContent: 'center' }}
                  label=''
                  onChange={() => {
                    if (!tableLoading)
                      if (
                        pageData?.every((pageItem) => {
                          return selectedItems.find(
                            (selectedItem) => selectedItem?.id === pageItem?.id,
                          );
                        })
                      ) {
                        setSelectedItems((current) =>
                          current.filter(
                            (selectedItem) =>
                              !pageData?.some((pageItem) => selectedItem?.id === pageItem?.id),
                          ),
                        );
                      } else
                        setSelectedItems((current) =>
                          current.concat(
                            pageData?.filter(
                              (pageItem) =>
                                !selectedItems.some(
                                  (selectedItem) => selectedItem?.id === pageItem?.id,
                                ),
                            ),
                          ),
                        );
                  }}
                />
              </td>
            )}
            {columns?.map((column, index) => {
              if (tableSettings?.columnsConfig?.[column.id])
                return (
                  <td key={`cell-column${index}-row-title`} className='opwellTableCell'>
                    <div className='opwellTableCellTitleContent'>
                      {column.label}
                      <div
                        key={`opwellTableCellControlls-column${index}-row-title`}
                        className='opwellTableCellControlls'
                      >
                        {column?.sortable && (
                          <div
                            key={`opwellTableCellControll-column${index}-sort`}
                            className='opwellTableCellControll'
                            onClick={() => {
                              if (!tableLoading)
                                setSort((current) => {
                                  if (current.column === column.id) {
                                    if (!current.asc) return { column: null, asc: true };
                                    else return { ...current, asc: !current.asc };
                                  } else {
                                    return { column: column.id, asc: true };
                                  }
                                });
                            }}
                          >
                            <img
                              src={
                                sort?.column === column.id
                                  ? sort.asc
                                    ? sortAsc
                                    : sortDsc
                                  : sortDefault
                              }
                            />
                          </div>
                        )}
                        {column?.filter?.type && (
                          <div
                            key={`opwellTableCellControll-column${index}-filter`}
                            id={`opwellTableCellControllFilter-${column.id}`}
                            className='opwellTableCellControll filter'
                            onClick={() => {
                              if (!tableLoading) setShowFilter(column.id);
                            }}
                          >
                            <img
                              src={
                                showFilter === column.id || filter[column.id]
                                  ? filterSelected
                                  : filterImg
                              }
                            />
                            {showFilter === column.id && (
                              <div
                                className={`opwellTableCellFilterWrapper ${isDivOutOfViewport(document.getElementById(`opwellTableCellControllFilter-${column.id}`), 400) ? 'openRight' : ''}`}
                                onMouseLeave={() => {
                                  setShowFilter(null);
                                }}
                              >
                                <div className={`opwellTableCellFilterContainer`}>
                                  {column?.filter?.type === 'search' ? (
                                    <div className='opwellTableCellFilter'>
                                      <div className='filterTitle'>
                                        {t('search_values')} <b>{column.label}</b>
                                      </div>
                                      <Input
                                        value={filter?.[column.id] ?? ''}
                                        placeholder={'Busque'}
                                        onChange={(e) => {
                                          if (e.target.value?.length > 0)
                                            setFilter((current) => ({
                                              ...current,
                                              [column.id]: e.target.value,
                                            }));
                                          else
                                            setFilter((current) => ({
                                              ...current,
                                              [column.id]: undefined,
                                            }));
                                        }}
                                      />
                                      <Button
                                        className={`filterButton ${!filter?.[column.id] ? 'disabled' : ''}`}
                                        size='large'
                                        disabled={!filter?.[column.id]}
                                        style={{
                                          borderColor: 'black',
                                          background: 'white',
                                          color: 'black',
                                        }}
                                        onClick={() => {
                                          setFilter((current) => {
                                            triggerApiCall({
                                              ...current,
                                              [column.id]: undefined,
                                            });
                                            return {
                                              ...current,
                                              [column.id]: undefined,
                                            };
                                          });
                                        }}
                                      >
                                        {t('clean')}
                                      </Button>
                                      <Button
                                        className={`filterButton ${!filter?.[column.id] ? 'disabled' : ''}`}
                                        size='large'
                                        disabled={!filter?.[column.id]}
                                        style={{
                                          borderColor: '#1777FF',
                                          background: '#1777FF',
                                          color: 'white',
                                        }}
                                        onClick={() => {
                                          setShowFilter(null);
                                          triggerApiCall();
                                        }}
                                      >
                                        {t('filter')}
                                      </Button>
                                    </div>
                                  ) : (
                                    <div className='opwellTableCellFilter'>
                                      <div className='filterTitle'>
                                        {t('choose_values')} <b>{column.label}</b>
                                      </div>
                                      <SingleSelect
                                        key={`listFilter-${column.id}-${filter?.[column.id]}`}
                                        value={
                                          filter?.[column.id] ? { id: filter?.[column.id] } : {}
                                        }
                                        options={tableSettings?.uniqueValues?.[column.id].map(
                                          (item) => {
                                            return {
                                              id: `${item}`,
                                              label: tableSettings?.columnsConfig?.[column.id]
                                                .replaceValue
                                                ? tableSettings?.columnsConfig?.[
                                                    column.id
                                                  ].replaceValue(item)?.label
                                                : tableSettings?.columnsConfig?.[column.id]
                                                      .customElement
                                                  ? tableSettings.columnsConfig[
                                                      column.id
                                                    ].customElement(item)
                                                  : item,
                                            };
                                          },
                                        )}
                                        onChange={(e) => {
                                          setFilter((current) => ({
                                            ...current,
                                            [column.id]: e.target.value,
                                          }));
                                        }}
                                      />
                                      <Button
                                        className={`filterButton ${!filter?.[column.id] ? 'disabled' : ''}`}
                                        size='large'
                                        disabled={!filter?.[column.id]}
                                        style={{
                                          borderColor: 'black',
                                          background: 'white',
                                          color: 'black',
                                        }}
                                        onClick={() => {
                                          setFilter((current) => {
                                            triggerApiCall({
                                              ...current,
                                              [column.id]: undefined,
                                            });
                                            return {
                                              ...current,
                                              [column.id]: undefined,
                                            };
                                          });
                                        }}
                                      >
                                        {t('clean')}
                                      </Button>
                                      <Button
                                        className={`filterButton ${!filter?.[column.id] ? 'disabled' : ''}`}
                                        size='large'
                                        disabled={!filter?.[column.id]}
                                        style={{
                                          borderColor: '#1777FF',
                                          background: '#1777FF',
                                          color: 'white',
                                        }}
                                        onClick={() => {
                                          setShowFilter(null);
                                          triggerApiCall();
                                        }}
                                      >
                                        {t('filter')}
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                );
            })}
          </tr>

          {tableLoading || loadingSkeleton
            ? [...Array(5).keys()].map((index) => (
                <tr key={`skeleton-row-${index}`} className='opwellTableRow loading'>
                  {handleSelect && (
                    <td key={`skeleton-select-${index}`} className='opwellTableCell'></td>
                  )}
                  {columns?.map((column, colIndex) => (
                    <td key={`skeleton-cell-${index}-${colIndex}`} className='opwellTableCell'>
                      <Skeleton active title={false} paragraph={{ rows: 1, width: '80%' }} />
                    </td>
                  ))}
                </tr>
              ))
            : pageData?.map((row, rowIndex) => {
                return (
                  <tr
                    key={`row-${rowIndex}-${row?.id}`}
                    className='opwellTableRow'
                    onClick={(e) => {
                      if (handleRowClick) handleRowClick(row);
                    }}
                  >
                    {handleSelect && (
                      <td
                        key={`cell-column-select-row-${rowIndex}`}
                        className='opwellTableCell'
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Checkbox
                          checked={selectedItems.some(
                            (selectedItem) => selectedItem?.id === row?.id,
                          )}
                          style={{ justifyContent: 'center' }}
                          label=''
                          onChange={() => {
                            if (
                              selectedItems.some((selectedItem) => selectedItem?.id === row?.id)
                            ) {
                              setSelectedItems((current) =>
                                current.filter((selectedItem) => selectedItem?.id !== row?.id),
                              );
                            } else {
                              setSelectedItems((current) => current.concat([row]));
                            }
                          }}
                        />
                      </td>
                    )}
                    {columns?.map((column, index) => {
                      if (tableSettings?.columnsConfig?.[column.id])
                        return (
                          <td
                            key={`cell-column${index}-row-${rowIndex}`}
                            className='opwellTableCell dataRow'
                            style={handleRowClick ? {} : { cursor: 'default' }}
                          >
                            {row[column.id] || row[column.id] == false
                              ? tableSettings?.columnsConfig?.[column.id]?.customElement
                                ? tableSettings?.columnsConfig?.[column.id]?.customElement(row)
                                : tableSettings?.columnsConfig?.[column.id]?.replaceValue
                                  ? tableSettings.columnsConfig[column.id].replaceValue(
                                      row[column.id],
                                    )?.label
                                  : row[column.id]
                              : '-'}
                          </td>
                        );
                    })}
                  </tr>
                );
              })}
        </table>
      </div>
      <div className='opwellTablePagination'>
        <div className='opwellTablePaginationControlls'>
          <div className='pageSizeSelector'>
            <div className='pageSizeSelectorText'>{t('items_per_page')}</div>
            <SingleSelect
              shouldOpenUp
              style={{ width: '5rem' }}
              value={{ id: 10 }}
              options={[
                { id: 5, label: '5' },
                { id: 10, label: '10' },
                { id: 20, label: '20' },
                { id: 50, label: '50' },
                { id: 100, label: '100' },
              ]}
              onChange={(e) => {
                setPageSize(e.target.value);
              }}
            />
          </div>
          <div>
            {tableLoading ? ' ' : (currentPage - 1) * pageSize} -{' '}
            {tableLoading ? ' ' : (currentPage - 1) * pageSize + pageData?.length} / {totalItems}
          </div>
          <div className='pageSelector'>
            <div
              className='pageSelectorArrow'
              onClick={() => {
                setCurrentPage((current) => (current > 1 ? current - 1 : current));
              }}
            >
              <img src={paginationLeft} />
            </div>
            <div
              className={`pageSelectorButton ${currentPage === 1 ? 'selected' : ''}`}
              onClick={() => {
                setCurrentPage(1);
              }}
            >
              1
            </div>
            {totalPages > 5 && currentPage >= 5 && (
              <div className={`pageSelectorEllipsis`}>...</div>
            )}
            {[...Array(Number.isFinite(totalPages) && totalPages > 0 ? totalPages : 1).keys()]
              ?.filter((page) => {
                return currentPage < 5
                  ? page + 1 <= 4 && page != 0
                  : currentPage > totalPages - 3
                    ? page + 1 >= totalPages - 3 && page + 1 !== totalPages
                    : page + 1 >= currentPage - 1 &&
                      page + 1 <= currentPage + 1 &&
                      page + 1 !== totalPages;
              })
              ?.map((page) => {
                return (
                  <div
                    className={`pageSelectorButton ${currentPage === page + 1 ? 'selected' : ''}`}
                    onClick={() => {
                      setCurrentPage(page + 1);
                    }}
                  >
                    {page + 1}
                  </div>
                );
              })}
            {totalPages > 5 && currentPage < totalPages - 2 && (
              <div className={`pageSelectorEllipsis`}>...</div>
            )}
            {totalPages >= 5 && (
              <div
                className={`pageSelectorButton ${currentPage === totalPages ? 'selected' : ''}`}
                onClick={() => {
                  setCurrentPage(totalPages);
                }}
              >
                {totalPages}
              </div>
            )}
            <div
              className='pageSelectorArrow'
              onClick={() => {
                setCurrentPage((current) => (current < totalPages ? current + 1 : current));
              }}
            >
              <img src={paginationRight} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
