import React from 'react';

import './style.css';
import { Col, Row } from 'antd';
import ListContent from './ListContent';
import ListEvidences from './ListEvidences';
import Signature from './Signature';

export default function ReportContent({ task, editMode, latestVersion, version, interconnection }) {
  const formattedDate = (date) => {
    if (date)
      return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    return null;
  };

  return (
    <div id='report-content'>
      <div className={'report-table'}>
        <div className={'report-table-title'}>
          <p
            style={{
              margin: '0 0 0 10px',
              padding: '2px 0 2px 0',
              color: 'white',
              fontWeight: '500',
            }}
          >
            Informações Gerais
          </p>
        </div>
        <div className={'report-table-content'}>
          <Row>
            <Col span={6}>
              <div className={'report-table-item'}>
                <p
                  style={{
                    margin: '0 0 0 10px',
                    padding: '2px 0 2px 0',
                    color: '#225968',
                    fontWeight: '500',
                  }}
                >
                  Contratado:
                </p>
              </div>
            </Col>
            <Col span={6}>
              <div className={'report-table-item'}>
                <p
                  style={{
                    margin: '0 0 0 10px',
                    padding: '2px 0 2px 0',
                    color: 'black',
                    fontWeight: '500',
                  }}
                >
                  {task?.employee?.company_name}
                </p>
              </div>
            </Col>
            <Col span={6}>
              <div className={'report-table-item'}>
                <p
                  style={{
                    margin: '0 0 0 10px',
                    padding: '2px 0 2px 0',
                    color: '#225968',
                    fontWeight: '500',
                  }}
                >
                  Data:
                </p>
              </div>
            </Col>
            <Col span={6}>
              <div className={'report-table-item-side'}>
                <p
                  style={{
                    margin: '0 0 0 10px',
                    padding: '2px 0 2px 0',
                    color: 'black',
                    fontWeight: '500',
                  }}
                >
                  {task?.report.updated_at
                    ? formattedDate(new Date(task?.report.updated_at))
                    : 'N/A'}
                </p>
              </div>
            </Col>
            <Col span={6}>
              <div className={'report-table-item'}>
                <p
                  style={{
                    margin: '0 0 0 10px',
                    padding: '2px 0 2px 0',
                    color: '#225968',
                    fontWeight: '500',
                  }}
                >
                  Nome do Site:
                </p>
              </div>
            </Col>
            <Col span={6}>
              <div className={'report-table-item'}>
                <p
                  style={{
                    margin: '0 0 0 10px',
                    padding: '2px 0 2px 0',
                    color: 'black',
                    fontWeight: '500',
                  }}
                >
                  {interconnection?.system?.name ?? 'N/A'}
                </p>
              </div>
            </Col>
            <Col span={6}>
              <div className={'report-table-item'}>
                <p
                  style={{
                    margin: '0 0 0 10px',
                    padding: '2px 0 2px 0',
                    color: '#225968',
                    fontWeight: '500',
                  }}
                >
                  Nome do Cabo:
                </p>
              </div>
            </Col>
            <Col span={6}>
              <div className={'report-table-item-side'}>
                <p
                  style={{
                    margin: '0 0 0 10px',
                    padding: '2px 0 2px 0',
                    color: 'black',
                    fontWeight: '500',
                  }}
                >
                  {task?.interconnection?.name ?? 'N/A'}
                </p>
              </div>
            </Col>
            <Col span={6}>
              <div className={'report-table-item-bottom'}>
                <p
                  style={{
                    margin: '0 0 0 10px',
                    padding: '2px 0 2px 0',
                    color: '#225968',
                    fontWeight: '500',
                  }}
                >
                  Nº Relatório:
                </p>
              </div>
            </Col>
            <Col span={6}>
              <div className={'report-table-item-bottom'}>
                <p
                  style={{
                    margin: '0 0 0 10px',
                    padding: '2px 0 2px 0',
                    color: 'black',
                    fontWeight: '500',
                  }}
                >
                  {task?.commissioning_work_order_token
                    ? task.commissioning_work_order_token
                    : 'N/A'}
                </p>
              </div>
            </Col>
            <Col span={6}>
              <div className={'report-table-item-bottom'}>
                <p
                  style={{
                    margin: '0 0 0 10px',
                    padding: '2px 0 2px 0',
                    color: '#225968',
                    fontWeight: '500',
                  }}
                >
                  Tentativas:
                </p>
              </div>
            </Col>
            <Col span={6}>
              <div className={'report-table-item-bottom-side'}>
                <p
                  style={{
                    margin: '0 0 0 10px',
                    padding: '2px 0 2px 0',
                    color: 'black',
                    fontWeight: '500',
                  }}
                >
                  {task?.attempt}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {task?.phase?.map((taskPhase) => (
        <>
          <ListContent
            instrument={task?.instrument}
            phase={taskPhase}
            editMode={editMode}
            latestVersion={latestVersion}
            version={version}
          />
        </>
      ))}
      <ListEvidences task={task} />
      <Signature task={task} />
    </div>
  );
}
