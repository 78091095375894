import React, { useState, useEffect } from 'react';
import Table from '../../../Template/layoutComponents/Table';
import { message, Button } from 'antd';
import { DeleteTwoTone, UserOutlined } from '@ant-design/icons';
import PageHeader from '../../../Maintenance/layoutComponents/PageHeader';
import useCrudApi from '../../../Maintenance/hooks/useCrudApi';
import PermissionTag from '../../../Maintenance/components/PermissionTag';
import RoleModal from './UsersModal';
import DeleteModal from '../../../Maintenance/components/DeleteModal/index';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import useTemplateState from '../../../Template/atoms/variables';

export default function Roles() {
  const { t } = useTranslation();
  const element = 'roles';
  const { getAllItems, deleteItem, loading } = useCrudApi(element);
  const [roles, setRoles] = useState(null);
  const [roleModalVisible, setRoleModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [uniqueValues, setUniqueValues] = useState(null);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const sessionRole = 'sessionRole';
  const { siderVisible } = useTemplateState();

  const apiCall = async (params) => {
    const result = await getAllItems(params);
    return {
      items: result.items.map((role) => ({
        ...role,
        permissions: role.permissions || [],
      })),
      totalItems: result.total,
    };
  };

  useEffect(() => {
    const fetchRoles = async () => {
      const currentRoles = await getAllItems();
      if (currentRoles) {
        setSelectedKeys([]);
        setRoles(currentRoles.items);
        setUniqueValues(currentRoles.uniqueValues);
      }
    };
    if (!roles) fetchRoles();
  }, [roles]);

  const handleDeleteClick = async () => {
    if (!selectedKeys.length) return;
    try {
      setDeleting(true);
      await Promise.all(selectedKeys.map((item) => deleteItem(item._id)));
      message.success(t('messages.deleteSuccess'));
      if (selectedKeys.find((item) => item?.email === account?.username)) {
        sessionStorage.removeItem(sessionRole);
      }
      setSelectedKeys([]);
      setRoles(null);
    } catch (error) {
      message.error(t('messages.deleteError'));
    } finally {
      setDeleting(false);
    }
  };

  const handleCloseModal = () => {
    setRoleModalVisible(false);
  };

  const handleFinish = () => {
    setRoleModalVisible(false);
    setRoles(null);
  };

  const tableSettings = {
    columnsConfig: {
      full_name: { label: t('table.fullName'), sortable: true },
      email: { label: 'Email', sortable: true },
      company: { label: t('table.company') },
      job: { label: t('table.job') },
      permissions: {
        label: t('table.permissions'),
        customElement: (row) =>
          row.permissions.map((permission) => (
            <PermissionTag key={permission} permission={permission} />
          )),
      },
    },
    sortable: ['full_name', 'email'],
    searchFilter: ['email', 'full_name'],
    listFilter: ['job', 'company'],
    uniqueValues: uniqueValues,
    totalItems: roles?.length || 0,
  };

  return (
    <div>
      <PageHeader
        keyElement='roles'
        buttons={[
          {
            name: t('buttons.newUser'),
            visible: true,
            function: () => setRoleModalVisible(true),
            icon: <UserOutlined />,
          },
        ]}
      />
      <>
        {roleModalVisible && (
          <RoleModal
            roles={roles}
            visible={roleModalVisible}
            handleClose={handleCloseModal}
            handleCancel={handleCloseModal}
            handleFinish={handleFinish}
          />
        )}
        {deleteModalVisible && (
          <DeleteModal
            loading={deleting}
            visible={deleteModalVisible}
            onCancel={() => setDeleteModalVisible(false)}
            onDelete={async () => {
              await handleDeleteClick();
              setDeleteModalVisible(false);
            }}
          />
        )}
        <div style={{ width: !siderVisible ? '75vw' : '90vw', margin: '0 auto' }}>
          <div style={{ display: 'flex', justifyContent: 'start', margin: '0 0 10px 8px' }}>
            <Button
              icon={<DeleteTwoTone twoToneColor='#FF4D4F' />}
              onClick={() => setDeleteModalVisible(true)}
              disabled={!selectedKeys.length}
              style={{
                opacity: !selectedKeys.length ? 0.5 : 1,
              }}
            />
          </div>
          <Table
            items={roles}
            tableSettings={tableSettings}
            apiCall={apiCall}
            handleSelect={(selectedItems) => {
              setSelectedKeys(selectedItems);
            }}
            handleRowClick={(row) => console.log(t('messages.rowClicked'), row)}
            loadingSkeleton={loading}
          />
        </div>
      </>
    </div>
  );
}
