import React from 'react';
import { Form, message, Modal, Switch } from 'antd';
import CustomForm from '../../../../Maintenance/components/CustomForm';
import useCrudApi from '../../../../Maintenance/hooks/useCrudApi';
import { formatEmail } from '../../../../Maintenance/helpers/validations';
import { useMsal } from '@azure/msal-react';

const RoleModal = ({ roles, visible, handleClose, handleCancel, handleFinish }) => {
  const element = 'roles';
  const { createItem, creating } = useCrudApi(element);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const sessionRole = 'sessionRole';

  const checkExistingAndValidEmail = async (_, value) => {
    await formatEmail(_, value);
    const existingEmail = roles.find((role) => role.email === value);
    if (existingEmail)
      return Promise.reject('Email já cadastrado! Remove ou adicione outro email.');
    return Promise.resolve();
  };

  const listPermissions = [
    { _id: 0, key: 'admin', name: 'admin' },
    { _id: 1, key: 'planner', name: 'planner' },
    { _id: 2, key: 'management', name: 'management' },
    { _id: 3, key: 'quality', name: 'quality' },
  ];
  const [form] = Form.useForm();
  let formFields = [
    {
      label: 'Nome completo',
      key: 'full_name',
      type: 'input',
      multiple: false,
      required: true,
    },
    {
      label: 'Email',
      key: 'email',
      type: 'input',
      multiple: false,
      required: true,
      placeholder: 'Ex: exemplo@domain.com',
      validateFunction: checkExistingAndValidEmail,
    },
    {
      label: 'Empresa',
      key: 'company',
      type: 'input',
      multiple: false,
      required: true,
    },
    {
      label: 'Cargo',
      key: 'job',
      type: 'input',
      multiple: false,
      required: true,
    },
    {
      label: 'Tags de permissão',
      key: 'permissions',
      type: 'select',
      selectItem: 'name',
      selectKey: 'name',
      multiple: false,
      required: true,
      items: listPermissions,
    },
  ];

  const handleSubmit = async (values) => {
    values.permissions = [values.permissions];
    values.permissions.forEach((_, index) => {
      const perm = listPermissions.find(
        (permission) => permission._id === values.permissions[index],
      );
      values.permissions[index] = perm.key;
    });
    const new_role = await createItem(values);
    if (new_role) {
      message.success(`Permissões para o email ${values.email} criados com sucesso!`);
      if (new_role.email === account?.username)
        sessionStorage.setItem(sessionRole, JSON.stringify(new_role));
      handleFinish();
    } else message.error(`Houve algum problema ao criar permissões para o email ${values.email}!`);
  };

  const handleSwitchChange = (autofill) => {
    if (autofill) {
      const { given_name: givenName, family_name: familyName, name } = account.idTokenClaims || {};
      const full_name = name ?? `${givenName} ${familyName}`;
      const email = account.username;
      form.setFieldValue('full_name', full_name);
      form.setFieldValue('email', email);
    } else {
      form.resetFields();
    }
  };

  return (
    <Modal
      title={'Adicionar permissões para novo email'}
      open={visible}
      footer={null}
      width={'68vw'}
      onClose={() => handleClose()}
      onCancel={() => handleCancel()}
    >
      <Switch checkedChildren='Autofill' unCheckedChildren='Manual' onChange={handleSwitchChange} />
      <CustomForm
        form={form}
        fields={formFields}
        creating={creating}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
      />
    </Modal>
  );
};

export default RoleModal;
