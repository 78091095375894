import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const LoadingComponent = ({ loading }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '34vh',
      }}
    >
      {loading && (
        <div>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          <p>Aguarde...</p>
        </div>
      )}
    </div>
  );
};

export default LoadingComponent;
