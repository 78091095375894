import React, { useState } from 'react';
import { Layout } from 'antd';
import ResizableHandle from './components/ResizableSider/index';
import logo from '../../images/logo.svg';
import logoCollapsed from '../../images/logoCollapsed.svg';
import collapse from '../../images/collapse.svg';
import './style.css';

import SiderMenu from './components/SiderMenu';
import useTemplateState from '../../atoms/variables';
import { Footer } from 'antd/es/layout/layout';

const { Sider } = Layout;

export default function SiderComponent() {
  const { companyInfo, siderVisible, setSiderVisible } = useTemplateState();

  const [collapsed, setCollapsed] = useState(false);
  const [width, setWidth] = useState(300);

  const isMobile = window.screen.width < 980;

  const toggleCollapse = () => {
    if (window.screen.width >= 980) {
      setSiderVisible(!siderVisible);
      setCollapsed(!collapsed);
    }
  };

  const handleResize = (newWidth) => {
    if (newWidth >= 200 && newWidth <= 300) {
      setWidth(newWidth);
    } else if (newWidth < 200) {
      toggleCollapse();
    }
  };

  return (
    !isMobile && (
      <div style={{ display: 'flex' }}>
        <Sider
          id='sider'
          width={width}
          collapsible
          collapsed={collapsed}
          trigger={null}
          className={collapsed ? 'collapsed' : ''}
        >
          <div className='head'>
            <img
              src={companyInfo ? (companyInfo?.logo?.length > 0 ? companyInfo?.logo : logo) : logo}
              className={'logo'}
              alt='Logo'
            />
          </div>
          <SiderMenu collapsed={collapsed} setCollapsed={setCollapsed} />
          <div className={`bottom ${collapsed ? 'collapsed' : 'opened'}`} onClick={toggleCollapse}>
            <img style={collapsed ? { transform: 'scale(-1,1)' } : {}} src={collapse} alt='Logo' />
          </div>
        </Sider>
        <ResizableHandle onResize={handleResize} />{' '}
      </div>
    )
  );
}
