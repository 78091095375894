import React from 'react';
import { Modal, Button, Typography } from 'antd';

const DeleteModal = ({ visible, onCancel, onDelete, loading }) => {
  return (
    <Modal
      title={
        <Typography.Title level={5} style={{ color: 'red', marginTop: 0 }}>
          Você tem certeza desta ação?
        </Typography.Title>
      }
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={'30vw'}
      centered
    >
      <Typography.Text>
        Esta ação não poderá ser desfeita e apagará permanentemente sua seleção.
      </Typography.Text>
      <div style={{ textAlign: 'right', marginTop: '10vh' }}>
        <Button type='primary' loading={loading} danger onClick={onDelete}>
          Excluir mesmo assim
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
