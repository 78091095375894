import React from 'react';
import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { ClearOutlined } from '@ant-design/icons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { message } from 'antd';

import CustomTable from '../../../components/CustomTable';
import AddTaskModal from '../../../components/TaskModal';
import WorkOrderModal from '../../../components/TaskModal/WorkOrderModal';

import useCrudApi from '../../../hooks/useCrudApi';
import elementsConfig from '../../../helpers/elementsConfig';

import {
  mapStatusColumn,
  mapOriginColumn,
  mapDestinyColumn,
  mapNameColumn,
  mapOriginColumnByInput,
  mapDestinyColumnByInput,
  mapRequiredTasksColumn,
  mapCategoryColumn,
} from './helpers/filters';

import {
  saveSessionSelections,
  restoreSessionSelections,
  clearSessionSelections,
  updateSessionSelections,
} from './helpers/sessionStorageHelpers';

import './style.css';

import PageHeader from '../../../layoutComponents/PageHeader';
import PageContent from '../../../layoutComponents/PageContent';

const Interconnections = () => {
  const element = 'interconnections';
  const [interconnections, setInterconnections] = useState([]);
  const [categories, setCategories] = useState([]);
  const { plantId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [filtersState, setFiltersState] = useState({});
  const [sorterState, setSorterState] = useState({});
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(searchParams.get('page') || 1);
  const [limit, setLimit] = useState(searchParams.get('limit') || 10);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isWoModalVisible, setIsWoModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { deleteItem, creating, getItems } = useCrudApi(element);
  const creatingWo = useCrudApi('work-order');
  const getCategories = useCrudApi('equipment_category').getItems;

  const hasSorterOrFilter = useMemo(() => {
    const hasFilter = Object.keys(filtersState).some((key) => filtersState[key]?.length > 0);
    const hasSorter = !!sorterState.field;
    return hasFilter || hasSorter;
  }, [sorterState, filtersState, searchParams]);

  useEffect(() => {
    loadAllOptions();
  }, []);

  useEffect(() => {
    const { selectedKeys, selectedRows } = restoreSessionSelections();
    setSelectedKeys(selectedKeys);
    setSelectedRows(selectedRows);
  }, [page]);

  useEffect(() => {
    initializeParams();
  }, [searchParams]);

  const loadAllOptions = async () => {
    try {
      const [fetchedInterconnections, fetchedCategories] = await Promise.all([
        getItems(page, limit, { system: plantId }),
        getCategories(),
      ]);
      if (fetchedInterconnections) {
        setPage(fetchedInterconnections.page);
        setLimit(fetchedInterconnections.limit);
        setTotal(fetchedInterconnections.totalItems);
        setInterconnections(fetchedInterconnections.items);
      }
      if (fetchedCategories) setCategories(fetchedCategories.items);
    } catch (error) {
      console.error('Erro ao carregar opções de filtros:', error);
    }
  };

  const loadData = useCallback(
    async (
      filters = filtersState,
      sorter = sorterState,
      currentPage = page,
      currentLimit = limit,
    ) => {
      // if (!isInitialized) return;
      setLoading(true);
      try {
        const filterParams = mapFilters(plantId, filters);
        const sortParams = getSortParams(sorter);

        const [fetchedInterconnections, fetchedCategories] = await Promise.all([
          getItems(page, limit, filterParams, sortParams),
          getCategories(),
        ]);

        setInterconnections(fetchedInterconnections?.items || []);
        setTotal(fetchedInterconnections?.totalItems || 1);
        setCategories(fetchedCategories.items || []);
      } catch (error) {
        console.error('Erro ao carregar os dados:', error);
        setInterconnections([]);
        setTotal(1);
      } finally {
        setLoading(false);
      }
    },
    [filtersState, sorterState, page, limit, isWoModalVisible, getItems, getCategories],
  );

  const mapFilters = (plantId, filters) => ({
    system: { $eq: plantId },
    category: filters.category?.[0],
    status: filters.status?.[0],
    origin: filters['properties.origin']?.[0],
    destiny: filters['properties.destiny']?.[0],
    name: filters.name?.[0],
    commissioning_tasks: filters['commissioning_tasks'] && {
      $all: filters['commissioning_tasks'].map((task) => {
        return {
          $elemMatch: {
            task: task,
            status: 'Required',
          },
        };
      }),
    },
  });

  const getSortParams = () => {
    const sortField = searchParams.get('sortField');
    const sortOrder = searchParams.get('sortOrder');
    return sortField ? { [sortField]: sortOrder === 'ascend' ? 1 : -1 } : {};
  };

  const initializeParams = () => {
    const filters = getFiltersFromSearchParams();
    const sortField = searchParams.get('sortField');
    const sortOrder = searchParams.get('sortOrder');
    const initialPage = parseInt(searchParams.get('page'), 10) || 1;
    const initialLimit = parseInt(searchParams.get('limit'), 10) || 10;

    setFiltersState(filters);
    setPage(initialPage);
    setLimit(initialLimit);

    if (sortField && sortOrder) {
      setSorterState({ field: sortField, order: sortOrder });
    }
    loadData(filters, { field: sortField, order: sortOrder }, initialPage, initialLimit);
  };

  const getFiltersFromSearchParams = () => {
    const filters = {};
    searchParams.forEach((value, key) => {
      if (key.startsWith('filter_')) {
        filters[key.replace('filter_', '')] = [value];
      }
    });
    return filters;
  };

  const deleteBatchInterconnections = () => {
    setInterconnections(null);
    const params = new URLSearchParams(searchParams);
    params.set('page', page <= 1 ? 1 : page - 1);
    params.set('limit', limit);
    navigate(`/maintenance/plants/${plantId}/interconnections?${params.toString()}`);
    navigate(0);
  };

  const handleDeleteClick = async () => {
    if (!selectedKeys.length) return;

    try {
      await Promise.all(selectedKeys.map((id) => deleteItem(id)));
      message.success('Itens excluídos com sucesso');
      if (selectedKeys.length === interconnections.length) {
        if (page && page !== 1) {
          deleteBatchInterconnections();
        }
      } else {
        setInterconnections((prev) => prev.filter((item) => !selectedKeys.includes(item._id)));
      }
      clearSelections();
    } catch (error) {
      message.error('Erro ao excluir os itens');
    }
  };

  const clearSelections = () => {
    setSelectedKeys([]);
    setSelectedRows([]);
    clearSessionSelections();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);

    const updatedFilters = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        updatedFilters[key] = filters[key];
      }
    });
    setFiltersState(updatedFilters);

    const order = sorter?.order || null;
    setSorterState({ field: sorter?.field, order });
    updateSearchParams(
      updatedFilters,
      { field: sorter?.field, order: sorter?.order || null },
      pagination.current,
      pagination.pageSize,
    );
  };

  const updateSearchParams = useCallback(
    (filters, sorter, currentPage, pageSize) => {
      const params = new URLSearchParams(searchParams);

      Object.keys(filters).forEach((key) => {
        if (filters[key] && filters[key].length > 0) {
          params.set(`filter_${key}`, filters[key][0]);
        } else {
          params.delete(`filter_${key}`);
        }
      });

      if (sorter.field) {
        if (sorter.order) {
          params.set('sortField', sorter.field);
          params.set('sortOrder', sorter.order);
        } else {
          params.delete('sortField');
          params.delete('sortOrder');
          setSorterState({});
          setFiltersState({});
        }
      }

      params.set('page', currentPage);
      params.set('limit', pageSize);
      navigate(`/maintenance/plants/${plantId}/interconnections?${params.toString()}`, {
        replace: true,
      });
    },
    [searchParams, navigate, page, limit],
  );

  const handleClearFilters = () => {
    setFiltersState({});
    setSorterState({});
    setPage(1);
    setInterconnections(null);

    const params = new URLSearchParams();
    params.set('page', 1);
    params.set('limit', limit);

    navigate(`/maintenance/plants/${plantId}/interconnections?${params.toString()}`);
  };

  const handleFinish = () => {
    setIsWoModalVisible(false);
    loadData();
  };

  const handleSubmit = async (values) => {
    try {
      loadData();
    } catch (error) {
      message.error('Erro ao enviar o formulário');
    }
  };

  const modifiedColumns = elementsConfig[element].tableColumns.map((column) => {
    if (column.dataIndex === 'category') {
      return mapCategoryColumn(
        column,
        categories.length > 0 ? categories : [],
        sorterState,
        filtersState,
      );
    }
    if (column.dataIndex === 'status') return mapStatusColumn(column, sorterState, filtersState);
    if (column.dataIndex === 'properties.origin')
      return mapOriginColumnByInput(column, sorterState, filtersState);
    if (column.dataIndex === 'properties.destiny')
      return mapDestinyColumnByInput(column, sorterState, filtersState);
    if (column.dataIndex === 'name') return mapNameColumn(column, sorterState, filtersState);
    if (column.dataIndex === 'details') return column;
    if (column.dataIndex === 'commissioning_tasks.task')
      return mapRequiredTasksColumn(column, sorterState, filtersState);

    return {
      ...column,
      render: (text, record) => {
        const value = typeof text === 'object' ? JSON.stringify(text) : text;
        return <span>{value}</span>;
      },
    };
  });

  return (
    <>
      <PageHeader
        buttons={[
          { name: 'Nova Interconexão', function: setIsAddModalVisible, visible: true },
          {
            name: 'Gerar Ordem de Serviço',
            function: setIsWoModalVisible,
            visible:
              selectedRows &&
              selectedRows.length > 0 &&
              selectedRows.every((row) => row.status === 'Pending' || row.status === 'Scheduled'),
          },
          {
            name: 'Limpar Filtros',
            function: handleClearFilters,
            visible: hasSorterOrFilter,
            disabled: false,
            design: 'default',
            icon: <ClearOutlined />,
          },
        ]}
      />
      <PageContent
        Content={() => (
          <>
            <CustomTable
              unlocker
              items={interconnections}
              columns={modifiedColumns}
              selectedRowKeys={selectedKeys}
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedKeys}
              setEquipments={setInterconnections}
              setSelectedRows={setSelectedRows}
              pagination={{ current: page, pageSize: limit, total, showSizeChanger: true }}
              onDelete={handleDeleteClick}
              onPageChange={handleTableChange}
              loading={loading}
              updateSessionStorage={updateSessionSelections}
            />
            {isAddModalVisible && (
              <AddTaskModal
                visible={isAddModalVisible}
                onOk={() => setIsAddModalVisible(false)}
                onCancel={() => setIsAddModalVisible(false)}
                loading={creating}
                setEquipments={setInterconnections}
                loadData={loadData}
                handleSubmit={handleSubmit}
              />
            )}

            {isWoModalVisible && (
              <WorkOrderModal
                visible={isWoModalVisible}
                handleCancel={() => setIsWoModalVisible(false)}
                handleFinish={handleFinish}
                loading={creatingWo}
                preSelectedEquipments={selectedRows}
              />
            )}
          </>
        )}
      />
    </>
  );
};

export default Interconnections;
