import React, { useEffect, useState } from 'react';
import Table from '../../../../../../../../Template/layoutComponents/Table';
import { notification, Button } from 'antd';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { deleteFile, getFiles, saveFile } from '../../../../../../../helpers/files';
import { getAssetCategoryById } from '../../../../../../../helpers/apiCalls';
import useTemplateState from '../../../../../../../../Template/atoms/variables';
import { getTableConfig } from '../../helpers/tableConfigHelper';
import moment from 'moment';

const L1Component = ({
  asset,
  type,
  setStepProgress,
  onNextStep,
  setMandatoryCompletion,
  isLocked,
}) => {
  const { instance } = useMsal();
  const { i18n, t } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [itemsByLanguage, setItemsByLanguage] = useState({});
  const [allMandatoryUploaded, setAllMandatoryUploaded] = useState(false);
  const { notificationApi, contextHolder } = notification.useNotification();
  const [tableLoading, setTableLoading] = useState(true);
  const { setPendingApiCalls } = useTemplateState();

  useEffect(() => {
    if (asset) {
      fetchData();
    }
  }, [asset]);

  const handleError = (message, description) => {
    notificationApi?.error({ message, description, duration: 5 });
  };

  const fetchData = async () => {
    setTableLoading(true);
    try {
      await fetchL1Items();
      await fetchDocuments();
    } finally {
      setTableLoading(false);
    }
  };

  const fetchL1Items = async () => {
    const category = await fetchCategory(asset?.asset_category);
    if (!category) return;

    const phase = fetchPhase(category, 'L1');
    if (!phase) return;

    const languages = ['pt', 'en', 'es'];
    setItemsByLanguage(buildItemsByLanguage(phase, languages));
  };

  const fetchCategory = async (assetCategoryId) => {
    try {
      const category = await getAssetCategoryById(instance, assetCategoryId);
      if (!category?.commissioning) {
        handleError('Commissioning data not found', 'No commissioning data found.');
        return null;
      }
      return category;
    } catch (err) {
      handleError('Failed to fetch category', err?.message);
      return null;
    }
  };

  const fetchPhase = (category, phaseName) => {
    const phase = category?.commissioning?.phases?.find((p) => p.name === phaseName);
    if (!phase) {
      handleError('Phase not found', `Phase ${phaseName} is missing in commissioning data.`);
      return null;
    }
    return phase;
  };

  const buildItemsByLanguage = (phase, languages) => {
    return languages.reduce((items, lang) => {
      const stage = phase.stages.find(
        (s) => s[lang]?.platform === 'web' && s[lang]?.key === 'file-upload',
      );
      items[lang] = stage
        ? stage[lang]?.plan?.steps[0]?.items.map((item) => ({
            id: item._id,
            documentType: item.type,
            mandatoryItem: item.mandatory,
          })) || []
        : [];
      return items;
    }, {});
  };

  const fetchDocuments = async () => {
    try {
      const response = await getFiles(instance, type, asset._id, true);
      setDocuments(
        response.map((doc) => ({
          id: doc.name,
          documentName: doc.name.split('/').pop() || '-',
          uploadedBy: doc.metadata?.userid || '-',
          dateTime: doc.lastModified ? moment(doc.lastModified).format('DD/MM/YYYY HH:mm') : '-',
        })),
      );
    } catch (err) {
      handleError('Failed to fetch documents', err?.message);
    }
  };

  const deleteDocument = async (row) => {
    const matchingDocument = documents.find((doc) =>
      doc.documentName.startsWith(`index_${row.index}_`),
    );
    if (!matchingDocument) {
      handleError('Document not found', 'No matching document found.');
      return;
    }

    setPendingApiCalls((prev) => [...prev, 'deleteFile']);
    try {
      await deleteFile(instance, type, asset._id, matchingDocument.id);
      await fetchDocuments();
    } catch (err) {
      handleError('Failed to delete document', err.message);
    } finally {
      setPendingApiCalls((prev) => prev.filter((call) => call !== 'deleteFile'));
    }
  };

  const getItemsByLanguage = () => itemsByLanguage[i18n.language] || [];

  const getUploadedItems = (items) =>
    items.filter((item, index) =>
      documents.some((doc) => doc.documentName.startsWith(`index_${index}_`)),
    );

  const getMandatoryItems = (items) => items.filter((item) => item.mandatoryItem);

  const calculateProgress = () => {
    const items = getMandatoryItems(getItemsByLanguage());
    const totalMandatoryItems = items.length;
    const uploadedMandatoryItems = getUploadedItems(items).length;
    setAllMandatoryUploaded(uploadedMandatoryItems === totalMandatoryItems);
    return totalMandatoryItems > 0
      ? Math.round((uploadedMandatoryItems / totalMandatoryItems) * 100)
      : 0;
  };

  useEffect(() => {
    setStepProgress({ L1: calculateProgress() });
    setMandatoryCompletion((prev) => ({
      ...prev,
      L1:
        getMandatoryItems(getItemsByLanguage()).length ===
        getUploadedItems(getMandatoryItems(getItemsByLanguage())).length,
    }));
  }, [documents, itemsByLanguage]);

  const mergeDataForTable = () => {
    const items = getItemsByLanguage();
    return items.map((item, index) => {
      const matchingDocument = documents.find((doc) =>
        doc.documentName.startsWith(`index_${index}_`),
      );

      return {
        ...item,
        index,
        documentName: matchingDocument?.documentName.split('_').slice(2).join('_') || '-',
        uploadedBy: matchingDocument?.uploadedBy || '-',
        dateTime: matchingDocument?.dateTime || '-',
        manage: 'placeholder',
      };
    });
  };

  const tableSettings = getTableConfig({
    t,
    isLocked,
    deleteDocument,
    fetchDocuments,
    asset,
    type,
    saveFile,
  });

  return (
    <div>
      {contextHolder}
      <Table
        items={mergeDataForTable()}
        handleSelect={null}
        handleRowClick={null}
        tableSettings={{
          ...tableSettings,
          totalItems: mergeDataForTable().length,
        }}
        apiCall={async () => ({
          items: mergeDataForTable(),
          totalItems: mergeDataForTable().length,
        })}
        loadingSkeleton={tableLoading}
      />
      <div style={{ textAlign: 'right', marginTop: '40px' }}>
        <Button
          type='primary'
          disabled={!allMandatoryUploaded}
          onClick={onNextStep}
          style={{ padding: '20px 40px' }}
        >
          {t('next')}
        </Button>
      </div>
    </div>
  );
};

export default L1Component;
