import React, { useEffect, useState } from 'react';
import { Table, Checkbox, Button, message } from 'antd';
import { CheckCircleTwoTone, ClockCircleTwoTone } from '@ant-design/icons';

import useCrudApi from '../../../../hooks/useCrudApi';

export default function PendingTasksChecklist({
  pendingTasks,
  workOrder,
  setWorkOrder,
  setComponent,
}) {
  const { creating, createItem } = useCrudApi('work-order');
  const [items, setItems] = useState(null);
  const [equipmentList, setEquipmentList] = useState([]);
  const [columns, setColumns] = useState([]);

  function handleRowCheck(checked, instrument_id, equipment_id) {
    let newEquipmentList = equipmentList;
    let item = newEquipmentList.find((item) => item.interconnection === equipment_id);
    if (checked) item.instruments.push(instrument_id);
    else item.instruments = item.instruments.filter((i) => i !== instrument_id);
    setEquipmentList(newEquipmentList);
  }

  function renderColumnData(record, instrument_id) {
    if (record[instrument_id].pending == null) return 'N/A';
    if (record[instrument_id].scheduled) return <ClockCircleTwoTone />;
    return record[instrument_id].pending ? (
      <Checkbox
        onChange={(e) =>
          handleRowCheck(e.target.checked, instrument_id, record.interconnection._id)
        }
      />
    ) : (
      <CheckCircleTwoTone twoToneColor='#52c41a' />
    );
  }

  function formatTableData() {
    let instruments = {};
    for (let item of pendingTasks) {
      item.tests.map((test) => {
        instruments[test.instrument._id] = test.instrument.name;
      });
    }
    formatTableColumns(instruments);
    formatTableRows(instruments);
  }

  function formatTableColumns(instruments) {
    let columns = [
      {
        title: 'Interconexão',
        dataIndex: 'interconnection',
        key: 'interconnection',
        render: (_, record) => record.interconnection?.name,
      },
    ];
    for (let instrument_id of Object.keys(instruments))
      columns.push({
        title: instruments[instrument_id],
        dataIndex: instrument_id,
        key: instrument_id,
        render: (_, record) => renderColumnData(record, instrument_id),
      });
    setColumns(columns);
  }
  function formatTableRows(instruments) {
    let rows = [];

    for (let item of pendingTasks) {
      let row = { interconnection: item.interconnection };
      for (let instrument of Object.keys(instruments)) {
        let test = item.tests.find((t) => t.instrument._id == instrument);
        row[instrument] = { pending: test?.pending, scheduled: test?.scheduled };
      }
      rows.push(row);
    }
    setItems(rows);
  }

  async function handleFinish() {
    if (equipmentList.some((item) => item.instruments.length === 0)) {
      message.error('Deve ser escolhido pelo menos um teste por equipamento.');
      return;
    }
    let finalWorkOrder = {
      ...workOrder,
      interconnection_list: equipmentList,
    };
    finalWorkOrder = await createItem(finalWorkOrder);
    setWorkOrder(finalWorkOrder);
    setComponent('token');
  }

  useEffect(() => {
    if (!Array.isArray(pendingTasks)) return;
    setEquipmentList(
      pendingTasks.map((item) => {
        return { interconnection: item.interconnection._id, instruments: [] };
      }),
    );
  }, [pendingTasks]);

  useEffect(() => {
    formatTableData();
  }, [equipmentList]);

  return (
    <div id='pendingTasksChecklist'>
      <p>Selecione quais testes devem ser executados</p>
      <Table
        dataSource={items ?? []}
        columns={columns}
        bordered
        loading={!items}
        pagination={false}
        size='middle'
      />
      <div className='actions'>
        <Button color='default' variant='text' onClick={() => setComponent('form')}>
          Voltar
        </Button>
        <Button color='default' variant='solid' onClick={handleFinish} loading={creating}>
          Confirmar
        </Button>
      </div>
    </div>
  );
}
