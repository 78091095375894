import React, { useEffect, useState, useMemo } from 'react';
import CustomTable from '../../../components/CustomTable';
import useCrudApi from '../../../hooks/useCrudApi';
import { message } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PageContent from '../../../layoutComponents/PageContent';
import PageHeader from '../../../layoutComponents/PageHeader';
import WorkOrderModal from '../../../components/TaskModal/WorkOrderModal';
import elementsConfig from '../../../helpers/elementsConfig';
import {
  saveSessionSelections,
  restoreSessionSelections,
  clearSessionSelections,
} from './helpers/sessionStorageHelpers';
import {
  mapExecutorColumn,
  mapDataPrevistaColumn,
  mapTokenColumn,
  mapStatusColumn,
} from './helpers/filters';
import { ClearOutlined } from '@ant-design/icons';

import './style.css';

export default function WorkOrder() {
  const element = 'work-order';
  const { plantId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [workOrders, setWorkOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(searchParams.get('page') || 1);
  const [limit, setLimit] = useState(searchParams.get('limit') || 10);
  const [filtersState, setFiltersState] = useState({});
  const [sorterState, setSorterState] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { getItems, deleteItem, creating } = useCrudApi(element);

  useEffect(() => {
    const { selectedKeys, selectedRows } = restoreSessionSelections();
    setSelectedKeys(selectedKeys);
    setSelectedRows(selectedRows);
  }, []);

  useEffect(() => {
    saveSessionSelections(selectedKeys, selectedRows);
  }, [selectedKeys, selectedRows]);

  useEffect(() => {
    if (!loading) {
      loadData();
    }
  }, [workOrders, filtersState, sorterState, page, limit]);

  const loadData = async () => {
    const filterParams = mapFilters(plantId);
    const sortParams = getSortParams();
    setLoading(true);
    try {
      if (!workOrders) {
        const result = await getItems(page, limit, filterParams, sortParams);
        if (result) {
          setWorkOrders(result.items);
          setTotal(result.totalDocuments);
        }
      }
    } catch (error) {
      message.error('Erro ao carregar as ordens de serviço');
    } finally {
      setLoading(false);
    }
  };

  const mapFilters = (plantId) => {
    const dateFilter = filtersState.scheduled_date?.[0]
      ? {
          scheduled_date: filtersState.scheduled_date[0],
        }
      : {};

    return {
      location: { $eq: plantId },
      employee: filtersState.employee?.[0],
      status: filtersState.status?.[0],
      token: filtersState.token?.[0],
      ...dateFilter,
    };
  };

  const getSortParams = () => {
    const sortField = searchParams.get('sortField');
    const sortOrder = searchParams.get('sortOrder');
    return sortField ? { [sortField]: sortOrder === 'ascend' ? 1 : -1 } : {};
  };

  useEffect(() => {
    const filters = getFiltersFromSearchParams();
    const sortField = searchParams.get('sortField');
    const sortOrder = searchParams.get('sortOrder');

    if (sortField && sortOrder) {
      setSorterState({ field: sortField, order: sortOrder });
    }

    setFiltersState(filters);
  }, [searchParams]);

  const getFiltersFromSearchParams = () => {
    const filters = {};
    searchParams.forEach((value, key) => {
      if (key.startsWith('filter_')) {
        filters[key.replace('filter_', '')] = [value];
      }
    });
    return filters;
  };

  const updateSearchParams = (pagination, filters, sorter) => {
    const params = new URLSearchParams(searchParams);
    params.set('page', pagination.current);
    params.set('limit', pagination.pageSize);

    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key].length > 0) {
        params.set(`filter_${key}`, filters[key][0]);
      } else {
        params.delete(`filter_${key}`);
      }
    });

    if (sorter.field) {
      if (sorter.order) {
        params.set('sortField', sorter.field);
        params.set('sortOrder', sorter.order);
      } else {
        params.delete('sortField');
        params.delete('sortOrder');
      }
    }

    navigate(`/maintenance/plants/${plantId}/work-orders?${params.toString()}`);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    setFiltersState(filters);
    const order = sorter.order || null;
    setSorterState({ field: sorter.field, order });
    updateSearchParams(pagination, filters, { field: sorter.field, order });
    setWorkOrders(null);
  };

  const handleDeleteClick = async () => {
    if (!selectedKeys.length) return;

    try {
      await Promise.all(selectedKeys.map((id) => deleteItem(id)));
      message.success('Itens excluídos com sucesso');
      setWorkOrders((prev) => prev.filter((item) => !selectedKeys.includes(item._id)));
      clearSelections();
    } catch (error) {
      message.error('Erro ao excluir os itens');
    }
  };

  const clearSelections = () => {
    setSelectedKeys([]);
    setSelectedRows([]);
    clearSessionSelections();
  };

  const handleClearFilters = () => {
    setFiltersState({});
    setSorterState({});
    setPage(1);
    setWorkOrders(null);

    const params = new URLSearchParams();
    params.set('page', 1);
    params.set('limit', limit);

    navigate(`/maintenance/plants/${plantId}/work-orders?${params.toString()}`);
  };

  const handleFinish = () => {
    setWorkOrders(null);
    setIsModalVisible(false);
  };

  const modifiedColumns = elementsConfig[element].tableColumns.map((column) => {
    if (column.dataIndex === 'employee')
      return mapExecutorColumn(column, sorterState, filtersState);
    if (column.dataIndex === 'scheduled_date')
      return mapDataPrevistaColumn(column, sorterState, filtersState);
    if (column.dataIndex === 'token') return mapTokenColumn(column, sorterState, filtersState);
    if (column.dataIndex === 'status') return mapStatusColumn(column, sorterState, filtersState);
    return column;
  });

  const hasSorterOrFilter = useMemo(() => {
    const hasFilter = Object.keys(filtersState).some((key) => filtersState[key]?.length > 0);
    const hasSorter = !!sorterState.field;
    return hasFilter || hasSorter;
  }, [sorterState, filtersState, searchParams]);

  return (
    <div>
      <PageHeader
        buttons={[
          {
            name: 'Gerar Ordem de Serviço',
            function: () => setIsModalVisible(true),
            visible: true,
          },
          {
            name: 'Limpar Filtros',
            function: handleClearFilters,
            visible: hasSorterOrFilter,
            disabled: false,
            design: 'default',
            icon: <ClearOutlined />,
          },
        ]}
      />
      <PageContent
        Content={() => (
          <>
            <CustomTable
              items={workOrders}
              columns={modifiedColumns}
              selectedRowKeys={selectedKeys}
              setSelectedRowKeys={setSelectedKeys}
              setSelectedRows={setSelectedRows}
              pagination={{ current: page, pageSize: limit, total, showSizeChanger: true }}
              onDelete={handleDeleteClick}
              onPageChange={handleTableChange}
            />
            {isModalVisible && (
              <WorkOrderModal
                visible={isModalVisible}
                handleCancel={() => setIsModalVisible(false)}
                handleFinish={handleFinish}
                loading={creating}
              />
            )}
          </>
        )}
      />
    </div>
  );
}
