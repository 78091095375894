import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { message } from 'antd';

import CustomForm from '../../../CustomForm';
import useCrudApi from '../../../../hooks/useCrudApi';
import { useMsal } from '@azure/msal-react';
import { checkPendingTasks } from '../../../../helpers/workOrder';
import useMaintenanceState from '../../../../atoms/variables';

export default function WorkOrderForm({
  handleCancel,
  preSelectedEquipments,
  setPendingTasks,
  workOrder,
  setWorkOrder,
  setComponent,
}) {
  const { instance } = useMsal();
  const [form] = Form.useForm();
  const { chosenPlant } = useMaintenanceState();
  const [listEmployees, setListEmployees] = useState(null);
  const [listEquipments, setListEquipments] = useState(null);
  const [selectedEquipments, setSelectedEquipments] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [loading, setLoading] = useState(false);

  const getEquipments = useCrudApi('equipments').getItems;
  const getEmployees = useCrudApi('executors').getItems;

  const formFields = [
    {
      label: 'Site',
      key: 'location',
      type: 'input',
      multiple: false,
      disabled: true,
      value: chosenPlant?.name,
    },
    {
      label: 'Executor',
      key: 'employee',
      type: 'select',
      multiple: false,
      required: true,
      preSelectedItems: selectedEmployee,
      items: listEmployees,
    },
    {
      label: 'Interconexões',
      key: 'interconnection_list',
      type: selectedEquipments ? 'select-predefined' : 'select',
      multiple: true,
      required: true,
      items: listEquipments,
      preSelectedItems: selectedEquipments,
      disabled: !!selectedEquipments,
    },
    { label: 'Data Prevista', key: 'scheduled_date', type: 'date', required: true },
    {
      label: 'Idioma',
      key: 'language',
      type: 'select',
      multiple: false,
      required: true,
      items: [
        { _id: 'es', name: 'Espanhol' },
        { _id: 'en', name: 'Inglês' },
        { _id: 'pt', name: 'Português' },
      ],
    },
  ];

  const handlePreSelectedWorkOrder = () => {
    form.setFieldsValue({
      interconnection_list: workOrder.interconnection_list,
      employee: workOrder.employee,
      scheduled_date: workOrder.scheduled_date,
    });
  };

  const handlePreSelectedEquipments = (preSelectedEquipments) => {
    setSelectedEquipments(preSelectedEquipments.map((e) => e.name));
    form.setFieldsValue({
      interconnection_list: preSelectedEquipments.map((e) => e._id),
    });
  };

  const loadEquipments = async () => {
    const mappedFilter = {
      system: { $eq: chosenPlant.id },
      status: { $eq: 'Pending' },
    };
    const equipments = await getEquipments(1, undefined, mappedFilter);
    setListEquipments(equipments && equipments.items ? equipments.items : []);
  };

  const fetchData = async () => {
    try {
      const mappedFilterEmployees = {
        location: { $eq: chosenPlant.id },
      };
      const employees = await getEmployees(undefined, undefined, mappedFilterEmployees);
      setListEmployees(
        employees
          ? employees.items.map((e) => {
              return { ...e, formatName: `${e.name} (${e.company_name})` };
            })
          : [],
      );

      if (employees && workOrder) {
        let employee = employees.items.find((e) => e._id === workOrder.employee);
        if (employee) setSelectedEmployee(`${employee.name} (${employee.company_name})`);
      }
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      if (preSelectedEquipments && preSelectedEquipments.length > 0) {
        handlePreSelectedEquipments(preSelectedEquipments);
      } else {
        await loadEquipments();
      }

      if (workOrder) {
        handlePreSelectedWorkOrder();
      }
      await fetchData();
    };

    initialize();
  }, []);

  const handleSubmit = async (formValues) => {
    formValues.location = chosenPlant.id;
    const equipmentList = formValues.interconnection_list || [];
    if (equipmentList.length === 0) {
      message.error('Nenhum equipamento selecionado.');
      return;
    }
    if (equipmentList.length > 30) {
      message.error('Por favor, selecione até 30 equipamentos!');
      return;
    }
    try {
      setLoading(true);
      const pendingTasks = await checkPendingTasks(instance, formValues.interconnection_list);
      setPendingTasks(pendingTasks);
      setWorkOrder(formValues);
      setComponent('pendingTasks');
    } catch (error) {
      console.log(error);
      message.error('Erro ao enviar o formulário');
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomForm
      form={form}
      fields={formFields}
      creating={loading}
      editing={true}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      confirmLabel='Continuar'
    />
  );
}
