import { useEffect, useState } from 'react';
import DoubleColumnTemplate from '../../../../Template/layoutComponents/DoubleColumn';
import Tree from '../../../components/Tree';
import { useTranslation } from 'react-i18next';
import { Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import useSettingsState from '../../../atoms/variables';
import useGlobalState from '../../../../Template/atoms/variables';

import './style.css';
import { saveHierarchy } from '../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';

const AssetTreeBuild = () => {
  const { instance } = useMsal();
  const { setPendingApiCalls } = useGlobalState();
  const { chosenPositions, setChosenPositions, defaultNodes } = useSettingsState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [step, setStep] = useState(0);
  const [newlyChosenPositions, setNewlyChosenPositions] = useState([
    {
      hierarchy: 0,
      type: 'plant',
      label: t('plant'),
      kind: 'position',
    },
    {
      hierarchy: 0,
      type: 'machine',
      label: t('machine'),
      kind: 'asset',
    },
  ]);

  const handleTreeNodeClick = (node) => {
    if (node.type != 'plant' && node.type != 'machine') {
      setNewlyChosenPositions((current) => {
        const existingIndex = current?.findIndex((currentNode) => currentNode.type == node.type);

        if (existingIndex !== -1) {
          return current
            .filter((node, index) => index != existingIndex)
            .sort((a, b) => {
              return a.hierarchy < b.hierarchy ? -1 : 1;
            });
        } else
          return current.concat([node]).sort((a, b) => {
            return a.hierarchy < b.hierarchy ? -1 : 1;
          });
      });
    }
  };

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['saveHierarchy']));

      await saveHierarchy(instance, newlyChosenPositions);
    } catch (err) {
      // API fail notification
      notificationApi.error({
        message: 'Falha ao salvar hierarquia escolhida',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'saveHierarchy'));
    }
  };

  useEffect(() => {
    if (chosenPositions?.length > 0) {
      navigate('/settings/asset-tree/manage');
    }
  }, []);

  useEffect(() => {
    setNewlyChosenPositions((current) =>
      current.map((position) => ({
        ...position,
        label: t(position.type),
      })),
    );
  }, [t]);

  return (
    <div id='assetTree'>
      {contextHolder}
      <DoubleColumnTemplate
        firstColumnContent={
          <div className='columnContainer' style={{ justifyContent: 'space-between', gap: '2rem' }}>
            <div className='columnHeader' style={{ alignItems: 'center' }}>
              <div className='columnTitle'>{t('building_tree')}</div>
              <div className='columnSubTitle'>
                {step == 0 ? t('select_tree_nodes') : t('confirm_tree_nodes')}
              </div>
            </div>
            <div className='assetTreeProgressContainer'>
              <div className='assetTreeProgressSpacer done' style={{ height: '2rem' }}></div>
              <div className='assetTreeProgressIndicator'>
                <div className={`assetTreeProgressBubble ${step > 0 ? 'done' : 'active'}`}></div>
                <div className={`assetTreeProgressText ${step == 0 ? 'active' : ''}`}>
                  {t('select_nodes')}
                </div>
              </div>
              <div className={`assetTreeProgressSpacer ${step > 0 ? 'done' : 'active'}`}></div>
              <div className='assetTreeProgressIndicator'>
                <div
                  className={`assetTreeProgressBubble ${step > 0 ? (step == 1 ? 'active' : 'done') : ''}`}
                ></div>
                <div className={`assetTreeProgressText ${step == 1 ? 'active' : ''}`}>
                  {t('confirm_nodes')}
                </div>
              </div>
              <div className={`assetTreeProgressSpacer ${step > 1 ? 'active' : ''}`}></div>
              <div className='assetTreeProgressIndicator'>
                <div
                  className={`assetTreeProgressBubble ${step > 1 ? (step == 2 ? 'active' : 'done') : ''}`}
                ></div>
                <div className={`assetTreeProgressText  ${step == 2 ? 'active' : ''}`}>
                  {t('build_tree')}
                </div>
              </div>
            </div>
          </div>
        }
        secondColumnContent={
          <div className='columnContainer' style={{ overflow: 'auto', paddingTop: '1rem' }}>
            <Tree
              disabled={false}
              treeNodes={
                step === 0
                  ? defaultNodes
                      .map((node) => ({
                        ...node,
                        label: t(node.type),
                      }))
                      .filter((node) => node.kind === 'position')
                  : newlyChosenPositions.filter((node) => node.kind === 'position')
              }
              maxNodeWidth='70%'
              nodeType='positions'
              chosenPositions={newlyChosenPositions}
              setChosenPosition={(node) => {
                if (step == 0) handleTreeNodeClick(node);
              }}
            />
            <div className='doubleTreeSpacerContainer'>
              <div className='treeSpacer' style={{ position: 'absolute', height: '4.4rem' }}></div>
            </div>
            <Tree
              disabled={false}
              treeNodes={
                step === 0
                  ? defaultNodes
                      .map((node) => ({
                        ...node,
                        label: t(node.type),
                      }))
                      .filter((node) => node.kind === 'asset')
                  : newlyChosenPositions.filter((node) => node.kind === 'asset')
              }
              maxNodeWidth={`${70 - (step > 0 ? newlyChosenPositions : defaultNodes)?.filter((node) => node.kind === 'position').length * 5}%`}
              nodeType='assets'
              chosenPositions={newlyChosenPositions}
              setChosenPosition={(node) => {
                if (step == 0) handleTreeNodeClick(node);
              }}
            />
            <div className='buttons'>
              <Button
                className='goBack'
                size='large'
                disabled={step == 0}
                style={{
                  borderColor: '#1777FF',
                  background: 'white',
                  color: '#1777FF',
                }}
                onClick={() => {
                  setStep((current) => (current > 0 ? current - 1 : 0));
                }}
              >
                {t('back')}
              </Button>
              <Button
                className='continueButton'
                size='large'
                style={{
                  borderColor: '#1777FF',
                  background: '#1777FF',
                  color: 'white',
                }}
                onClick={async () => {
                  setStep((current) => current + 1);
                  if (step == 1) {
                    setChosenPositions(newlyChosenPositions);
                    await triggerApiCalls();
                    navigate('/settings/asset-tree/manage');
                  }
                }}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default AssetTreeBuild;
