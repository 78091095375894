import React from 'react';
import { Button, notification, Spin, ConfigProvider } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import Dragger from 'antd/es/upload/Dragger';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTemplateState from '../../../atoms/variables';

const AddFilesModal = ({
  plantId,
  id,
  type,
  uploadCall,
  reloadFileList,
  customButton,
  fileTransformer,
}) => {
  const { t } = useTranslation();
  const { setModalOpen, setModalContent, setModalTitle, setModalConfirm, setModalDisabled } =
    useTemplateState();

  const { instance } = useMsal();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [notificationApi, contextHolder] = notification.useNotification();

  const [totalProgress, setTotalProgress] = useState(0);
  const [progressPerFile, setProgressPerFile] = useState([]);

  const modalContent = (
    <div className='equipmentFiles'>
      <Dragger
        onRemove={async (file) => {}}
        customRequest={async ({ file, onSuccess, onError }) => {
          onSuccess(null, file);
          setModalDisabled(false);
        }}
        accept='application/pdf'
        multiple
        onChange={(info) => {
          if (info.fileList.length === 0) setModalDisabled(true);
          setUploadedFiles(info.fileList);
        }}
        onDrop={(e) => console.log('Dropped files', e.dataTransfer.files)}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>{t('clickOrDrag')}</p>
        <p className='ant-upload-hint'>{t('pdfOnly')}</p>
        <p className='ant-upload-hint'>{t('uploadSupport')}</p>
      </Dragger>
    </div>
  );

  const loadingContent = (
    <ConfigProvider
      theme={{
        components: {
          Spin: {
            dotSizeLG: 80,
          },
        },
      }}
    >
      <Spin spinning={totalProgress > 0} percent={totalProgress} size='large' />
      <p className='loading-text'>{t('wait')}</p>
    </ConfigProvider>
  );

  useEffect(() => {
    setModalContent(loadingContent);
  }, [totalProgress]);

  useEffect(() => {
    if (totalProgress > 0) {
      setModalConfirm(null);
    } else {
      setModalConfirm(() => async () => {
        await handleUploadFiles();
      });
    }
  }, [totalProgress, uploadedFiles]);

  useEffect(() => {
    updateOverallProgress();
  }, [progressPerFile]);

  function updateOverallProgress() {
    const overallProgress =
      progressPerFile.reduce((sum, progress) => sum + progress, 0) / uploadedFiles.length;
    setTotalProgress(overallProgress);
  }

  const onUploadProgress = (progressEvent, index) => {
    const { loaded, total } = progressEvent;
    setProgressPerFile((current) => {
      let tmp = [...current];
      tmp[index] = Math.floor((loaded * 100) / total);
      return tmp;
    });
  };

  const handleUploadFiles = async () => {
    setProgressPerFile(Array(uploadedFiles.length).fill(0));
    setTotalProgress(0);
    const uploadPromises = uploadedFiles.map((file, index) => {
      const transformedFile = fileTransformer ? fileTransformer(file) : file.originFileObj;
      return uploadCall(
        instance,
        type,
        id,
        transformedFile,
        (progressEvent) => onUploadProgress(progressEvent, index),
        fileTransformer ? true : false,
      );
    });

    await Promise.all(uploadPromises);
    setTotalProgress(100);
    setModalDisabled(false);
    setModalOpen(false);
    setTotalProgress(0);
    setModalContent(null);
    reloadFileList();
  };

  return (
    <>
      {customButton ? (
        React.cloneElement(customButton, {
          onClick: () => {
            setModalTitle(t('addTitle'));
            setModalDisabled(true);
            setModalConfirm(() => async () => {
              await handleUploadFiles();
            });
            setModalContent(modalContent);
            setModalOpen(true);
          },
        })
      ) : (
        <Button
          className='createFileButton'
          size='large'
          icon={<UploadOutlined />}
          style={{
            height: '3rem',
            background: '#1777FF',
            color: 'white',
          }}
          onClick={() => {
            setModalTitle(t('addTitle'));
            setModalDisabled(true);
            setModalConfirm(() => async () => {
              await handleUploadFiles();
            });
            setModalContent(modalContent);
            setModalOpen(true);
          }}
        >
          {t('addDocument')}
        </Button>
      )}
    </>
  );
};

export default AddFilesModal;
