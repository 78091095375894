import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useGlobalState from '../../Template/atoms/variables';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import useMaintenanceState from '../atoms/variables';
import {
  getCostCenters,
  getDepartments,
  getExistingAssetTrees,
  getManufacturers,
  getOrgs,
  getSystems,
} from '../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
export const MaintenanceOutlet = () => {
  const { instance } = useMsal();
  const { setTitle, setPendingApiCalls } = useGlobalState();
  const {
    setExistingAssetTrees,
    setOrgs,
    setDepartments,
    setSystems,
    setManufacturers,
    setCostcenters,
  } = useMaintenanceState();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  useEffect(() => {
    setTitle(null);
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) =>
        current.concat([
          'getExistingAssetTrees',
          'getOrgs',
          'getDepartments',
          'getSystems',
          'getManufacturers',
        ]),
      );

      const responses = await Promise.all([
        getExistingAssetTrees(instance),
        getOrgs(instance),
        getDepartments(instance),
        getSystems(instance),
        getManufacturers(instance),
        getCostCenters(instance),
      ]);
      setExistingAssetTrees(responses[0] ?? []);
      setOrgs(responses[1]);
      setDepartments(responses[2]);
      setSystems(responses[3]);
      setManufacturers(responses[4]);
      setCostcenters(responses[5]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter(
          (item) =>
            item !== 'getExistingAssetTrees' &&
            item !== 'getOrgs' &&
            item !== 'getDepartments' &&
            item !== 'getSystems' &&
            item !== 'getManufacturers',
        ),
      );
    }
  };

  return (
    <div
      className='default-module-outlet'
      // style={{ margin: '3vh', overflowY: 'auto' }}
    >
      {contextHolder}
      <Outlet />
    </div>
  );
};
