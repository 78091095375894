import React from 'react';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../hooks/useGlobalState';

export default function DetailsButton({ column, columnKey, element }) {
  const navigate = useNavigate();
  const { setDetailsSelection } = useGlobalState();
  const { plantId } = useParams();

  function handleClick() {
    setDetailsSelection(column);

    if (!columnKey) {
      return;
    }

    if (element === 'work-order') {
      return navigate(`/maintenance/plants/${plantId}/work-orders/${columnKey}/details`);
    }

    if (element === 'interconnections') {
      return navigate(`/maintenance/plants/${plantId}/interconnections/${columnKey}`);
    }
  }

  return (
    <Button type='link' onClick={handleClick}>
      Ver Detalhes
    </Button>
  );
}
