import { useState } from 'react';
import axios from 'axios';
import elementsConfig from '../helpers/elementsConfig.js';
import { acquireToken } from '../../auth/msal.js';
import { useMsal } from '@azure/msal-react';

export default function useFileApi() {
  const { apiUrl, scope } = elementsConfig['files'];
  const { instance } = useMsal();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const uploadFile = async (equipmentId, file) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await acquireToken(instance, scope);
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await axios.post(`${apiUrl}/${equipmentId}`, formData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data;
    } catch (e) {
      setError(e);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const getEquipmentFiles = async (equipmentId) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await acquireToken(instance, scope);
      const { data } = await axios.get(`${apiUrl}/${equipmentId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data;
    } catch (e) {
      console.log(e);
      setError(e);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = async (fileName) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await acquireToken(instance, scope);
      const { data } = await axios.delete(`${apiUrl}?file_name=${encodeURIComponent(fileName)}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data;
    } catch (e) {
      console.log(e);
      setError(e);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return {
    uploadFile,
    getEquipmentFiles,
    deleteFile,
    loading,
    error,
  };
}
