import React from 'react';

import './style.css';
import { Col, Form, Input, InputNumber, Row, Select } from 'antd';

const renderFieldComponent = (field) => {
  switch (field.type) {
    case 'input':
      return (
        <Form.Item
          className={'custom-form-item'}
          key={field.label}
          name={field.label}
          style={{
            marginLeft: '1vh',
            marginBottom: '0',
            minHeight: '0 !important',
            height: '22px !important',
          }}
        >
          <Input defaultValue={field.userGivenValue && field.userGivenValue[0]} disabled />
        </Form.Item>
      );
    case 'select':
      return (
        <Form.Item
          className={'custom-form-item'}
          key={field.label}
          name={field.label}
          style={{
            marginLeft: '1vh',
            marginBottom: '0',
            minHeight: '0 !important',
            height: '22px !important',
          }}
        >
          <Select
            size={'small'}
            style={{ width: 'auto', minWidth: 100 }}
            popupMatchSelectWidth={false}
            defaultValue={field.userGivenValue && field.userGivenValue[0]}
            disabled
          >
            {field.choices &&
              field.choices.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            <Select.Option key={''} value={''}>
              Sem valor
            </Select.Option>
          </Select>
        </Form.Item>
      );
    default:
      return null;
  }
};

const renderField = (field, editMode, version, latestVersion) => {
  switch (editMode) {
    case true:
      return renderFieldComponent(field);
    case false:
      return (version === null && latestVersion === null) || version === latestVersion
        ? field.userGivenValue && field.userGivenValue[0]
          ? field.userGivenValue[0]
          : '-'
        : version === null && latestVersion !== null
          ? field.previousUserGivenValue && field.previousUserGivenValue[0]
            ? field.previousUserGivenValue[0]
            : '-'
          : field.previousUserGivenValue && field.previousUserGivenValue[version]
            ? field.previousUserGivenValue[version]
            : '-';
    default:
      return null;
  }
};

const renderStepComponent = (step, instrument) => {
  const instrumentUnits = instrument?.validation?.unit?.values.map((unit) => {
    return { label: unit?.symbol, value: unit?.symbol };
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Form.Item
        className={'custom-form-item'}
        key={step.title}
        name={step.title}
        style={{ marginLeft: '1vh', marginBottom: '0' }}
      >
        <InputNumber
          defaultValue={step.imageAnalysis?.reading}
          size={'small'}
          style={{ width: '3vw' }}
        />
      </Form.Item>
      <Form.Item
        className={'custom-form-item'}
        key={`${step.title}-unit`}
        name={`${step.title}-unit`}
        style={{ marginLeft: '1vh', marginBottom: '0' }}
      >
        <Select
          defaultValue={step.imageAnalysis?.reading_unit}
          size={'small'}
          style={{ width: '3.5vw' }}
          options={instrumentUnits}
        />
      </Form.Item>
    </div>
  );
};

const renderStep = (step, instrument, editMode, version, latestVersion) => {
  switch (editMode) {
    case true:
      return renderStepComponent(step, instrument);
    case false:
      return (version === null && latestVersion === null) || version === latestVersion
        ? `${step.imageAnalysis?.reading} ${step.imageAnalysis?.reading_unit}`
        : version === null && latestVersion !== null
          ? `${step.imageAnalysis?.previousReadings && step.imageAnalysis?.previousReadings[0]} ${step.imageAnalysis?.previousReadings && step.imageAnalysis?.previousReadingsUnits[0]}`
          : `${step.imageAnalysis?.previousReadings && step.imageAnalysis?.previousReadings[version]} ${step.imageAnalysis?.previousReadings && step.imageAnalysis?.previousReadingsUnits[version]}`;
    default:
      return null;
  }
};

const hipotMapFields = {
  date: 'Data:',
  time: 'Horário:',
  voltage: 'Voltagem:',
  current: 'Amperagem:',
  frequency: 'Frequência:',
  operator: 'Operador:',
  time_count: 'Contagem:',
  valid: 'Válido?',
};

const formatValue = (key, value) => {
  return key === 'valid' ? (value ? 'Sim' : 'Não') : value;
};

const renderFieldHipot = (key, phase, editMode, version, latestVersion) => {
  const imageAnalysis = phase?.steps[0]?.imageAnalysis[key];
  const previousImageAnalysis = phase?.steps[0]?.previousImageAnalysis;

  switch (editMode) {
    case true:
      return (
        <Form.Item
          className={'custom-form-item'}
          key={key}
          name={key}
          style={{
            marginLeft: '1vh',
            marginBottom: '0',
            minHeight: '0 !important',
            height: '22px !important',
          }}
        >
          <Input size='small' defaultValue={imageAnalysis} />
        </Form.Item>
      );
    case false:
      return (version === null && latestVersion === null) || version === latestVersion
        ? (formatValue(key, imageAnalysis) ?? '-')
        : version === null && latestVersion !== null
          ? previousImageAnalysis && previousImageAnalysis[key][0]
            ? formatValue(key, previousImageAnalysis[key][0])
            : '-'
          : previousImageAnalysis && previousImageAnalysis[key][version]
            ? formatValue(key, previousImageAnalysis[key][version])
            : '-';
    default:
      return null;
  }
};

export default function ListContent({ instrument, phase, editMode, latestVersion, version }) {
  return (
    <>
      {phase?.type === 'form' && (
        <div className={'report-table'}>
          <div className={'report-table-title'}>
            <p
              style={{
                margin: '0 0 0 10px',
                padding: '2px 0 2px 0',
                color: 'white',
                fontWeight: '500',
              }}
            >
              {phase.title}
            </p>
          </div>
          <div className={'report-table-content'}>
            <Row>
              {phase?.fields?.map((field) => (
                <>
                  <Col span={6}>
                    <div className={'report-table-item'}>
                      <p
                        style={{
                          margin: '0 0 0 10px',
                          padding: '2px 0 2px 0',
                          color: '#225968',
                          fontWeight: '500',
                        }}
                      >
                        {field?.label}
                      </p>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className={'report-table-item'}>
                      <p
                        style={{
                          margin: '0 0 0 10px',
                          padding: '2px 0 2px 0',
                          color:
                            latestVersion !== version
                              ? 'grey'
                              : field?.isGoodToGo
                                ? 'black'
                                : 'red',
                          fontWeight: '500',
                        }}
                      >
                        {phase?.title === 'Inspeção Visual'
                          ? field.userGivenValue && field.userGivenValue[0]
                            ? field.userGivenValue[0]
                            : '-'
                          : renderField(field, editMode, version, latestVersion)}
                      </p>
                    </div>
                  </Col>
                </>
              ))}
            </Row>
          </div>
        </div>
      )}
      {phase?.type === 'camera' && (
        <div className={'report-table'}>
          <div className={'report-table-title'}>
            <p
              style={{
                margin: '0 0 0 10px',
                padding: '2px 0 2px 0',
                color: 'white',
                fontWeight: '500',
              }}
            >
              {`${phase.title} - ${instrument?.name}`}
            </p>
          </div>
          <div className={'report-table-content'}>
            <Row>
              {instrument?.name === 'Megger' &&
                phase?.steps?.map((step) => (
                  <>
                    <Col span={6}>
                      <div className={'report-table-item'}>
                        <p
                          style={{
                            margin: '0 0 0 10px',
                            padding: '2px 0 2px 0',
                            color: '#225968',
                            fontWeight: '500',
                          }}
                        >
                          {step?.title}
                        </p>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className={'report-table-item'}>
                        <p
                          className='symbol-font'
                          style={{
                            margin: '0 0 0 10px',
                            padding: '2px 0 2px 0',
                            color:
                              latestVersion !== version ? 'grey' : step?.valid ? 'black' : 'red',
                            fontWeight: '500',
                          }}
                        >
                          {renderStep(step, instrument, editMode, version, latestVersion)}
                        </p>
                      </div>
                    </Col>
                  </>
                ))}
              {instrument?.name === 'Hipot' &&
                phase?.steps[0]?.imageAnalysis &&
                Object.keys(phase?.steps[0]?.imageAnalysis).map(
                  (key) =>
                    hipotMapFields[key] && (
                      <>
                        <Col span={6}>
                          <div className={'report-table-item'}>
                            <p
                              style={{
                                margin: '0 0 0 10px',
                                padding: '2px 0 2px 0',
                                color: '#225968',
                                fontWeight: '500',
                              }}
                            >
                              {hipotMapFields[key]}
                            </p>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div className={'report-table-item'}>
                            <p
                              className='symbol-font'
                              style={{
                                margin: '0 0 0 10px',
                                padding: '2px 0 2px 0',
                                color:
                                  latestVersion !== version
                                    ? 'grey'
                                    : key !== 'time_count'
                                      ? 'black'
                                      : phase?.steps[0]?.valid
                                        ? 'black'
                                        : 'red',
                                fontWeight: '500',
                              }}
                            >
                              {renderFieldHipot(key, phase, editMode, version, latestVersion)}
                            </p>
                          </div>
                        </Col>
                      </>
                    ),
                )}
            </Row>
          </div>
        </div>
      )}
    </>
  );
}
