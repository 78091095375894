import React, { useEffect } from 'react';
import { Select } from 'antd';
import './style.css';

const { Option } = Select;

const DynamicSelect = ({
  selectKey,
  multiple,
  onChange,
  width,
  placeholder = 'Selecione',
  defaultValue,
  selectedValue,
  items,
  label,
}) => {
  const getDisplayValue = (item) => {
    return item.formatName ? item.formatName : (item.name ?? item._id);
  };

  const isLoadingItems = !items || items.length === 0;

  return (
    <Select
      aria-label={label ? label : ''}
      style={{ width: width ? width : '100%' }}
      mode={multiple ? 'multiple' : null}
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={isLoadingItems ? null : selectedValue}
      className={multiple ? 'multiple-select' : ''}
      loading={isLoadingItems}
      filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
    >
      {!isLoadingItems &&
        items.map((item) => (
          <Option
            label={getDisplayValue(item)}
            key={item[selectKey ?? '_id']}
            value={item[selectKey ?? '_id']}
          >
            {getDisplayValue(item)}
          </Option>
        ))}
    </Select>
  );
};

export default DynamicSelect;
