import { Outlet } from 'react-router-dom';
import { notification } from 'antd';
import { useEffect } from 'react';
import { getOrgs } from '../../../Maintenance/helpers/apiCalls';
import { useMsal } from '@azure/msal-react';

import useMaintenanceState from '../../atoms/variables';
import useTemplateState from '../../../Template/atoms/variables';

const DepartmentsOutlet = () => {
  const [notificationApi, contextHolder] = notification.useNotification();

  const { instance } = useMsal();
  const { orgs, setOrgs } = useMaintenanceState();
  const { setPendingApiCalls } = useTemplateState();

  useEffect(() => {
    if (orgs?.length === 0) {
      triggerApiCalls();
    }
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getOrgs']));

      const response = await getOrgs(instance);

      setOrgs(response);
    } catch (err) {
      notificationApi.error({
        message: 'Falha de comunicação com o banco de dados',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getOrgs'));
    }
  };

  return (
    <>
      {contextHolder}
      <Outlet />
    </>
  );
};

export default DepartmentsOutlet;
