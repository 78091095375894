import { useNavigate, useParams } from 'react-router-dom';
import Grid from '../../../../../Template/layoutComponents/Grid';
import { useTranslation } from 'react-i18next';
import useMaintenanceState, { chosenPlant } from '../../../../atoms/variables';
import { getNewSystem } from '../../../../helpers/schemas';
import useTemplateState from '../../../../../Template/atoms/variables';
import { useEffect } from 'react';
import { notification } from 'antd';
import { getSystems } from '../../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';

const Systems = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { systems, setChosenSystem } = useMaintenanceState();

  const { plantId } = useParams();

  const { setPendingApiCalls } = useTemplateState();

  const handleClickNew = () => {
    setChosenSystem(getNewSystem(t));
    navigate(`/maintenance/plants/${plantId}/systems/newSystem`);
  };
  const handleClickItem = (item) => {
    setChosenSystem(item);
    navigate(`/maintenance/plants/${plantId}/systems/${item.id}/view`);
  };

  return (
    <>
      {contextHolder}
      <Grid items={systems} onClickItem={handleClickItem} onClickNew={handleClickNew} />
    </>
  );
};
export default Systems;
