import { useNavigate } from 'react-router-dom';
import Grid from '../../../../Template/layoutComponents/Grid';
import { useTranslation } from 'react-i18next';
import useMaintenanceState from '../../../atoms/variables';

import { getNewPlant } from '../../../helpers/schemas';
import './style.css';

const PlantsGrid = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { existingAssetTrees, setChosenPlant } = useMaintenanceState();

  const handleClickNew = () => {
    setChosenPlant(getNewPlant(t));
    navigate(`/maintenance/plants/newPlant`);
  };
  const handleClickItem = (item) => {
    setChosenPlant(item);
    navigate(`/maintenance/plants/${item?.id}`);
  };

  const filteredItems = existingAssetTrees?.filter((item) => item?.id !== 'newPlant') || [];

  return <Grid items={filteredItems} onClickItem={handleClickItem} onClickNew={handleClickNew} />;
};

export default PlantsGrid;
