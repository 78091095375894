import React from 'react';
import { Button, message } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import DownloadEyeButton from './DownloadEyeButton';

const ReportButtons = ({ task, onExpand, expanded }) => {
  const handleClick = () => {
    const element = document.getElementById(`overview-content-${task._id}`);
    if (!element) onExpand(task);
    else {
      const containsAntInput = element.querySelector('.ant-input') !== null;
      const containsAntSelect = element.querySelector('.ant-select') !== null;
      if (containsAntInput || containsAntSelect) {
        message.info('Por favor, finalize a edição antes de fechar o visualizador!');
      } else onExpand(task);
    }
  };

  const taskIsReady = () => {
    return (
      task &&
      task.status !== 'Pending AI validation' &&
      task.status !== 'Waiting for files' &&
      task.status !== 'Pending'
    );
  };

  return (
    <div>
      <Button
        type='text'
        disabled={!taskIsReady()}
        icon={
          expanded ? (
            <EyeOutlined style={{ fontSize: '20px', color: 'blue' }} />
          ) : (
            <EyeInvisibleOutlined style={{ fontSize: '20px' }} />
          )
        }
        style={{ marginRight: '15px' }}
        onClick={handleClick}
      />
      <DownloadEyeButton
        expanded={expanded}
        interconnectionName={task?.interconnection?.name}
        siteName={task?.interconnection?.system?.name}
        task={task}
      />
    </div>
  );
};

export default ReportButtons;
