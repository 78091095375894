import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Form } from 'antd';
import CustomForm from '../../CustomForm';
import unflattenObjects from '../../../utils/unflattenObject';
import './style.css';
import useCrudApi from '../../../hooks/useCrudApi';

const ManualTaskModal = ({ visible, onCancel, handleSubmit, loading }) => {
  const navigate = useNavigate();
  const [transformInTwoColums, setTransformInTwoColums] = useState(false);
  const getLocations = useCrudApi('sites').getItems;
  const getCategories = useCrudApi('equipment_category').getItems;
  const createEquipment = useCrudApi('equipments').createItem;
  const [listLocations, setListLocations] = useState(null);
  const [listCategories, setListCategories] = useState(null);
  const [listConditions, setListConditions] = useState(null);

  const [form] = Form.useForm();
  let formFields = [
    {
      label: 'Categoria',
      key: 'category',
      type: 'select',
      selectItem: 'equipment_category',
      selectKey: '_id',
      multiple: false,
      required: true,
      items: listCategories,
    },
    {
      label: 'Nome da Interconexão',
      key: 'name',
      type: 'input',
      required: true,
    },
    {
      label: 'Localização',
      key: 'system',
      type: 'select',
      selectItem: 'sites',
      selectKey: '_id',
      multiple: false,
      required: true,
      items: listLocations,
    },
    {
      label: 'Condição',
      key: 'condition',
      type: 'tag',
      selectItem: 'equipment_condition',
      selectKey: 'name',
      value: 'Em Comissionamento',
    },
  ];
  const [fields, setFields] = useState(formFields);
  const categoryId = Form.useWatch('category', form);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (visible && !listConditions && !listCategories && !listLocations) {
          const resultLocations = await getLocations();
          if (resultLocations) {
            setListLocations(resultLocations.items);
          }
          const resultCategories = await getCategories();
          if (resultCategories) {
            setListCategories(resultCategories.items);
          }
          setListConditions([
            {
              name: 'Em Comissionamento',
              value: 'Em Comissionamento',
              label: 'Em Comissionamento',
            },
          ]);
        }
      } catch (error) {
        setListLocations([]);
        setListCategories([]);
        setListConditions([]);
      }
    };
    fetchData();
    if (!categoryId) setFields(formFields);
    else {
      let category = listCategories?.find((cat) => cat._id === categoryId);
      let fields = category.settings.worksheet.values;
      let properties = [];
      for (let field of fields.filter((f) => f.key.includes('properties.'))) {
        properties.push({
          label: field.label,
          key: field.key,
          type: 'input',
          required: true,
          regex: field.regex,
          regexMessage: field.regexMessage,
        });
      }
      setFields([...formFields, ...properties]);
    }
  }, [visible, categoryId]);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSendForm = async (values) => {
    await createEquipment(unflattenObjects(values));
    await handleSubmit(unflattenObjects(values));
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={<div className='title-manual-task-modal'>Criar uma interconexão manualmente</div>}
      open={visible}
      onCancel={() => {
        form.resetFields();
        setTransformInTwoColums(false);
        onCancel();
      }}
      footer={null}
      width={'68vw'}
      centered
      bodyStyle={{ maxHeight: '82vh', overflowY: 'auto' }}
    >
      <CustomForm
        form={form}
        fields={categoryId ? fields : formFields}
        creating={loading}
        handleSubmit={handleSendForm}
        handleCancel={handleCancel}
        isTwoColumns={transformInTwoColums}
      />
    </Modal>
  );
};

export default ManualTaskModal;
