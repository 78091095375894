import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  collapsed,
  breadcrumbItems,
  commissioningTree,
  detailsSelection,
  navigationItems,
  navigationSelected,
  userState,
  loadingState,
  currentAssistantState,
  conversationHistoryState,
  currentConversationIdState,
  currentConversationMessagesState,
  currentResponseState,
  socketIdState,
  streamingState,
  searchTermState,
  openKeys,
  downloading
} from '../atoms/globalVariables';

export function useGlobalState() {
  return {
    collapsed: useRecoilValue(collapsed),
    breadcrumbItems: useRecoilValue(breadcrumbItems),
    commissioningTree: useRecoilValue(commissioningTree),
    detailsSelection: useRecoilValue(detailsSelection),
    navigationItems: useRecoilValue(navigationItems),
    navigationSelected: useRecoilValue(navigationSelected),
    searchTermState: useRecoilValue(searchTermState),
    openKeys: useRecoilValue(openKeys),
    downloading: useRecoilValue(downloading),

    user: useRecoilValue(userState),
    loading: useRecoilValue(loadingState),
    currentAssistant: useRecoilValue(currentAssistantState),
    conversationHistory: useRecoilValue(conversationHistoryState),
    currentConversationId: useRecoilValue(currentConversationIdState),
    currentConversationMessages: useRecoilValue(currentConversationMessagesState),
    currentResponse: useRecoilValue(currentResponseState),
    socketId: useRecoilValue(socketIdState),
    streaming: useRecoilValue(streamingState),
    setStreaming: useSetRecoilState(streamingState),
    setSocketId: useSetRecoilState(socketIdState),
    setCurrentResponse: useSetRecoilState(currentResponseState),
    setCurrentConversationMessages: useSetRecoilState(currentConversationMessagesState),
    setUser: useSetRecoilState(userState),
    setCurrentConversationId: useSetRecoilState(currentConversationIdState),
    setConversationHistory: useSetRecoilState(conversationHistoryState),

    setCollapsed: useSetRecoilState(collapsed),
    setBreadcrumbItems: useSetRecoilState(breadcrumbItems),
    setCommissioningTree: useSetRecoilState(commissioningTree),
    setDetailsSelection: useSetRecoilState(detailsSelection),
    setNavigationItems: useSetRecoilState(navigationItems),
    setNavigationSelected: useSetRecoilState(navigationSelected),
    setSearchTermState: useSetRecoilState(searchTermState),
    setOpenKeys: useSetRecoilState(openKeys),
    setDownloading: useSetRecoilState(downloading),
  };
}
