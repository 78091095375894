import React from 'react';
import { Modal, Card, Row, Col } from 'antd';
import { UploadOutlined, FileAddOutlined } from '@ant-design/icons';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useLocation, useParams } from 'react-router-dom';
import { modalTypeState, uploadStatusState } from '../../atoms/globalVariables';
import ManualTaskModal from './ManualTaskModal';
import UploadTaskModal from './UploadTaskModal';
import './style.css';

const AddTaskModal = ({
  visible,
  onCancel,
  onClose,
  handleSubmit,
  loading,
  setEquipments,
  loadData,
}) => {
  const [modalType, setModalType] = useRecoilState(modalTypeState);
  const setStatus = useSetRecoilState(uploadStatusState);

  const handleCardClick = (type) => {
    setModalType(type);
  };

  const handleModalClose = () => {
    onCancel();
    setModalType(null);
  };

  return (
    <>
      <Modal
        title={'Adicione uma nova interconexão'}
        open={visible}
        onCancel={onCancel}
        footer={null}
        width={'40vw'}
        centered
      >
        <Row gutter={14} className='container-cards' style={{ marginTop: '24px', display: 'flex' }}>
          <Col span={12}>
            <Card
              className='fixed-card'
              hoverable
              onClick={() => {
                handleCardClick('manual');
              }}
              cover={
                <div>
                  <FileAddOutlined />
                </div>
              }
            >
              <Card.Meta
                title={
                  <div className='custom-card-title'>Adicione uma interconexão manualmente</div>
                }
                description='Preencha manualmente um formulário.'
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              className='fixed-card'
              hoverable
              onClick={() => {
                handleCardClick('upload');
                setStatus('idle');
              }}
              cover={
                <div>
                  <UploadOutlined />
                </div>
              }
            >
              <Card.Meta
                title={<div className='custom-card-title'>Upload de Planilha</div>}
                description='Faça upload de uma planilha para preencher automaticamente.'
              />
            </Card>
          </Col>
        </Row>
      </Modal>
      {modalType === 'manual' && (
        <ManualTaskModal
          visible={modalType === 'manual'}
          onCancel={handleModalClose}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      )}
      {modalType === 'upload' && (
        <UploadTaskModal
          visible={modalType === 'upload'}
          onCancel={handleModalClose}
          setEquipments={setEquipments}
          loadData={loadData}
        />
      )}
    </>
  );
};

export default AddTaskModal;
