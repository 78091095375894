import React from 'react';

import inventory from '../../../../../images/inventory.svg';

const InstrumentsIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={inventory} alt={altText} style={{ width, height }} />;
};

export default InstrumentsIconComponent;
