import React, { useRef, useEffect } from 'react';
import { Button, message } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import watermarkImage from '../../../images/commissioning/logo_s.png';
import customFont from '../../../fonts/DrSugiyama-Regular.js';
import symbolFont from '../../../fonts/Roboto-Regular.js';

const DownloadEyeButton = ({ task, interconnectionName, siteName, expanded }) => {
  const sessionVersion = 'sessionVersion';
  const { workOrderToken } = useParams();

  const getFileName = () => {
    const fileName = workOrderToken ? `OS_${workOrderToken}` : `${siteName}_${interconnectionName}`;
    return `${fileName}_overview.pdf`;
  };

  const getNormalizedFileName = () => {
    const selectedVersion = Number(sessionStorage.getItem(sessionVersion));
    const normalized_name = task?.interconnection?.name.replace(' ', '');
    let version = selectedVersion ? selectedVersion + 1 : 1;
    const fileName = `${siteName}_${normalized_name}_${task?.instrument?.name}_${task.attempt}_v${version}`;
    return `${fileName}.pdf`;
  };

  useEffect(() => {
    document.title = getFileName();
  }, []);

  const getPDFBlob = async (id) => {
    const element = document.getElementById(`overview-content-${id}`);
    const clone = element.cloneNode(true);

    if (!element) return;

    if (clone) {
      clone.style.height = '1775px';
      clone.style.width = '1461px';
    }

    const containsAntInput = element.querySelector('.ant-input') !== null;
    const containsAntSelect = element.querySelector('.ant-select') !== null;
    if (containsAntInput || containsAntSelect) {
      message.info('Por favor, finalize a edição antes de baixar o arquivo!');
      return;
    }

    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    pdf.setFillColor(255, 255, 255);
    pdf.rect(0, 0, pageWidth, pageHeight, 'F');

    pdf.addFileToVFS('DrSugiyama-Regular.ttf', customFont);
    pdf.addFont('DrSugiyama-Regular.ttf', 'DrSugiyama-Regular', 'normal');
    pdf.addFileToVFS('Roboto-Regular.ttf', symbolFont);
    pdf.addFont('Roboto-Regular.ttf', 'Roboto-Regular', 'normal');

    await pdf.html(clone, {
      width: pageWidth,
      windowWidth: 1459,
      filename: 'jspdf',
      x: 0,
      y: 0,
      callback: () => {
        addWaterMark(pdf, pageWidth, pageHeight);
        return pdf.save(getNormalizedFileName());
      },
    });
  };

  function addWaterMark(doc, pageWidth, pageHeight) {
    var totalPages = doc.internal.getNumberOfPages();

    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.addImage(watermarkImage, 'PNG', 0, 0, pageWidth, pageHeight, '', 'SLOW');
    }

    return doc;
  }

  const handleOnClick = () => {
    getPDFBlob(task._id);
  };

  const taskIsReady = () => {
    return task && task.status !== 'Pending AI validation' && task.status !== 'Waiting for files';
  };

  return (
    <>
      <Button
        type='text'
        disabled={!expanded || !taskIsReady()}
        icon={
          <FilePdfOutlined
            style={{ fontSize: '20px', color: expanded && taskIsReady() && 'red' }}
            onClick={handleOnClick}
          />
        }
      />
    </>
  );
};
export default DownloadEyeButton;
