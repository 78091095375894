import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, message, Flex } from 'antd';
import CustomForm from '../../../../../components/CustomForm';
import InformationButtons from './InformationButtons';
import useCrudApi from '../../../../../hooks/useCrudApi';
import elementsConfig from '../../../../../helpers/elementsConfig';
import flattenObjects from '../../../../../utils/flattenObjects';
import unflattenObjects from '../../../../../utils/unflattenObject';

import './style.css';
import { useGlobalState } from '../../../../../hooks/useGlobalState';

export default function UpdateRegister({ interconnection }) {
  const [editing, setEditing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const element = 'tab_information';
  const { setDetailsSelection } = useGlobalState();

  const { updateItem, updating, deleteItem, deleting } = useCrudApi(element);

  const baseFields = elementsConfig[element].formFields;
  const [fields, setFields] = useState(baseFields);
  const [form] = Form.useForm();

  useEffect(() => {
    if (interconnection && Object.keys(interconnection).length > 0) {
      let fields = interconnection.category?.settings?.worksheet?.values;
      if (fields) {
        let properties = [];
        for (let field of fields.filter((f) => f.key.includes('properties.'))) {
          properties.push({
            label: field.label,
            key: field.key,
            type: 'input',
            required: false,
          });
        }
        setFields([...baseFields, ...properties]);
      }
      const flatInterconnection = flattenObjects(interconnection);
      form.setFieldsValue({
        ...flatInterconnection,
      });
    }
  }, [interconnection, form]);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleDeleteClick = async () => {
    try {
      const success = await deleteItem(interconnection._id);
      if (success) {
        navigate(-1);
      }
    } catch (error) {
      message.error('Erro ao excluir o item');
    }
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const removeBlockedFields = (field, object) => {
    delete object[field];
    return object;
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const unflatValues = unflattenObjects(values);
      const editedValues = removeBlockedFields('subcategory', unflatValues);
      const success = await updateItem(interconnection._id, editedValues);
      if (success) {
        setEditing(false);
        form.resetFields();
        let currentPath = location.pathname;
        const basePath = currentPath.substring(0, currentPath.lastIndexOf('/'));
        setDetailsSelection(null);
        navigate(basePath);
      }
    } catch (error) {
      message.error('Erro ao enviar o formulário');
    }
  };

  return (
    <Flex vertical style={{ overflowY: 'scroll', height: '75vh' }}>
      <InformationButtons
        interconnection={interconnection}
        editing={editing}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        loading={updating || deleting}
      />
      <CustomForm
        form={form}
        fields={fields}
        creating={updating || deleting}
        readOnly={!editing}
        isTwoColumns
        informationMenu
        hideButtons
      />
    </Flex>
  );
}
