import React, { useEffect } from 'react';
import { Layout } from 'antd';
import './style.css';

export default function PageContent({ Content }) {
  return (
    <div className='legacyPages'>
      <Layout id='layout-content'>
        <Content />
      </Layout>
    </div>
  );
}
