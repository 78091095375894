import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import useTemplateState from '../../atoms/variables';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import useGlobalState from '../../atoms/variables';
import { getCompanyInfo } from '../../helpers/apiCalls';
import { notification } from 'antd';

const ProtectedModule = () => {
  const { setPendingApiCalls, setCompanyInfo } = useGlobalState();
  const { companyInfo } = useTemplateState();
  const { instance } = useMsal();
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(true); // Indica se os dados ainda estão sendo carregados
  const { pathname } = useLocation();

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getCompanyInfo']));
      const response = await getCompanyInfo(instance);
      setCompanyInfo(response);
    } catch (err) {
      console.log(err);
      // API fail notification
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getCompanyInfo'));
      setLoading(false);
    }
  };

  useEffect(() => {
    triggerApiCalls();
  }, []);

  useEffect(() => {
    let module = '/' + pathname.slice(1).split('/')[0];
    setHasAccess(companyInfo?.modules.includes(module));
  }, [pathname, companyInfo]);

  if (loading) return;

  if (!hasAccess) {
    return <div>{t('no_permission')}.</div>;
  }
  return (
    <>
      {contextHolder}
      <Outlet />
    </>
  );
};

export default ProtectedModule;
