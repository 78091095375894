import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Tabs from '../../../../Template/layoutComponents/Tabs';

import HistoryTab from '../../../../Template/layoutComponents/HistoryTab';
import GeneralTab from '../../../../Template/layoutComponents/GeneralTab';
import { updateOrganization } from '../../../helpers/apiCalls';

const Details = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const location = useLocation();

  useEffect(() => {
    if (!categoryId) {
      navigate(`/settings/organizations`);
    }
  }, [location]);

  return (
    <div id='assetDetails'>
      <Tabs
        tabs={[
          {
            label: t('general_tab'),
            content: (
              <GeneralTab detailsLink={'/settings/organizations'} updateItem={updateOrganization} />
            ),
            disabled: false,
          },
          {
            label: t('history_tab'),
            content: <HistoryTab id={categoryId} />,
            disabled: false,
          },
        ].filter((tab) => tab)}
      />
    </div>
  );
};

export default Details;
