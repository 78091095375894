import { getAllAssetsByManufacturer, getPlants } from '../../../../../Maintenance/helpers/apiCalls';

import Table from '../../../../../Template/layoutComponents/Table';
import { useTranslation } from 'react-i18next';

import useTemplateState from '../../../../../Template/atoms/variables';
import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { getAllCategories } from '../../../../helpers/apiCalls';

const AllAssetsTab = () => {
  const { instance } = useMsal();
  const { categoryId } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const { setPendingApiCalls } = useTemplateState();

  const [assetsByManufacturer, setAssetsByManufacturer] = useState(null);
  const [plants, setPlants] = useState([]);
  const [categories, setCategories] = useState([]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getAllAssetsByManufacturer']));

      const response = await Promise.all([
        getPlants(instance),
        getAllAssetsByManufacturer(instance, { manufacturer: categoryId }),
        getAllCategories(instance),
      ]);

      setPlants(response[0]);
      setAssetsByManufacturer(response[1]);
      setCategories(response[2]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== 'getAllAssetsByManufacturer'),
      );
    }
  };

  return (
    <div className='allAssetsTab'>
      {contextHolder}
      <Table
        handleSelect={null}
        apiCall={(instance, params) =>
          getAllAssetsByManufacturer(instance, { manufacturer: categoryId, ...params })
        }
        items={assetsByManufacturer?.items}
        handleRowClick={(row) => {
          navigate(`/maintenance/plants/${row.location}/assets/${row.id}`);
        }}
        tableSettings={{
          totalItems: assetsByManufacturer?.totalItems ?? 0,
          columnsConfig: {
            location: {
              label: t('plant'),
              replaceValue: (value) => {
                console.log(value);
                return plants?.find((cat) => cat._id === value || cat.id === value);
              },
            },
            name: {
              label: t('name'),
            },
            asset_category: {
              label: t('category'),
              replaceValue: (value) => {
                return categories?.find((cat) => cat._id === value || cat.id === value);
              },
            },
            model_number: {
              label: t('model_number'),
            },
            serial_number: {
              label: t('serial_number'),
            },
          },
          sortable: ['location', 'name', 'asset_category', 'model_number', 'serial_number'],
          listFilter: ['location', 'asset_category'],
          searchFilter: ['name', 'model_number', 'serial_number'],
          uniqueValues: assetsByManufacturer?.uniqueValues,
        }}
      />
    </div>
  );
};

export default AllAssetsTab;
