import React, { useState } from 'react';
import { Button, Space, Flex } from 'antd';
import { EditOutlined, DeleteOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import DeleteModal from '../../../../../components/DeleteModal';

const WorkOrderButtons = ({
  workOrder,
  handleSubmit,
  handleDeleteClick,
  handleEditClick,
  handleCancel,
  editing,
  loading,
}) => {
  const [visible, setVisible] = useState(false);

  const disableEdit = () => {
    return !workOrder || workOrder.status === 'Incomplete' || workOrder.status === 'Expired';
  };

  return (
    <Flex>
      <Flex align='flex-end'>
        <Space direction='horizontal' style={{ marginLeft: 'auto' }}>
          {!editing ? (
            <>
              <Button
                icon={<EditOutlined />}
                disabled={disableEdit()}
                type='text'
                style={{ background: disableEdit() ? 'grey': 'black', color: 'white' }}
                onClick={handleEditClick}
              >
                Editar
              </Button>
              <Button
                loading={loading}
                icon={<DeleteOutlined />}
                type='text'
                style={{ background: 'red', color: 'white' }}
                onClick={() => {
                  setVisible(true);
                }}
              >
                Excluir
              </Button>
            </>
          ) : (
            <>
              <Button
                loading={loading}
                icon={<CloseCircleOutlined />}
                type='text'
                style={{ background: 'black', color: 'white' }}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                htmlType='submit'
                loading={loading}
                icon={<SaveOutlined />}
                type='primary'
                onClick={handleSubmit}
              >
                Salvar
              </Button>
            </>
          )}
        </Space>
      </Flex>
      <DeleteModal
        onDelete={async () => {
          await handleDeleteClick();
          setVisible(false);
        }}
        visible={visible}
        onCancel={() => setVisible(false)}
        loading={loading}
      />
    </Flex>
  );
};

export default WorkOrderButtons;
