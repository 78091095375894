import React from 'react';
import { Table } from 'antd';

import './style.css';
import ButtonsTable from './ButtonsTable';

const CustomTable = ({
  unlocker,
  items,
  reports,
  columns,
  pagination,
  selectedRowKeys,
  setSelectedRowKeys,
  selectedRows,
  setSelectedRows,
  setEquipments,
  isReport,
  onDelete,
  deleting,
  expandable,
  task,
  onPageChange,
  updateSessionStorage,
  onTaskUpdate,
  onTasksUpdate,
  uploading,
  loading,
  reviewer,
  noDelete,
}) => {
  const tableProps = {
    rowSelection: selectedRowKeys
      ? {
          selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
            if (updateSessionStorage) {
              updateSessionStorage(items, selectedRowKeys, selectedRows);
            }
          },
        }
      : null,
  };

  const handleChange = (pagination, filters, sorter) => {
    onPageChange(pagination, filters, sorter);
  };

  return (
    <div className='customTable'>
      <ButtonsTable
        unlocker={unlocker}
        items={items}
        reports={reports}
        selectedItems={selectedRowKeys}
        selectedItemsData={selectedRows}
        setEquipments={setEquipments}
        isReport={isReport}
        onDelete={onDelete}
        loading={deleting}
        task={task}
        onTaskUpdate={onTaskUpdate}
        onTasksUpdate={onTasksUpdate}
        uploading={uploading}
        reviewer={reviewer}
        noDelete={noDelete}
      />
      <Table
        {...tableProps}
        dataSource={items ?? []}
        columns={columns}
        pagination={{
          ...pagination,
          showTotal: (total, range) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{`${range[0]}-${range[1]} de ${total}`}</span>
            </div>
          ),
        }}
        bordered
        loading={!items || loading}
        rowKey='_id'
        expandable={expandable}
        onChange={handleChange}
        size='middle'
      />
    </div>
  );
};

export default CustomTable;
