import React, { useState } from 'react';
import { Button, Space, Typography, Flex } from 'antd';
import { EditOutlined, DeleteOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import DeleteModal from '../../../../../../components/DeleteModal';

const { Text, Title } = Typography;

const InformationsButtons = ({
  interconnection,
  handleSubmit,
  handleDeleteClick,
  handleEditClick,
  handleCancel,
  editing,
  loading,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Flex
      vertical
      style={{
        padding: '0 40px 30px',
        backgroundColor: 'white',
        borderBottom: '1px solid #EFEFEF',
      }}
    >
      <Flex align='flex-end'>
        <Flex vertical>
          <Title level={5}>{interconnection?.name}</Title>
          <Text type='secondary'>
            Veja abaixo todas as informações referentes à interconexão. Edite e exclua se
            necessário.
          </Text>
        </Flex>
        <Space direction='horizontal' style={{ marginLeft: 'auto' }}>
          {!editing ? (
            <>
              <Button
                icon={<EditOutlined />}
                type='text'
                style={{ background: 'black', color: 'white' }}
                onClick={handleEditClick}
              >
                Editar
              </Button>
              <Button
                loading={loading}
                icon={<DeleteOutlined />}
                type='text'
                style={{ background: 'black', color: 'white' }}
                onClick={() => {
                  setVisible(true);
                }}
              >
                Excluir
              </Button>
            </>
          ) : (
            <>
              <Button
                loading={loading}
                icon={<CloseCircleOutlined />}
                type='text'
                style={{ background: 'black', color: 'white' }}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                htmlType='submit'
                loading={loading}
                icon={<SaveOutlined />}
                type='primary'
                onClick={handleSubmit}
              >
                Salvar
              </Button>
            </>
          )}
        </Space>
      </Flex>
      <DeleteModal
        onDelete={handleDeleteClick}
        visible={visible}
        onCancel={() => setVisible(false)}
        loading={loading}
      />
    </Flex>
  );
};

export default InformationsButtons;
