import { atom, atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export const user = atom({
  key: 'user',
  default: null,
});
export const companyInfo = atom({
  key: 'companyInfo',
  default: null,
});

export const siderVisible = atom({
  key: 'siderVisible',
  default: false,
});

export const modalOpen = atom({
  key: 'modalOpen',
  default: false,
});

export const modalTitle = atom({
  key: 'modalTitle',
  default: null,
});

export const modalContent = atom({
  key: 'modalContent',
  default: null,
});

export const modalDisabled = atom({
  key: 'modalDisabled',
  default: null,
});

export const modalConfirm = atom({
  key: 'modalConfirm',
  default: null,
});

export const loading = atom({
  key: 'loading',
  default: false,
});

export const headerComponentsState = atomFamily({
  key: 'headerComponentsState',
  default: null,
});

export const apiCalls = atom({
  key: 'apiCalls',
  default: [],
});

export default function useGlobalState() {
  return {
    user: useRecoilValue(user),
    setUser: useSetRecoilState(user),
    companyInfo: useRecoilValue(companyInfo),
    setCompanyInfo: useSetRecoilState(companyInfo),
    title: useRecoilValue(headerComponentsState('title')),
    setTitle: useSetRecoilState(headerComponentsState('title')),
    modalOpen: useRecoilValue(modalOpen),
    setModalOpen: useSetRecoilState(modalOpen),
    modalTitle: useRecoilValue(modalTitle),
    setModalTitle: useSetRecoilState(modalTitle),
    modalContent: useRecoilValue(modalContent),
    setModalContent: useSetRecoilState(modalContent),
    modalDisabled: useRecoilValue(modalDisabled),
    setModalDisabled: useSetRecoilState(modalDisabled),
    modalConfirm: useRecoilValue(modalConfirm),
    setModalConfirm: useSetRecoilState(modalConfirm),
    siderVisible: useRecoilValue(siderVisible),
    setSiderVisible: useSetRecoilState(siderVisible),
    loading: useRecoilValue(loading),
    setLoading: useSetRecoilState(loading),
    buttons: useRecoilValue(headerComponentsState('buttons')),
    setButtons: useSetRecoilState(headerComponentsState('buttons')),
    customMenu: useRecoilValue(headerComponentsState('customMenu')),
    setCustomMenu: useSetRecoilState(headerComponentsState('customMenu')),
    pendingApiCalls: useRecoilValue(apiCalls),
    setPendingApiCalls: useSetRecoilState(apiCalls),
  };
}
