import { protectedResourcesScopes } from '../../auth/config';
import DetailsButton from '../components/DetailsButton';
import StatusTag from '../components/StatusTag';
import ReportButtons from '../components/ReportButtons';
import { Tag } from 'antd';
import TaskTag from '../components/TaskTag';

export default {
  'work-order': {
    formFields: [
      {
        label: 'Executor',
        key: 'employee',
        type: 'select',
        selectItem: 'executors',
        multiple: false,
        required: true,
      },
      {
        label: 'Equipamentos',
        key: 'equipment_list',
        type: 'select',
        selectItem: 'equipments',
        multiple: true,
        required: true,
      },
      { label: 'Data Prevista', key: 'scheduled_date', type: 'date', required: true },
    ],
    apiUrl: `${process.env.REACT_APP_BFF_API}/commissioning/work-order`,
    apiUrlFiltered: `${process.env.REACT_APP_BFF_API}/commissioning/work-order?page=%page%&limit=%limit%`,
    apiUrlByField: `${process.env.REACT_APP_CORE_API}/work_orders/filter`,
    apiUrlNew: `${process.env.REACT_APP_BFF_API}/commissioning/work-order/new`,
    scope: protectedResourcesScopes.core,
    tableColumns: [
      {
        title: 'Executor',
        dataIndex: 'employee',
        key: 'employee',
        render: (_, record) => record.employee?.name,
      },
      {
        title: 'Data Prevista',
        dataIndex: 'scheduled_date',
        key: 'scheduled_date',
        render: (text) => new Intl.DateTimeFormat('pt-BR').format(new Date(text)),
      },
      { title: 'Token', dataIndex: 'token', key: 'token' },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => <StatusTag status={text} />,
      },
      {
        title: 'Detalhes',
        dataIndex: 'details',
        key: 'details',
        render: (_, record) => (
          <DetailsButton column={record} columnKey={record.token} element={'work-order'} />
        ),
      },
    ],
  },
  'work-order-details': {
    formFields: [
      {
        label: 'Contratado',
        key: 'employee.company_name',
        type: 'input',
        selectItem: 'executors',
        multiple: false,
        required: false,
        disabled: true,
      },
      {
        label: 'Executor',
        key: 'employee.name',
        type: 'input',
        selectItem: 'executors',
        disabled: true,
        multiple: false,
        required: false,
      },
      { label: 'Data Prevista', key: 'scheduled_date', type: 'date', required: false },
      { label: 'Status', key: 'status', type: 'input', required: false, disabled: true },
    ],
    tableColumns: [
      {
        title: 'Localização',
        dataIndex: 'location',
        key: 'location',
        render: (text, record) => record.equipment.location.name,
      },
      {
        title: 'Categoria',
        dataIndex: 'subcategory',
        key: 'subcategory',
        render: (text, record) => record.equipment.subcategory.name,
      },
      {
        title: 'Nome da Interconexão',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => record.equipment.name,
      },
      {
        title: 'Origem',
        dataIndex: 'properties.origin',
        key: 'properties.origin',
        render: (text, record) => record.equipment.properties.origin,
      },
      {
        title: 'Destino',
        dataIndex: 'properties.destiny',
        key: 'properties.destiny',
        render: (text, record) => record.equipment.properties.destiny,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => <StatusTag status={record.equipment.status} />,
      },
      {
        title: 'Detalhes',
        dataIndex: 'details',
        key: 'details',
        render: (_, record) => (
          <DetailsButton
            column={record.equipment}
            columnKey={record.equipment.name}
            element={'work-order-details'}
          />
        ),
      },
    ],
    apiUrl: `${process.env.REACT_APP_CORE_API}/work_orders`,
    scope: protectedResourcesScopes.core,
  },
  executors: {
    apiUrl: `${process.env.REACT_APP_BFF_API}/commissioning/executor`,
    apiUrlNew: `${process.env.REACT_APP_BFF_API}/commissioning/executor/new`,
    apiUrlUpdate: `${process.env.REACT_APP_BFF_API}/commissioning/executor`,
    scope: protectedResourcesScopes.core,
    tableColumns: [
      { title: 'Nome', dataIndex: 'name', key: 'name' },
      { title: 'Email', dataIndex: 'email', key: 'email' },
      { title: 'Telefone', dataIndex: 'phone', key: 'phone' },
      { title: 'Função/Cargo', dataIndex: 'role', key: 'role' },
      {
        title: 'Localização',
        dataIndex: 'location.name',
        key: 'location.name',
        render: (text, record) => record.location?.map((local) => <Tag>{local?.name}</Tag>),
      },
      { title: 'Compania', dataIndex: 'company_name', key: 'company_name' },
      {
        title: 'Ações',
        dataIndex: 'actions',
        key: 'actions',
        render: (_, record) => <></>,
      },
    ],
  },
  roles: {
    apiUrl: `${process.env.REACT_APP_BFF_API}/commissioning/role`,
    apiUrlNew: `${process.env.REACT_APP_BFF_API}/commissioning/role/new`,
    apiUrlByField: `${process.env.REACT_APP_BFF_API}/commissioning/role/filter`,
    scope: protectedResourcesScopes.core,
  },
  sites: {
    apiUrl: `${process.env.REACT_APP_BFF_API}/position`,
    scope: protectedResourcesScopes.core,
    tableColumns: [
      { title: 'Nome', dataIndex: 'name', key: 'name' },
      { title: 'Endereço', dataIndex: 'address', key: 'address' },
      { title: 'Cidade', dataIndex: 'city', key: 'city' },
      { title: 'Estado', dataIndex: 'state_province', key: 'state_province' },
      { title: 'País', dataIndex: 'country', key: 'country' },
      { title: 'Emails', dataIndex: 'emails', key: 'emails' },
    ],
  },
  equipments: {
    formFields: [
      {
        label: 'Categoria',
        key: 'subcategory',
        type: 'select',
        selectItem: 'equipment_subcategory',
        selectKey: '_id',
        multiple: false,
        required: true,
      },
      {
        label: 'Nome do Equipamento',
        key: 'name',
        type: 'input',
        required: true,
      },
      {
        label: 'Localização',
        key: 'location',
        type: 'select',
        selectItem: 'sites',
        selectKey: '_id',
        multiple: false,
        required: true,
      },
      {
        label: 'Condição',
        key: 'condition',
        type: 'select',
        selectItem: 'equipment_condition',
        selectKey: 'name',
        multiple: false,
        required: true,
      },
    ],
    apiUrlNew: `${process.env.REACT_APP_BFF_API}/commissioning/interconnection/new`,
    apiUrl: `${process.env.REACT_APP_BFF_API}/commissioning/interconnection`,
    apiUrlFiltered: `${process.env.REACT_APP_BFF_API}/commissioning/interconnection?page=%page%&limit=%limit%`,
    scope: protectedResourcesScopes.core,
    tableColumns: [
      {
        title: 'Categoria',
        dataIndex: 'category',
        key: 'category',
        render: (text, record) => record.category.name,
      },
      { title: 'Nome', dataIndex: 'name', key: 'name' },
      {
        title: 'Origem',
        dataIndex: 'properties.origin',
        key: 'properties.origin',
        render: (text, record) => record.properties?.origin,
      },
      {
        title: 'Destino',
        dataIndex: 'properties.destiny',
        key: 'properties.destiny',
        render: (text, record) => record.properties?.destiny,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => <StatusTag status={text} />,
      },
      {
        title: 'Tarefas Pendentes',
        dataIndex: 'commissioning_tasks.task',
        key: 'commissioning_tasks',
        render: (_, record) =>
          record.commissioning_tasks?.map(
            (requiredTask) =>
              requiredTask.status === 'Required' && (
                <TaskTag status={requiredTask.status} name={requiredTask.task} />
              ),
          ),
      },
      {
        title: 'Detalhes',
        dataIndex: 'details',
        key: 'details',
        render: (_, record) => (
          <DetailsButton column={record} columnKey={record.name} element={'interconnections'} />
        ),
      },
    ],
  },
  interconnections: {
    formFields: [
      {
        label: 'Categoria',
        key: 'subcategory',
        type: 'select',
        selectItem: 'equipment_subcategory',
        selectKey: '_id',
        multiple: false,
        required: true,
      },
      {
        label: 'Nome do Equipamento',
        key: 'name',
        type: 'input',
        required: true,
      },
      {
        label: 'Localização',
        key: 'location',
        type: 'select',
        selectItem: 'sites',
        selectKey: '_id',
        multiple: false,
        required: true,
      },
      {
        label: 'Condição',
        key: 'condition',
        type: 'select',
        selectItem: 'equipment_condition',
        selectKey: 'name',
        multiple: false,
        required: true,
      },
    ],
    apiUrl: `${process.env.REACT_APP_BFF_API}/commissioning/interconnection`,
    apiUrlFiltered: `${process.env.REACT_APP_BFF_API}/commissioning/interconnection?page=%page%&limit=%limit%`,
    apiUrlWorksheet: `${process.env.REACT_APP_BFF_API}/commissioning/worksheet/extract`,
    apiUrlUnlock: `${process.env.REACT_APP_BFF_API}/commissioning/interconnection/unlock`,
    scope: protectedResourcesScopes.core,
    tableColumns: [
      {
        title: 'Categoria',
        dataIndex: 'category',
        key: 'category',
        render: (text, record) => record.category.name,
      },
      { title: 'Nome', dataIndex: 'name', key: 'name' },
      {
        title: 'Origem',
        dataIndex: 'properties.origin',
        key: 'properties.origin',
        render: (text, record) => record.properties?.origin,
      },
      {
        title: 'Destino',
        dataIndex: 'properties.destiny',
        key: 'properties.destiny',
        render: (text, record) => record.properties?.destiny,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => <StatusTag status={text} />,
      },
      {
        title: 'Tarefas Pendentes',
        dataIndex: 'commissioning_tasks.task',
        key: 'commissioning_tasks',
        render: (_, record) =>
          record.commissioning_tasks?.map(
            (requiredTask) =>
              requiredTask.status === 'Required' && (
                <TaskTag status={requiredTask.status} name={requiredTask.task} />
              ),
          ),
      },
      {
        title: 'Detalhes',
        dataIndex: 'details',
        key: 'details',
        render: (_, record) => (
          <DetailsButton column={record} columnKey={record._id} element={'interconnections'} />
        ),
      },
    ],
    uploadTableColumns: [
      {
        title: 'Nome',
        dataIndex: 'data.name',
        key: 'data.name',
        render: (text, record) => record.name,
      },
      {
        title: 'Local',
        dataIndex: 'data.system.name',
        key: 'data.location',
        render: (text, record) => record.system.name,
      },
      {
        title: 'Categoria',
        dataIndex: 'data.category.name',
        key: 'data.category',
        render: (text, record) => record.category.name,
      },
    ],
    uploadErrorTableColumns: [
      {
        title: 'Nome',
        dataIndex: 'data.name',
        key: 'data.name',
        render: (text, record) => record.document.name,
      },
      {
        title: 'Local',
        dataIndex: 'data.system.name',
        key: 'data.system',
        render: (text, record) => record.document.system.name,
      },
      {
        title: 'Categoria',
        dataIndex: 'data.category.name',
        key: 'data.category',
        render: (text, record) => record.document.category.name,
      },
      {
        title: 'Mensagem de erro',
        dataIndex: 'message',
        key: 'message',
        render: (text, record) => record.error,
      },
    ],
  },
  equipment_category: {
    apiUrl: `${process.env.REACT_APP_BFF_API}/commissioning/interconnection/getCategories`,
    scope: protectedResourcesScopes.core,
  },
  equipment_condition: {
    apiUrl: `${process.env.REACT_APP_CORE_API}/equipments/conditions`,
    scope: protectedResourcesScopes.core,
  },
  equipment_history: {
    apiUrl: `${process.env.REACT_APP_BFF_API}/commissioning/interconnection/tasks`,
    apiUrlTask: `${process.env.REACT_APP_BFF_API}/commissioning/task`,
    apiUrlUpdate: `${process.env.REACT_APP_BFF_API}/commissioning/task/update`,
    scope: protectedResourcesScopes.core,
  },
  tab_information: {
    formFields: [
      {
        label: 'Categoria',
        key: 'category.name',
        type: 'input',
        disabled: true,
        required: false,
      },
      {
        label: 'Nome',
        key: 'name',
        type: 'input',
        required: false,
      },
    ],
    apiUrl: `${process.env.REACT_APP_CORE_API}/equipments`,
    scope: protectedResourcesScopes.core,
  },
  tab_commissioning: {
    tableColumns: [
      {
        title: 'Categoria',
        dataIndex: 'subcategory',
        key: 'subcategory',
        render: (text, record) => record.subcategory.name,
      },
      { title: 'Nome da Interconexão', dataIndex: 'name', key: 'name' },
      {
        title: 'Origem',
        dataIndex: 'properties.origin',
        key: 'properties.origin',
        render: (text, record) => record.properties?.origin,
      },
      {
        title: 'Destino',
        dataIndex: 'properties.destiny',
        key: 'properties.destiny',
        render: (text, record) => record.properties?.destiny,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => <StatusTag status={text} />,
      },
      {
        title: 'Detalhes',
        dataIndex: 'details',
        key: 'details',
        render: (_, record) => <ReportButtons />,
      },
    ],
    apiUrl: `${process.env.REACT_APP_CORE_API}/equipments`,
    scope: protectedResourcesScopes.core,
  },
  files: {
    apiUrl: `${process.env.REACT_APP_CORE_API}/files`,
    scope: protectedResourcesScopes.core,
  },
  reports: {
    apiUrl: `${process.env.REACT_APP_BFF_API}/commissioning/report`,
    scope: protectedResourcesScopes.core,
  },
};
