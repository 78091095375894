import { useTranslation } from 'react-i18next';
import CrudPage from '../../../../Template/layoutComponents/CrudPage';

import {
  getCostCenters,
  newCostCenter,
  updateCostCenter,
} from '../../../../Maintenance/helpers/apiCalls';

import useMaintenanceState from '../../../../Maintenance/atoms/variables';

import './style.css';

const Departments = () => {
  const { t } = useTranslation();

  const { orgs } = useMaintenanceState();

  return (
    <div id='costCentersPage'>
      <CrudPage
        title={t('cost_center')}
        newText={t('new_cost_center')}
        detailsLink={'/settings/cost-centers'}
        defaultItem={{
          new: true,
          label: t('new_cost_center'),
          properties: [
            {
              label: t('name'),
              type: 'text',
              value: t('new_cost_center'),
              main: true,
              required: true,
              disabled: false,
              key: 'name',
            },
            {
              label: t('organization_id'),
              type: 'singleSelect',
              value: null,
              options: orgs,
              main: true,
              required: true,
              disabled: false,
              key: 'organization_id',
            },
          ],
        }}
        getAllItems={getCostCenters}
        newItem={newCostCenter}
        updateItem={updateCostCenter}
      />
    </div>
  );
};

export default Departments;
