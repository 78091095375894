import React, { useState, useEffect, useMemo } from 'react';
import { Button, message, Tooltip } from 'antd';
import DeleteModal from '../../DeleteModal';
import { useLocation, useParams } from 'react-router-dom';
import useCrudApi from '../../../hooks/useCrudApi';
import { isExternalUser } from '../../../helpers/roles';
import { saveAs } from 'file-saver';

import './style.css';

import { useMsal } from '@azure/msal-react';
import { useGlobalState } from '../../../hooks/useGlobalState';
import JSZip from 'jszip';
import useMaintenanceState from '../../../atoms/variables';

export default function ButtonsTable({
  reviewer,
  unlocker,
  items,
  reports,
  onDelete,
  loading,
  selectedItems,
  selectedItemsData,
  isReport,
  setEquipments,
  onTaskUpdate,
  onTasksUpdate,
  uploading,
  noDelete,
}) {
  const location = useLocation();
  const { pathname, search } = location;
  const { createBatchItemsAprove } = useCrudApi('equipment_history');
  const { unlockItem } = useCrudApi('interconnections');
  const { getReports, deleteReports, downloading } = useCrudApi('reports');
  const [visible, setVisible] = useState(false);
  const [isAllSuccess, setIsAllSuccess] = useState(false);

  const [canApproving, setCanApproving] = useState(false);
  const [canDisapproving, setCanDisapproving] = useState(false);
  const [canUnlock, setCanUnlock] = useState(false);
  const [canDownload, setCanDownload] = useState(false);
  const [canEquipmentDownload, setCanEquipmentDownload] = useState(false);

  const [interconnectionStatus, setInterconnectionStatus] = useState(false);
  const { chosenPlant } = useMaintenanceState();

  const { user } = useGlobalState();

  const isExternalUserMemo = useMemo(() => isExternalUser(user?.username), [user?.username]);

  const sessionRole = 'sessionRole';

  useEffect(() => {
    const checkCondition = (arr, predicate, isReport) => {
      return isReport ? arr?.some(predicate) : arr?.every(predicate);
    };

    const someItemsAreFail = checkCondition(
      selectedItems,
      (id) => items?.find((item) => item?._id === id && item?.status === 'Fail'),
      isReport,
    );

    const role = sessionStorage.getItem(sessionRole);
    const role_data = role ? JSON.parse(role) : null;

    const someItemsDontRequireSignOnRole = checkCondition(
      selectedItems,
      (id) =>
        items?.find(
          (item) =>
            item?._id === id &&
            !item?.report?.signatures?.some((signature) =>
              role_data?.permissions?.includes(signature.anchor),
            ),
        ),
      isReport,
    );

    const someItemsAreRejectedOnRole = checkCondition(
      selectedItems,
      (id) =>
        items?.find(
          (item) =>
            item?._id === id &&
            item?.report?.signatures?.find(
              (signature) =>
                role_data?.permissions?.includes(signature.anchor) && signature.approve === false,
            ),
        ),
      isReport,
    );

    const someItemsAreApprovedOnRole = checkCondition(
      selectedItems,
      (id) =>
        items?.find(
          (item) =>
            item?._id === id &&
            item?.report?.signatures?.find(
              (signature) =>
                role_data?.permissions?.includes(signature.anchor) && signature.approve === true,
            ),
        ),
      isReport,
    );

    const allItemsAreDownloadable = checkCondition(
      selectedItems,
      (id) => reports?.find((report) => report.name.includes(id)),
      false,
    );

    const allEquipmentsAreDownloadable = checkCondition(
      selectedItems,
      (id) => items?.find((item) => item?._id === id && item?.status === 'Approved'),
      false,
    );

    setCanEquipmentDownload(allEquipmentsAreDownloadable);
    setCanDownload(allItemsAreDownloadable);
    setCanUnlock(someItemsAreFail);
    setIsAllSuccess(
      someItemsDontRequireSignOnRole || someItemsAreRejectedOnRole || someItemsAreApprovedOnRole,
    );
  }, [selectedItems, reports, items, isReport, isAllSuccess]);

  const getInterconnectionId = () => {
    if (items) {
      return items[0].interconnection._id;
    }
  };

  const handleSituationReport = async (situation) => {
    const role = sessionStorage.getItem(sessionRole);
    const role_data = JSON.parse(role);

    if (!role_data || role_data.length === 0) {
      message.error(
        `Esse usuário não possui permissão para ${situation === 'approve' ? 'aprovar' : 'reprovar'} a tarefa!`,
      );
      return;
    }

    const setStatus = situation === 'approve' ? setCanApproving : setCanDisapproving;
    try {
      setStatus(true);
      const updatedTasks = await createBatchItemsAprove(
        {
          ids: selectedItems,
          interconnection: getInterconnectionId(),
          role: role_data,
          timestamp: new Date(),
        },
        situation,
      );
      if (updatedTasks) {
        // if (situation !== 'approve') {
        //   for (const task of updatedTasks) {
        //     await deleteReports(commissioningTree.site.id, task.equipment._id, task._id);
        //   }
        // }
        await onTasksUpdate(updatedTasks, situation);
        message.success(
          `Relatório(s) ${situation === 'approve' ? 'aprovado(s)' : 'reprovado(s)'} com sucesso!`,
        );
      }
    } catch (e) {
      message.error('Erro ao aprovar itens.');
    } finally {
      setStatus(false);
    }
  };

  const handleChangeInterconnectionStatus = async () => {
    if (canUnlock) {
      setInterconnectionStatus(true);
      const updatePromises = selectedItems.map(async (id) => {
        const success = await unlockItem(id, { status: 'Pending' });
        return success ? id : null;
      });

      try {
        const results = await Promise.all(updatePromises);
        if (results.every((res) => res)) {
          message.success('Itens desbloqueados com sucesso!');
          setEquipments((prevEquipments) =>
            prevEquipments.map((item) =>
              selectedItems.includes(item._id) ? { ...item, status: 'Pending' } : item,
            ),
          );
        } else {
          message.error('Erro ao desbloquear alguns itens.');
        }
      } catch (error) {
        message.error('Erro ao processar o desbloqueio.');
      } finally {
        setInterconnectionStatus(false);
      }
    }
  };

  const zipReports = async (requestedReports, interconnectionName) => {
    const zip = new JSZip();

    const fileFetchPromises = requestedReports.map(async (report, index) => {
      const response = await fetch(report.url);
      const blob = await response.blob();
      const fileName = `${report.fileName}`;
      zip.file(fileName, blob);
    });

    await Promise.all(fileFetchPromises);
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, `relatorios_${chosenPlant.name}_${interconnectionName ?? ''}.zip`);
  };

  const handleDownloadBlobReports = async () => {
    let requestedReports = [];
    let interconnectionName = reports ? selectedItemsData[0].interconnection.name : null;

    selectedItems.map((id) => {
      const report = reports?.find((report) => report.name.includes(id));
      if (report) {
        const splitName = report.name.split('/');
        const fileName = splitName[4];
        requestedReports.push({ fileName: fileName, url: report.url });
      }
    });

    if (requestedReports.length === 0) {
      for (const data of selectedItemsData) {
        const id = data._id;
        interconnectionName = data.name;
        const reports = await getReports(chosenPlant.id, id);
        if (reports) {
          for (const report of reports) {
            const splitName = report.name.split('/');
            const fileName = splitName[4];
            requestedReports.push({ fileName: fileName, url: report.url });
          }
        }
        await zipReports(requestedReports, interconnectionName);
        requestedReports = [];
      }
    } else await zipReports(requestedReports, interconnectionName);
  };

  return (
    <div className='filters'>
      {(pathname.match(/\/maintenance\/.*\/interconnections(\/|$)/g) ||
        pathname.match(/\/work-orders\/.*/g)) &&
        selectedItems?.length > 0 && (
          <>
            <Tooltip
              size
              color='white'
              overlayInnerStyle={{ color: 'black' }}
              placement='topLeft'
              title={
                canDownload || canEquipmentDownload
                  ? 'Os relatórios selecionados serão baixados em uma pasta .ZIP'
                  : 'Alguns relatórios selecionados não estão disponíveis para download'
              }
            >
              <Button
                type='primary'
                disabled={(!canDownload && !canEquipmentDownload) || uploading}
                className='space-btn'
                onClick={() => handleDownloadBlobReports()}
                loading={downloading}
              >
                Baixar Relatório(s)
              </Button>
            </Tooltip>
            {reviewer && (
              <>
                <Button
                  type='primary'
                  loading={canApproving}
                  disabled={isAllSuccess || uploading}
                  className='space-btn'
                  onClick={() => handleSituationReport('approve')}
                >
                  Aprovar
                </Button>
                <Button
                  danger
                  loading={canDisapproving}
                  onClick={() => handleSituationReport('disapprove')}
                  disabled={isAllSuccess || uploading}
                  className='space-btn'
                >
                  Reprovar
                </Button>
              </>
            )}
            {unlocker && !isExternalUserMemo && (
              <Button
                id='unlock-btn'
                // type='ghost'
                loading={interconnectionStatus}
                onClick={handleChangeInterconnectionStatus}
                disabled={!canUnlock}
                className='space-btn custom-btn'
              >
                Desbloquear
              </Button>
            )}
          </>
        )}
      {!noDelete && selectedItems?.length > 0 && (
        <Button disabled={uploading} onClick={() => setVisible(true)} type='primary' danger>
          Excluir
        </Button>
      )}
      {visible && (
        <DeleteModal
          loading={loading}
          visible={visible}
          onCancel={() => setVisible(false)}
          onDelete={async () => {
            await onDelete();
            setVisible(false);
          }}
        />
      )}
    </div>
  );
}
