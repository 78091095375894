import React from 'react';
import { Button, Flex } from 'antd';

const DownloadTemplateButton = ({ filePath, label }) => {
  return (
    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
      <a href={filePath} download>
        <Button type='link'>{label}</Button>
      </a>
    </div>
  );
};

export default DownloadTemplateButton;
